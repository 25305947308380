%hide-text {
  text-indent: -9999px;
}

%filter-label {
  color: #706f73;
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  letter-spacing: 0.3px;
  font-weight: 400;
  line-height: 20px;
}