@font-face {
  font-family: "HelveticaNeue-Bold";
  src: url("../fonts/HelveticaNeueLTStd-Bd.eot");
  src: url("../fonts/HelveticaNeueLTStd-Bd.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeueLTStd-Bd.woff2") format("woff2"), url("../fonts/HelveticaNeueLTStd-Bd.woff") format("woff"), url("../fonts/HelveticaNeueLTStd-Bd.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue LT Std Lt";
  src: url("../fonts/HelveticaNeueLTStd-Lt.eot");
  src: url("../fonts/HelveticaNeueLTStd-Lt.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeueLTStd-Lt.woff2") format("woff2"), url("../fonts/HelveticaNeueLTStd-Lt.woff") format("woff"), url("../fonts/HelveticaNeueLTStd-Lt.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "HelveticaNeue-Medium";
  src: url("../fonts/HelveticaNeueLTStd-Md.eot");
  src: url("../fonts/HelveticaNeueLTStd-Md.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeueLTStd-Md.woff2") format("woff2"), url("../fonts/HelveticaNeueLTStd-Md.woff") format("woff"), url("../fonts/HelveticaNeueLTStd-Md.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("../fonts/HelveticaNeueLTStd-Roman.otf") format("opentype");
  src: url("../fonts/HelveticaNeueLTStd-Roman.eot");
  src: url("../fonts/HelveticaNeueLTStd-Roman.eot?#iefix") format("embedded-opentype"),
  url("../fonts/HelveticaNeueLTStd-Roman.woff2") format("woff2"),
  url("../fonts/HelveticaNeueLTStd-Roman.woff") format("woff"),
  url("../fonts/HelveticaNeueLTStd-Roman.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@import "variables";
@import "mixins";
@import "placeholders";

* {
  -moz-hyphens:none;
  -ms-hyphens:none;
  -webkit-hyphens: none;
  hyphens:none;
}

* {
  word-break:keep-all;
}

html, body {
  overflow-x: hidden;
}
body {
  font-family: Helvetica,'Helvetica Neue', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
}
body.admin-menu {
  padding-top: 20px;
  margin-top: 0 !important;
}
body.admin-menu header {
  padding-top: 20px !important;
}
.centered {
  text-align: center;
}
.xs-centered {
  text-align: center;
}
//.inner-wide {
//  width: 100%;
//  margin-left: 0;
//  margin-right: 0;
//}

.inner-wide {
  width: 200%;
  margin-left: -50%;
  margin-right: 50%;
}
.btn {
  font-family: Helvetica, sans-serif;
  font-weight: 700;
  font-size: 16px;
}
.btn-primary {
  border: none;
  border-radius: 0;
  background-color: #5d9632;
  padding: 10px 12px;
  font-family: Helvetica, sans-serif;
}
.btn-pop-close {
  display: table;
  margin-top: 15px;
}
.btn-primary:active, .btn-primary.active, .open > .dropdown-toggle.btn-primary, .btn-primary:active:hover, .btn-primary.active:hover, .open > .dropdown-toggle.btn-primary:hover, .btn-primary:active:focus, .btn-primary.active:focus, .open > .dropdown-toggle.btn-primary:focus, .btn-primary:active.focus, .btn-primary.active.focus, .open > .dropdown-toggle.btn-primary.focus, .btn-primary:focus, .btn-primary.focus,
.hs-form .hs-button:active:not(.inactive):not(.link), .hs-form .hs-button.depressed:not(.inactive):not(.link), .dropdown-open>.hs-form .hs-button:not(.inactive):not(.link){
  background-color: #8cc63e;
}
.relative {
  position: relative;
}
.bold {
  font-weight: bold;
}
#cboxLoadedContent {
  margin-bottom: 0;
  padding: 25px 0;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 28px;
    color: #706f73;
    font-size: 16px;
    letter-spacing: 0.3px;
    font-weight: 400;
}
small, .small {
  font-size: 75%;
  font-family: 'PlutoSansLight';
  color: #444;
}
.no-mp {
  margin: 0;
  padding: 0;
}
blockquote {
  color: #706f73;
  background-color: #ebebeb;
  border: none;
  padding: 20px;
  font-style: italic;
}
h1 {
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-size: 32px;
  color: #706f73;
}
h2 {
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-size: 30px;
  color: #706f73;
}
h3 {
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-size: 20px;
  color: #706f73;
}

.node-type-homepage {
  h1, #product-groups-slider ul.slides li .text h2 {
    font-size: 30px;
    font-family: "Helvetica Neue LT Std Lt", Arial, sans-serif;
  }
}



.clearfix {
  clear: both;
}
.no-pad-left {
  padding-left: 0;
}
.no-pad-right {
  padding-right: 0;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  background-color: #00aeef;
  width: 25px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #fff transparent transparent transparent;
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #fff transparent;
}
.select2-container--default .select2-selection--single {
  border-radius: 0;
  border-color: #00aeef;
}
.hubspot-form-container {
  margin: 0 auto;
  max-width: 100%;
  margin-left: 15px;
  overflow: hidden;
}
.hs-form .hs-richtext {
  margin-bottom: 0;
}
.hs-form .actions {
  margin: 10px 0 0 0;
  padding: 0;
}
.hs-form .hs-button {
  padding: 8px 20px;
  border: none !important;
  border-radius: 0;
  background-color: #5d9632;
  text-shadow: none !important;
  background-image: none !important;
  box-shadow: none !important;
  font-size: 16px;
  letter-spacing: 0.3px;
  min-width: 185px;
  font-weight: 700;
  line-height: 23px;
}

input.hs-input, textarea.hs-input, select.hs-input {
  border-radius: 0;
  font-family: Helvetica,Arial,sans-serif;
}

.hs-form .hs-button {
  &:hover, &:active, &:focus, &:visited {
    border: none !important;
    box-shadow: none !important;
    background-color: #8cc63e;
  }

}
.hs-form label, .hs-form.stacked label {
  color: #706f73;
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  letter-spacing: 0.3px;
  font-weight: 400;
  line-height: 20px;

}

.hs-error-msgs {
  position: relative;
}
.hs-form.stacked .hs-error-msgs label {
  position: absolute;
  top: 100%;
  left: 0;
  margin: 0 0 2px;
  padding: 2px 6px !important;
  height: auto;
  background-color: #fdd2d0;
  font-size: 11px;
  border: 1px solid #fcb3af;
  padding: 4px 16px 4px 10px;
  color: #000;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #fefefe), color-stop(100%, #fdd2d0));
  background-image: -webkit-linear-gradient(#fefefe,#fdd2d0);
  background-image: -moz-linear-gradient(#fefefe,#fdd2d0);
  background-image: -o-linear-gradient(#fefefe,#fdd2d0);
  background-image: linear-gradient(#fefefe,#fdd2d0);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 0 6px #ddd;
  -moz-box-shadow: 0 0 6px #ddd;
  box-shadow: 0 0 6px #ddd;
  //z-index: 99999;
  width: auto
}
.hs-error-msgs label {
  white-space: nowrap !important;
}
.hs-loading {
  display: block;
  text-align: center;
}
.hs-loading i {
  font-size: 36px;
  margin: 100px;
  color: #13457c;
  -webkit-animation-name: rotateThis;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: rotateThis;
  -moz-animation-duration: 1s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  animation-name: rotateThis;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.hubspot-form-container .submitted-message,
.hubspot-form-container .submitted-message h3 {
  font-family: 'PlutoSansLight';
}
ul.action-links {
  margin: 7px 5px 0 0;
  float: right;
}
.view .pagination .progress-disabled {
  float: left;
}
.pager-load-more li>a {
  font-family: 'PlutoSansLight';
  border-radius: 0;
  border-color: #13457c;
  color: #13457c;
  font-size: 1.5em;
  padding: 10px 50px;
}

.header-image-responsive {
  align-items: center;
  width: 100%;
  position: relative;
  height: auto;
  background-image: linear-gradient(180deg, #f0f0f0 -15%, #ffffff 100%);
  z-index: -1;
  &:before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    height: 100%;
    width: 100%;
    bottom: 0;
    left: -100%;
    background-image: linear-gradient(180deg, #f0f0f0 -15%, #ffffff 100%);
    z-index: -1;
  }
  &:after {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    height: 100%;
    width: 100%;
    bottom: 0;
    right: -100%;
    background-image: linear-gradient(180deg, #f0f0f0 -15%, #ffffff 100%);
    z-index: -1;
  }
}
.node-type-page {
  .header-image-responsive {
    display: flex;
  }
  .header-page-2{
    display: block;
    margin-bottom: 20px;
  }

}
.node-type-enhanced-page .header-image-responsive:before {
  content: '';
  position: absolute;
  width: 200%;
  //left: -50%;
  right: -59%;
  height: 266px;
  background-size: cover;
}
.header-image-responsive:after {
  //padding-top: 26%;
  //display: block;
  //content: '';
}
.header-image-responsive img {
  max-height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  -webkit-transform:translate(-50%, 0);
  -moz-transform:translate(-50%, 0);
  -ms-transform:translate(-50%, 0);
  -o-transform:translate(-50%, 0);
}
.node-type-enhanced-page #aboutus-header .header-image-responsive img {
  max-width: 100%;
  position: static;
  top: 0;
  left: 0;
  -webkit-transform:inherit;
  -moz-transform:inherit;
  -ms-transform:inherit;
  -o-transform:inherit;
  height: auto;
}

.field-name-body img {
  max-width: 100%;
}

.social-share ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: center;
}
.social-share ul li {
  display: inline-block;
  margin: 0 5px;
}
.social-share ul li a {
  font-size: 1.6em;
  border: 1px solid #13457c;
  color: #13457c;
  border-radius: 45px;
  width: 45px;
  height: 45px;
  display: block;
  text-align: center;
}
.social-share ul li a i {
  line-height: 2;
}
.social-share ul li a span {
  display: none;
}
.social-share ul li a:hover {
  background-color: #13457c;
  color: #fff;
}
.flex-direction-nav .flex-prev,
.flex-direction-nav .flex-next {
  opacity: 0;
  text-decoration: none;
  outline: none;
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.common-header {
  position: relative;
  color: #000;
  padding: 15px 0;
  .image {
    padding: 0 85px;
  }
}
.common-header .inner-wide {
  background-color: #fff;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.common-header h1 {
  display: inline-block;
  position: relative;
  margin-bottom: 0; // new style.
  color: #4a4a4a;
  font-family: "Helvetica Neue LT Std Lt", Arial, sans-serif;
  font-size: 30px;
  line-height: 33px;
  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 9999%;
    right: 0;
    height: 1px;
    background-color: #adafb1;
  }
}
.common-header h2 {
  font-family: 'PlutoSansMedium';
  font-size: 1.2em;
  margin-top: 15px;
  margin-bottom: 0;
  padding-bottom: 0;
}
.common-header h3 {
  color: #706f73;
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  letter-spacing: 0.3px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 15px;
  @media (min-width: 992px) {
    padding-right: 55px;
  }
}
.common-header .line {
  position: relative;
  margin-top: 5px;
}
.common-header .line-inner {
  position: absolute;
  top: 0;
  right: 0;
  width: 400%;
  border-bottom: 1px solid #13457c;
}
.overview-product .common-header .line-inner {
  position: absolute;
  top: 0;
  right: 0;
  left: -100%;
  width: 200%;
  border-bottom: 1px solid #fff;
}
#term-header .line-inner {
  //border-color: #fff;
}

#category-overs {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: none;
}
#category-overs li {
  display: inline-block;
  border: none;
  padding: 0 2px;
  margin: 0 50px 0 0;
  text-align: center;
}
#category-overs a {
  display: block;
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 15px 0;
  text-transform: capitalize;
  text-decoration: none;
  color: #706f73;
  font-family: "Helvetica Neue LT Std Lt", Arial, sans-serif;
  font-size: 16px;
  letter-spacing: 0.3px;
  font-weight: 400;
  line-height: 20px;
  border-bottom: 5px solid transparent;
}
#category-overs a:hover {
  text-decoration: none;
  border-bottom: 5px solid #8cc63e;
}
#category-overs a .relative {
  z-index: 1;
}
#category-overs a .overlay {
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
}
#category-overs li a.active {
  border-bottom: 5px solid #8cc63e;
}

header {
  width: 100%;
  font-family: $font-regular;
  z-index: 99;
}

#kerrdental-nav {
  ul.depth-1 > li {
    > a {
      font-size: 15px;
      font-family: $font-light;
    }
    ul > li > a {
      font-size: 15px;
      font-family: $font-light;
    }
  }
}

#kerrdental-nav ul.depth-2 li.category-matrix {
  font-family: $font-light;
}

header .navbar-brand {
  padding-top: 0px;
  padding-bottom: 0px;
  line-height: 55px;
  height: 100%;
}
header .navbar-brand a {
  display: block;
  width: 75px;
  text-indent: -9999px;
  background: transparent url(../img/pelton-logo.svg) center center no-repeat;
  text-align: left;
  line-height: 55px;
}
.row {
  height: 100%;
}
#header-top {
  background-color: #13457c;
  height: 55px;
  position: relative;
  > .container {
    padding-right: 0;
  }
}
.container {
  height: 100%;
}
.row {
  height: 100%;
}

#header-right-wrapper {
  padding: 0 10px;
  text-align: center;
  white-space: nowrap;

  @media (max-width: 992px) {
    float: right;
  }

  > div {
    white-space: normal;

    &:nth-child(2n){
      margin-left: 10px;

      @media (min-width: 992px) {
        padding-right: 10px;
      }
    }
  }

  @media (max-width: 480px) {
    .profile-toggle,
    .cart-toggle {
      display: none;
    }
  }
}
#header-cart-container,
#header-profile-container {
  display: inline-block;
  line-height: 54px;
  position: relative;
  padding: 0;
}
#header-profile-container.full-width {
  width: 100%;
}
#header-cart-container-mobile {
  background-color: #10365f;
  line-height: 54px;
  position: static;
  padding: 0;
}
.header-cart {
  cursor: pointer;
  text-align: center;
}
.header-cart i, .header-profile i {
  font-size: 1.6em;
  margin-right: 5px;
  color: #fff;
  vertical-align: sub;
  position: relative;
}
.header-cart i span {
  position: absolute;
  right: -12px;
  top: -6px;
  background-color: #c2c2c2;
  font-size: 10px;
  padding: 4px 3px;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  font-family: 'PlutoSansMedium';
}
.header-cart a.cart-toggle,
.header-profile .profile-toggle {
  color: #fff;
  font-size: 0.8em;
  padding-bottom: 4px;
  text-transform: uppercase;
  cursor: pointer;
}
.header-cart a:hover {
  text-decoration: none;
}
#header-cart-container:hover a.cart-toggle, .header-cart a.cart-toggle.active {
  border-bottom: 3px solid #05abed
}

#header-profile-container:hover .profile-toggle, .header-profile .profile-toggle.active {
  border-bottom: 3px solid #fff;
  text-decoration: none;
}

#header-cart-container-mobile:hover a.cart-toggle, .header-cart a.cart-toggle.active {
  border-bottom: 3px solid #05abed
}
.header-cart-contents,
.header-profile-contents {
  display: none;
  position: absolute;
  right: -10px;
  top: 100%;
  background-color: #fff;
  box-shadow: 0 0 5px 2px rgba(0,0,0,0.15);
  border-top: none;
  z-index: 10;
  min-width: 250px;
}
.page-header {
  padding-bottom: 1px;
  display: inline-block;
  position: relative;
  margin: 25px 0 0 0;
  border-bottom: none;
  color: #4a4a4a;
  font-family: "Helvetica Neue LT Std Lt", Arial, sans-serif;
  font-size: 30px;
  line-height: 33px;
  &:after {
      content: '';
      position: absolute;
      display: block;
      width: 9999%;
      right: 0;
      bottom: 0;
      height: 1px;
      background-color: #adafb1;
  }
}
.header-profile-contents {
  padding: 0;
  background-color: #8b949b;
  text-align: left;

  .profile-menu {
    list-style: none;
    padding-left: 0;
    line-height: 1;

    li {
      border-bottom: 1px solid white;
      padding: 12px 20px;
      margin-bottom: 0;

      &:last-child {
        border-bottom: none;
        //padding-bottom: 0;
      }

      a {
        color: white;
        margin-bottom: 0;
        font-size: 15px;
        font-family: 'PlutoSansCondLight';

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
.header-cart-contents .views-row {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  color: #706f73;
  font-size: 12px;
  line-height: 14px;
}
.header-cart-contents .views-field-field-sku-image {
  width: 35%;
  display: inline-block;
  padding-right: 5px;
  vertical-align: top;
}
.header-cart-contents .views-field-field-sku-image img {
  max-width: 100%;
  height: auto;
}
.header-cart-contents .views-field-line-item-title {
  width: 61%;
  display: inline-block;
  vertical-align: top;
}
.header-cart-contents .views-field-line-item-title .field-content span {
  display: block;
  color: #00aeef;
  margin-top: 5px;
}
.header-cart-contents .btn {
  display: block;
  margin: 10px;
  padding: 10px;
  font-size: 1em;
}
#header-cart-container-mobile .header-cart-contents {
  width: 100%;
}
#header-cart-container-mobile .header-cart-contents .views-field-field-sku-image {
  width: 25%;
}
#header-cart-container-mobile .header-cart-contents .views-field-line-item-title {
  width: 71%;
}
#header-bottom {
  background-color: #f4f4f4;
  padding: 0 5px;
  color: #13457c;
  height: 100%;
  white-space: nowrap;
}
#header-bottom .container {
  text-align: right;
  height: 100%;
  padding: 0px;
  white-space: nowrap;
  display: flex;
  flex-direction: row-reverse;
}
#header-bottom .h3 {
  float: right;
  margin: 0px 5px 0px 0px;
  font-size: 12px;
  color: #444;
  font-family: 'PlutoSansLight';
  position: relative;
  top: 50%;
  -webkit-transform:translateY(-50%);
  -moz-transform:translateY(-50%);
  -ms-transform:translateY(-50%);
  -o-transform:translateY(-50%);
  line-height: 35px;
}
#header-help, #header-international, #header-search {
  float: right;
  vertical-align: middle;
  height: 35px;
  white-space: nowrap;
  text-align: center;
  padding: 0px 7px 0px 8px;
}
#header-international, #header-search {
  flex-grow: 1;
}
#header-help {
  cursor: pointer;
  color: #A7A7A7;
  font-size: 12px;
  padding: 0px 7px 0px 0px;
}
#header-help .help-container {
  text-align: right;
  /*float: right;*/
  overflow: hidden;
  position: relative;
  right: 0px;
  display: inline-block;
  vertical-align: top;
  width: 70px;
  height: 35px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
#header-help .help-container.open {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  min-width: 100px;
}
#header-help .help-text {
  white-space: nowrap;
  height: 100%;
  position: absolute;
  text-align: right;
  top: 0px;
  right: 0px;
  -webkit-transition: all 0.8s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  -o-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out;
}
#header-help .help-text {
  width: 100%;
  float: right;
  text-align: right;
}
#header-help .help-phone {
  white-space: nowrap;
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0px;
  right: 0px;
  -webkit-transition: all 0.8s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  -o-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out;
}
#header-help .help-text.out {
  right: -150px;
}
#header-help .help-phone.out {
  right: -150px;
}
#header-help i {
  color: #13457c;
  font-size: 22px;
}
#header-help .h3 {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform:translateY(-50%);
  -moz-transform:translateY(-50%);
  -ms-transform:translateY(-50%);
  -o-transform:translateY(-50%);
}
#header-help i.fa-mobile {
  float: right;
  line-height: 35px;
  margin: 0 5px 0 0;
}
#header-help .fa-question-circle {
  line-height: 35px;
  margin: 0 0px 0 0;
  /*float: right;*/
}
#header-help .shake {
  -webkit-animation-name: shake;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: shake;
  -moz-animation-duration: 1s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  animation-name: shake;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
#header-international .fa-globe {
  float: none;
  line-height: 35px;
  margin: 0;
}
#header-help:hover i {
  color: #555;
}
#header-international {
  color: #13457c;
  font-size: 22px;
  position: relative;
  text-align:center;
}
#header-international .h3 {
  margin: 0 5px 0 0;
  cursor: pointer;
  float: left;
}
#header-international span {
  color: #A7A7A7;
  font-size: 12px;
  vertical-align: middle;
}
#header-international i {
  cursor: pointer;
}
#header-international i:hover {
  color: #555;
}
#header-search {
  color: #13457c;
  font-size: 22px;
}
#header-search.searching {
  border-left: none;
  border-right: none;
}
#header-search .form-group {
  margin: 0;
  text-align:center;
  width:auto;
}
#header-search .form-group .input-group {
  margin: 0px auto;
  text-align:center;
  width:auto;
}
#header-search .input-group-btn {
  width: auto;
  line-height: 35px;
}
#header-search .form-text {
  display: none;
  width: 250px;
  margin-right: 5px;
  margin-top: 2px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  font-size: 16px;
  letter-spacing: 0.3px;
  margin-top: 2px;
  padding: 3px 12px;
  height: auto;
  box-shadow: none;
}
#header-search button {
  background: transparent;
  border: none;
  color: #13457c;
  font-size: 1em;
  padding: 0;
  outline: 0;
  line-height: 35px;
}
#header-search button:hover {
  color: #555;
}
#header-search button.form-submit {
  display: none;
}
.main-container {
  position: relative;
  //background: #fff;
}
.main-container.firefox-container:before {
  box-shadow: -20px 0 17px -24px inset;
}
.main-container.firefox-container:after {
  box-shadow: 20px 0 17px -24px inset;
}
section.block-system-main {
  margin-top: 50px;
}
.col-sm-12 {
  padding-left: 0;
  padding-right: 0;
}
body.admin-menu section.col-sm-12 {
  margin-top: 20px;
}
.footer {
  margin: 0 -15px;
  padding: 0;
  border: none;
}
footer .inner-wide {
  background-color: #f0f0f0;
  padding-bottom: 40px;
}
footer a,
footer a:active,
footer a:visited {
  color: #fff;
  text-decoration: none !important;
}
footer a:hover {
  color: #42c0f6;
  text-decoration: none;
}
footer .left {
  margin-top: 20px;
}
footer .left ul, footer .mid ul {
  list-style-type: none;
  margin: 0px auto;
  padding: 0px;
  text-align: center;
}
footer .left ul li, footer .mid ul li {
  list-style-type: none;
  margin: 0 0 10px 0;
  font-size: 0.9em;
}
footer .right {
  padding-top: 25px;
  @media (min-width: 992px) {
    padding-left: 20px;
  }
}
footer .right .get-connected {
  display: block;
  text-align: center;
  @media (min-width: 768px) {
    text-align: left;
  }
  font-weight: 700;
  text-transform: uppercase;
  color: #706f73;
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;


  padding-bottom: 15px;
  &:hover {
    opacity: 0.8;
  }
}
footer .right .line {
  border-bottom: 1px solid #113a66;
  width: 50%;
  margin: 10px auto 15px;
  height: 1px;
}
footer .right .get-connected i {
  border: 1px solid #fff;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  vertical-align: bottom;
  line-height: 1.2;
}
footer .right .get-connected:hover {
  color: #42c0f6;
}
footer .right .get-connected:hover i {
  border-color: #42c0f6;
}
footer .right ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: center;
  @media (min-width: 768px) {
    text-align: left;
  }
}
footer .right ul li {
  display: inline-block;
  margin: 0 5px;
}
footer .right ul li a {
  font-size: 1.6em;
  border: 1px solid #a7adb2;
  width: 45px;
  height: 45px;
  display: block;
  text-align: center;
}
footer .right ul li a i {
  line-height: 2;
}
footer .right ul li a span {
  display: block;
  text-indent: -9999px;
  position:absolute;
}
footer .right ul li a:hover {
  border-color: #42c0f6;
}
footer .right .copyright {
  text-align: center;
  @media (min-width: 768px) {
    text-align: left;
  }
  margin: 15px auto 0 auto;
  color: #adafb1;
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-size: 13px;
  line-height: 23px;

}
header nav.col-md-9 {
  padding: 0;
}
#header-top nav {
  position: absolute;
  top: 55px;
  left: 0;
  width: 100%;
}
#kerrdental-nav {
  display: none;
  margin: 0;
  padding: 0;
  list-style-type: none;
  z-index: 20;
  position: relative;
  clear: both;
  overflow: hidden;
}
#kerrdental-nav ul {
  background-color: #dad9d9;
}
#kerrdental-nav li {
  padding: 0;
  margin: 0;
  display: block;
  position: relative;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  display: block;
  background-color: #dad9d9;
  width: 100%;
}
#kerrdental-nav li li.last {
  padding-bottom: 0;
}
#kerrdental-nav li.link-depth-1 {
  cursor: pointer;
}
#kerrdental-nav li ul li {
  height: auto;
}
#kerrdental-nav li a {
  display: block;
  color: #13457c;
  padding: 4px 10px;
  white-space: nowrap;
  text-align: left;
  font-size: 0.9em;
}
#kerrdental-nav li.active a, #kerrdental-nav li.active-trail a {
  text-decoration: none;
  outline: none;
  color: #13457c;
}
#kerrdental-nav li a:hover, #kerrdental-nav li a:focus, #kerrdental-nav li a:active {
  text-decoration: none;
}
#kerrdental-nav li.active li a, #kerrdental-nav li.active-trail li a {
  border: 0;
}
#kerrdental-nav ul > li.active a, #kerrdental-nav ul > li a {
  padding: 10px 40px 10px 20px;
  margin-top: 0;
  //font-size: 1em;
  text-align: left;
}
#kerrdental-nav li a.menutype-drop:after {
  content: "\f078";
  font-family: 'FontAwesome';
  font-size: 50%;
  padding-left: 5px;
  vertical-align: middle;
}
#kerrdental-nav li ul li a.menutype-drop {
  position: relative;
}
#kerrdental-nav li ul li a.menutype-drop:after {
  position: absolute;
  right: 10px;
  content: "\f078";
  font-size: 1.5em;
}
#kerrdental-nav li ul li a:hover {
  text-decoration: none;
  /*background-color: #33bef2;*/
}
#kerrdental-nav li ul {
  margin: 0;
  padding: 0;
  position: static;
  top: 52px;
  left: -100%;
  display: none;
  z-index: 21;
  background-color: #8b949b !important;
}
#kerrdental-nav li.menu-our-company ul {
  left: -70%;
}
#kerrdental-nav li ul.depth-1 {
  margin-left: 30px;
}
#kerrdental-nav li ul.depth-2 {
  margin-left: 60px;
}
#kerrdental-nav li ul li {
  width: 100%;
  padding: 0;
  margin: 0;
  position: static;
}
#kerrdental-nav li ul ul {
  display: none;
  top: 0;
  /*background: transparent;*/
  position: static;
  left: 0;
}
#kerrdental-nav li ul ul a {
  /*color: #000;*/
}
#kerrdental-nav li.category-matrix {
  width: 100%;
}
#kerrdental-nav #matrix-container {
  width: 100%;
  background-color: #f8f8f8;
  padding-top: 1px;
}
#kerrdental-nav #matrix-container .top-tabs a {
  float: none;
  width: 100%;
  text-align: left;
  display: block;
  cursor: pointer;
  padding: 10px 10px 10px 35px;
  border: none;
  color: #fff;
  white-space: normal;
}
#kerrdental-nav #matrix-container .content-container {
  clear: both;
  display: none;
  margin-top: 2px;
  padding-left: 1px;
  min-height: 400px;
  width: 100%;
}
#kerrdental-nav #matrix-container .content-container.active {
  display: table;
  height: 100%;
}
#kerrdental-nav #matrix-container .left-tabs {
  width: 25%;
  height: 100%;
  display: table-cell;
  opacity: 1;
  visibility: visible;
  vertical-align: top;
  text-align: left;
  color: #706f73;
  font-size: 0.9em;
  background: #e5e5e5;
  padding-top: 5px;
}
#kerrdental-nav #matrix-container .left-tabs a {
  cursor: pointer;
  padding: 5px 15px 5px 15px;
  position: relative;
  border: none;
  color: #706f73;
  white-space: normal;
}
#kerrdental-nav #matrix-container .left-tabs a span {
  margin-right: 10px;
}
#kerrdental-nav #matrix-container .left-tabs a:hover {
  background-color: transparent;
}
#kerrdental-nav #matrix-container .left-tabs a:hover span, #kerrdental-nav #matrix-container .left-tabs a.active span {
  border-bottom: 1px solid #13457c;
  padding-bottom: 1px;
  font-weight: bolder;
}
#kerrdental-nav #matrix-container .right-contents {
  display: table-cell;
  height: 100%;
  vertical-align: top;
  /*width: 78%;*/
}
#kerrdental-nav #matrix-container .right-contents .content-holder {
  display: none;
  text-align: left;
  /*padding-bottom: 125px;*/
}
#kerrdental-nav #matrix-container .right-contents .content-holder.active {
  display: block;
}
#kerrdental-nav #matrix-container .right-contents .content-holder .product {
  display: inline-block;
  margin: 10px 5px 25px 20px;
  width: 105px;
  padding: 0;
  white-space: normal;
  border: none;
  vertical-align: top;
  color: #706f73;
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  letter-spacing: 0.3px;
  line-height: 20px;
}
#kerrdental-nav #matrix-container .right-contents .content-holder .product span.img {
  height: 108px;
  margin-bottom: 5px;
  display: block;
  border: 1px solid #c2c2c2;
  padding: 3px;
  background-color: #fff;
}

#kerrdental-nav #matrix-container .right-contents .content-holder .product img {
  max-width: 100%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform:translateY(-50%);
  -moz-transform:translateY(-50%);
  -ms-transform:translateY(-50%);
  -o-transform:translateY(-50%);
}
#kerrdental-nav #matrix-container .right-contents .content-holder .product:hover {
  background: transparent;
}
#kerrdental-nav #matrix-container .right-contents .content-holder .product:hover img {
  opacity: 0.8;
  visibility: visible;
}
#kerrdental-nav #matrix-container .right-contents .content-holder .info {
  /*width: 75%;*/
  vertical-align: top;
  padding: 15px 15px 15px 20px;
  left: 25%;
  bottom: 0;
}
#kerrdental-nav #matrix-container .right-contents .parent-products {
  vertical-align: top;
  padding: 15px 15px 15px 20px;
  left: 25%;
  bottom: 0;
  .descript {
    .header {
      font-family: Helvetica, Arial, sans-serif;
      width: 100%;
      padding-bottom: 5px;
      color: #706f73;
      font-size: 20px;
      font-weight: 400;
      line-height: 23px;
      margin-bottom: 5px;
      border-bottom: 1px solid #ADAFB1;
    }
    p {
      font-size: 13px;
    }
  }
}

#kerrdental-nav #matrix-container .right-contents .parent-products .product {
  display: inline-block;
  margin: 0 15px 25px 0;
  width: 105px;
  padding: 0;
  white-space: normal;
  border: none;
  vertical-align: top;
  color: #706f73;
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-size: 14px;
  letter-spacing: 0.3px;
  line-height: 20px;
  span {
    display: flex;
    height: 105px;
    justify-content: center;
    align-items: center;
    border: 1px solid #c2c2c2;
    margin-bottom: 5px;
  }
  img {
    max-width: 100%;
    height: auto;
    display: block;
    padding: 3px;
    background-color: #fff;
  }
}

#kerrdental-nav #matrix-container .right-contents .parent-products .descript .see-all {
  text-align: center;
  .btn {
    margin-top: 10px;
    display: inline-block;
    text-align: center;
    line-height: 20px;
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 16px;
    padding: 10px 20px;
    color: #fff;
  }
}

#kerrdental-nav #matrix-container .right-contents .content-holder .info .descript{
  min-height: 100px;
  color: #706f73;
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  letter-spacing: 0.3px;
  line-height: 20px;
}
#kerrdental-nav #matrix-container .right-contents .content-holder .info .header {
  font-family: "Helvetica Neue", Arial, sans-serif;
  width: 100%;
  padding-bottom: 5px;
  margin-bottom: 5px;
  color: #706f73;
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
  margin-bottom: 20px;

}
#kerrdental-nav #matrix-container .right-contents .content-holder .info .header a:hover {
  color: #13457c;
  background-color: transparent;
}
#kerrdental-nav #matrix-container .right-contents .content-holder .see-all {
  padding: 5px 0 10px;
}
#kerrdental-nav #matrix-container .right-contents .content-holder .see-all a {
  color: #000;
  border: 0;
  display: inline-block;
  font-family: 'PlutoSansMedium';
  padding: 0;
  text-align: center;
  margin-top: 10px;
}
#kerrdental-nav #matrix-container .right-contents .content-holder .see-all a.btn {
  width: auto;
  padding: 10px 25px;
  color: #fff;
  font-size: 16px;
  letter-spacing: 0.3px;
  font-family: 'Helvetica Neue';
  font-weight: 700;
  background-color: #5d9632;
  text-transform: capitalize;
}
#kerrdental-nav #matrix-container .right-contents .content-holder .see-all a:hover {
  color: #898989;
  background-color: transparent;
}
#kerrdental-nav #matrix-container .right-contents .content-holder .see-all a.btn:hover {
  background-color: #706f73;
  color: #fff;
}
#kerrdental-nav li.submenu-columns ul {
  width: 685px;
  text-align: left;
  top: 0;
  background-color: transparent;
}
#kerrdental-nav li.submenu-columns ul li {
  width: 100%;
  margin-right: -5px;
  vertical-align: top;
}
#kerrdental-nav #specialty-dentist, #kerrdental-nav #specialty-endo, #kerrdental-nav #specialty-hygen, #kerrdental-nav #specialty-lab {
  margin: 0;
  line-height: 1.5;
  white-space: initial;
  text-align: left;
  padding: 0;
  position: relative;
  width: 100%;
  height: auto;
  padding: 5px 0;
  border: none;
  background-color: transparent;
  white-space: normal;
}
@media (max-width: 991px) {
  /* #kerrdental-nav #ul-398{
     margin-left: 30px;
     position:relative;
     z-index:10;
     top:0px;
     left:0px;
     border-left:1px solid #fff;
     border-right:1px solid #fff;
   }
   #kerrdental-nav #ul-740 {
     margin-left: 30px;
     position:relative;
     z-index:9;
     top:0px;
     left:0px;
     border-left:1px solid #fff;
     border-right:1px solid #fff;
   }*/
  #kerrdental-nav li a.menutype-drop:after {
    font-size: 1.3rem;
  }
  #header-top nav {
    z-index: 60;
  }
  #kerrdental-nav li ul.depth-1 {
    margin-left: 0;
  }
  #kerrdental-nav li ul.depth-2 {
    margin-left: 0;
    width: auto;
  }
  #kerrdental-nav li ul.depth-2 li.leaf {
    /*padding-left:35px;*/
    height:50px;
  }
  #kerrdental-nav li ul.depth-2 li.leaf a {
    line-height:30px;
  }
  #kerrdental-nav #specialty-dentist,
  #kerrdental-nav #specialty-endo,
  #kerrdental-nav #specialty-hygen,
  #kerrdental-nav #specialty-lab {
    line-height: 40px;
  }
  #kerrdental-nav #show-product-categories > a,
  #kerrdental-nav .depth-1 .submenu-columns > a {
    background: none;
  }
  #kerrdental-nav #show-product-categories > a:hover,
  #kerrdental-nav .depth-1 .submenu-columns > a:hover {
    /*background-color: #5d9632;*/
  }
  #kerrdental-nav .depth-1 .submenu-columns .depth-2 > li {
    border-bottom: 1px solid #FFF;
    overflow: hidden;
  }
  #kerrdental-nav .depth-1 .submenu-columns .depth-2 > li:last-child {
    border-bottom: none;
  }
  #kerrdental-nav > li {
    background-color: #428BCA;
    min-height: 40px;
    border-bottom: 1px solid #809abe;
  }
  #kerrdental-nav li:last-child {
    border: none;
  }
  #kerrdental-nav li ul li  {
    /*background-color: #428BCA;*/
    background-color: #8d959c;
    border-bottom: 1px solid #ddd;
  }
  #kerrdental-nav li ul li.active-d2 > a.menutype-drop {
    /*background-color: #428BCA;*/
    background-color: #8d959c;
  }
    @media (min-width: 992px) {
        #kerrdental-nav li ul li.active-d2 > a.menutype-drop:hover {
            background-color: #5d9632;
        }
    }
  /*#kerrdental-nav li :hover {*/
    /*background-color: #05ABED;*/
  /*}*/
  /*#kerrdental-nav li.active-d1, #kerrdental-nav li .active-d2 {
    background-color: #10365F;
  }*/
  #kerrdental-nav li a {
    display: block;
    color: #fff;
    padding: 4px 10px;
    width: 100%;
    white-space: nowrap;
    text-align: left;
    font-size: 16px;
    letter-spacing: 0.3px;
    height:100%;
    line-height:30px;
  }
  #kerrdental-nav li.active a, #kerrdental-nav li.active-trail a {
    text-decoration: none;
    outline: none;
    color: #fff;
    /*background-color:#428BCA;*/
  }
  #kerrdental-nav li.active-d2 a, #kerrdental-nav li.active-trail a, #kerrdental-nav li ul li a:hover {
    text-decoration: none;
    outline: none;
    /*color: #fff !important;*/
    /*background-color:#428BCA;*/
    /*background-color: #bbb;*/
  }
  #kerrdental-nav li.first {
    border-top:1px solid #fff;
  }
}
#mobile-menu-button {
  display: block;
  height: 100%;
  line-height: 55px;
  cursor: pointer;
  float: left;
  color: #fff;
  font-size: 32px;
  z-index: 1;
}
#mobile-menu-button .fa-bars {
  padding-right: 20px;
  padding-top: 10px;
  height: 55px;
  vertical-align: top;
  border-right: 1px solid #fff;
}
#mobile-menu-button .fa-times {
  padding-right: 22px;
  padding-top: 10px;
  height: 55px;
  vertical-align: top;
  border-right: 1px solid #fff;
}

#home-carousel-container {
  position: relative;
  max-width: 2000px;
  width: 100%;
  margin: 0px auto 0 auto;
}
#home-carousel-container .container {
  position: absolute;
  background-color: transparent;
  left: 0;
  right: 0;
}
#home-carousel-container .slides .no-slide {
  display: none;
}
#home-carousel-container .slides a {
  //height: 230px;
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  display: block;

  //@include breakpoint(375px) {
  //  height: 270px;
  //}
  //
  //@include breakpoint(480px) {
  //  height: 320px;
  //}
  //
  //@include breakpoint(560px) {
  //  height: 400px;
  //}
  //
  //@include breakpoint($screen-sm) {
  //  height: 400px;
  //}
}
#home-carousel-container .flex-control-paging {
  position: absolute;
  bottom: 25px;
  left: 0;
  z-index: 5;
}
#home-carousel-container .flex-direction-nav .flex-prev, #home-carousel-container .flex-direction-nav .flex-next {
  position: absolute;
  top: 40%;
  left: 20px;
  color: #706f73;
  display: block;
  text-align: center;
  font-size: 50px;
  border: 1px solid #706f73;
  border-radius: 55px;
  width: 55px;
  height: 55px;
  line-height: 1;
  padding-right: 5px;
  z-index: 5;
  text-decoration:none;
}
#home-carousel-container .flex-direction-nav .flex-next {
  left: auto;
  right: 20px;
  padding-right: 0;
  padding-left: 5px;
}
#home-carousel-container .flex-direction-nav .flex-prev:hover, #home-carousel-container .flex-direction-nav .flex-next:hover {
  color: #113a66;
  border-color: #113a66;
}
#home-carousel-container .flex-direction-nav a:before {
  font-family: 'FontAwesome';
  content: "\f104";
  color: #5d9632;
  font-size: 50px;
}
#home-carousel-container .flex-direction-nav a.flex-next:before {
  content: "\f105";
}
#home-carousel {
  background: transparent;
  border: none;
  margin: 0;
}
body.front .main-container {
  padding-top: 0;
}
#home-specialties {
  margin: 20px 0 0 0;
}
#home-specialties ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  margin-left: -6px;
  margin-right: -4px;
}
#home-specialties ul li {
  padding: 5px 5px;
}
#home-specialties ul li a {
  display: block;
  width: 100%;
  height: 225px;
  position: relative;
  text-align: center;
  font-family: 'PlutoSansLight';
  overflow: hidden;
  padding: 0 8px;
  max-width: 285px;
  margin: 0 auto;
}
#home-specialties ul li a:hover {
  text-decoration: none;
}
#home-specialties ul li a .main-overlay {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 225px;
  top: 0;
  left: 0;
  background-color: rgba(5, 36, 70, 0.9);
  display: block;
}
#home-specialties ul li a .overlay {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 225px;
  top: 100%;
  left: 0;
  background-color: rgba(255, 255, 255, 0.9);
  display: block;
  font-weight: bold;
}
#home-specialties ul li a h2.main-title  {
  padding: 0 10px;
  margin:0;
  font-family: PlutoSansLight;
  color: #fff;
  font-size: 1.8em;
  line-height:normal;
  position: relative;
  top: 50%;
  -webkit-transform:translateY(-50%);
  -moz-transform:translateY(-50%);
  -ms-transform:translateY(-50%);
  -o-transform:translateY(-50%);
}
#home-specialties ul li a .name {
  font-size: 1.8em;
  display: block;
  padding: 10% 10px 0 10px;
}
#home-specialties ul li a .descript {
  display: block;
  font-size: 1em;
  margin-top: 10px;
  padding: 0 20px;
  line-height: 1.8;
}
@media (max-width: 550px) {
  #home-specialties {
    margin: 0;
  }
  #home-specialties ul {
    margin: 0px;
  }
  #home-specialties ul li {
    padding: 2px;
  }
  #home-specialties ul li a {
    height:125px;
  }
  #home-specialties ul li a .main-overlay {
    height:125px;
  }
  #home-specialties ul li a .overlay {
    height:125px;
  }
  #home-specialties ul li a h2.main-title {
    font-size: 21px;
  }
  #home-specialties ul li a .name {
    font-size: 18px;
    line-height: normal;
  }
  #home-specialties ul li a .descript {
    line-height: 16px;
    font-size: 16px;
    letter-spacing: 0.3px;
  }
}
#home-specialties ul li a#home-specialty-3 .name {
  padding-top: 4%;
}
#home-specialties ul li a .more {
  display: inline-block;
  margin: 0 auto;
  text-align: center;
  padding: 10px 30px;
  background: #706f73;
  color: #fff;
  margin-top: 10px;
}
#home-specialties ul li a#home-specialty-1:before,
#home-specialties ul li a#home-specialty-2:before,
#home-specialties ul li a#home-specialty-3:before,
#home-specialties ul li a#home-specialty-4:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: transparent url(../img/kavo-home-specialty-bg.png) 0 0 no-repeat;
}
#home-specialties ul li>a#home-specialty-2:before {
  background-position: -287px 0;
}
#home-specialties ul li a#home-specialty-3:before {
  background-position: -571px 0;
}
#home-specialties ul li a#home-specialty-4:before {
  background-position: -855px 0;
}
@media (min-width: 551px) and (max-width: 991px) {
  #home-specialties ul li a#home-specialty-1,
  #home-specialties ul li a#home-specialty-3 {
    margin-right: 10px;
  }
  #home-specialties ul li a#home-specialty-2,
  #home-specialties ul li a#home-specialty-4 {
    margin-left: 10px;
  }
  #home-specialties ul li a#home-specialty-3,
  #home-specialties ul li a#home-specialty-4 {
    margin-top: 20px;
  }
}
@media (max-width: 400px) {
  #home-specialties ul li a#home-specialty-1:before {
    background-position: -21px 0;
  }
  #home-specialties ul li a#home-specialty-2:before {
    background-position: -332px -19px;
  }
  #home-specialties ul li a#home-specialty-3:before {
    background-position: -614px 0;
  }
  #home-specialties ul li a#home-specialty-4:before {
    background-position: -890px 0;
  }
}

#home-mid-2 {
  @include breakpoint($screen-md) {
    display: flex;
  }
}

.product-group-blocks {
  padding: 0 5px 0px;

  .field-collection-container,
  .field-collection-view {
    padding: 0;
    margin: 0;
    border: 0;
  }

  & > .field-collection-container {
    @include breakpoint($screen-sm) {
      height: 100%;
      display: flex;
    }
  }
}

.field-name-field-product-group-blocks {
  width: 100%;

  & > .field-items {
    @include breakpoint($screen-sm) {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      height: 100%;
    }

    & > .field-item {
      margin-bottom: 15px;

      @include breakpoint($screen-sm) {
        flex: 50%;
        max-width: 50%;
      }

      &.even {
        @include breakpoint($screen-sm) {
          padding-right: 8px;
        }
      }

      &.odd {
        @include breakpoint($screen-sm) {
          padding-left: 8px;
        }
      }
    }
  }

  .field-item {
    .field-collection-view {
      background: #f8f8f8;
    }
  }

  .field-collection-view {
    height: 100%;
  }

  .field-collection-view .content {
    cursor: pointer;
  }

  .field-collection-item-field-product-group-blocks {
    .field-name-field-image {
      img {
        width: 100%;
      }
    }
    .product-group-block-title {
      color: #706f73;
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.05px;
      margin: 0;
      padding: 15px 20px 0;
      display: block;
      text-decoration: none;
    }

    .field-name-field-description {
      color: #1c2c3a;
      font-size: 14px;
      font-weight: 300;
      padding: 5px 65px 20px 20px;

      @include breakpoint($screen-md) {
        font-size: 16px;
        letter-spacing: 0.3px;
      }
      p {
        color: #706f73;
        font-family: "Helvetica Neue", Arial, sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 23px;
      }
      ul, ol {
        padding-left: 15px;
        list-style: none;
        li {
          color: #706f73;
          font-family: "Helvetica Neue", Arial, sans-serif;
          font-size: 16px;
          letter-spacing: 0.3px;
          line-height: 20px;
          &:before {
            content: "•";
            color: #7ea547;
            display: inline-block;
            width: 1em;
            margin-left: -1em
          }
        }
      }
    }
  }
}

.top-links-wrapper {
  padding: 0 5px 0 8px;
  display: flex;
}

.top-links {
  padding: 25px 40px;
  margin-bottom: 15px;
  background: #f8f8f8;
  width: 100%;

  .field-name-field-top-links-title {
    .field-item {
      color: #1c2c3a;
      font-family: "Helvetica Neue", Arial, sans-serif;
      font-size: 20px;
      font-weight: 400;
      padding-bottom: 15px;
    }
  }

  .top-link {
    font-size: 16px;
    letter-spacing: 0.3px;
    line-height: 24px;
    padding: 25px 0;
    display: block;
    border-bottom: 1px solid rgba(#b3b2b2, 0.51);
    color: #5d9632;
    font-family: "Helvetica Neue", Arial, sans-serif;
    font-size: 16px;
    letter-spacing: 0.3px;
    font-weight: 400;
    line-height: 24px;
  }

  & > .field-item {
    &:last-child {
      .top-link {
        border-bottom: 0;
      }
    }
  }
}


#product-groups-slider {
  @include breakpoint($screen-md) {
    height: 350px;
  }

  .inner-wide {
    background-color: #f0f0f0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 200%;
    margin-left: -50%;
    margin-right: 50%;
  }

  .line-container {
    position: relative;
  }

  ul.slides {
    height: 100%;
    position: relative;
    z-index: 1;
    padding-bottom: 30px;

    @include breakpoint($screen-sm) {
      padding-bottom: 50px;
    }

    @include breakpoint($screen-md) {
      padding-bottom: 0;
    }

    li {
      height: 100%;
      color: #706f73;
      font-family: "Helvetica Neue", Arial, sans-serif;
      font-size: 16px;
      letter-spacing: 0.3px;
      line-height: 20px;

      .text {
        padding-top: 40px;
        padding-right: 70px;

        h2 {
          margin: 0 0 5px;
          font-weight: 400;
          letter-spacing: -0.08px;
          color: #706f73;
          font-family: "Helvetica Neue", Arial, sans-serif;
          font-size: 30px;
          line-height: 33px;
          @include breakpoint($screen-md) {
            font-size: 30px;
          }
        }

        h4 {
          margin: 0;
          padding: 20px 0 10px;
          color: #706f73;
          font-family: "Helvetica Neue LT Std Lt", Arial, sans-serif;
          font-size: 20px;
          font-weight: 400;
          line-height: 23px;

          @include breakpoint($screen-md) {
            font-size: 20px;
          }
        }

        .field-name-field-slide-description {
          margin-top: 10px;
          font-size: 14px;
          line-height: 1.2;

          @include breakpoint($screen-md) {
            font-size: 16px;
            letter-spacing: 0.3px;
            line-height: 1.4;
          }
        }

        .buttons {
          margin-top: 30px;

          a {
            display: inline-block;
            border: 1px solid #5d9632;
            padding: 7px 25px;
            margin-right: 15px;
            margin-bottom: 20px;
            font-size: 16px;
            letter-spacing: 0.3px;
            color: #ffffff;
            min-width: 225px;
            text-align: center;
            background-color: #5d9632;
            text-decoration: none;
            font-weight: 700;
            line-height: 23px;
            font-family: Helvetica, sans-serif;
            &:last-child {
              margin-right: 0;
            }
            &:hover {
              //color: #5d9632;
              //background-color: transparent;
              border-color: #8cc63e;
              background-color: #8cc63e;
            }
          }
        }
        ul {
          list-style: none;
          padding-left: 1em;
          li {
            &:before {
              content: "•";
              color: #7ea547;
              display: inline-block;
              width: 1em;
              margin-left: -1em;
            }
          }
        }
      }

      .image {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        height: 100%;
        z-index: 0;
        min-height: 240px;
        margin: 15px 0;


        @include breakpoint($screen-sm) {
          min-height: 300px;
        }

        @include breakpoint($screen-md) {
          min-height: initial;
          margin: 0;
          background-size: auto;
        }
      }
    }
  }

  .flex-control-nav {
    bottom: 20px;
    z-index: 10;
  }

  .flex-direction-nav {
    display: none;
  }
}

#home-about-2 {
  padding-bottom: 30px;

  p {
    line-height: 22px;
  }

  .col {
    .image {
      float: left;
      margin-left: -60px;
    }
    .text {
      float: left;
      h2 {
        margin-top: 0;
      }
    }
  }

  .right {
    @include breakpoint($screen-md) {
      display: flex;
      justify-content: center;
    }

    .home-mid-support,
    .home-mid-buy,
    .home-mid-demo {
      position: relative;

      @include breakpoint($screen-md) {
        padding: 0 10px 0 45px;
      }
    }

    .col {
      text-align: center;

      @include breakpoint($screen-md) {
        text-align: left;
      }

      h4 {
        width: 100%;
        padding: 0;
        font-size: 2.2em;
        display: block;

        span {
          position: relative;
          left: 0;
          right: auto;
          top: 5px;
          bottom: auto;
          width: 35px;
          height: 33px;

          @include breakpoint($screen-md) {
            position: absolute;
            top: 0;
          }
        }
      }

      a {
        padding: 10px 15px;
        text-align: center;
        min-width: 225px;
        font-family: Helvetica, sans-serif;
      }
    }
    .field-name-field-product-support-subtitle,
    .field-name-field-homepage-how-buy-subtitle,
    .field-name-field-homepage-schedule-subtitle {
      font-size: 16px;
      letter-spacing: 0.3px;
      font-weight: 300;
      line-height: 18px;
      min-height: 55px;
    }
  }
}

#home-about {
  padding: 0 15px 100px 15px;
}
#home-about .inner-wide {
  background-color: #e5e5e5;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 200%;
  margin-left: -50%;
  margin-right: 50%;
}
#home-about h5 {
  font-size: 1.2em;
  font-family: "Helvetica Neue LT Std Lt", Arial, sans-serif;
  margin-top: 0;
}
#home-about p {
  font-size: 13px;
  line-height: 18px;
  font-family: "Helvetica Neue LT Std Lt", Arial, sans-serif;
  strong {
    font-size: 14px;
  }
}
#home-about a.more, #home-about a.less {
  color: #666;
  font-size: 0.9em;
}
#home-about a.more:hover, #home-about a.less:hover {
  text-decoration: none;
  color: #706f73;
}
#home-about .more-text {
  display: none;
}
#home-mid {
  margin-right: 2px;
}
#home-mid .left {
  padding-left: 0;
}
#home-mid .home-mid-procedure {
  position: relative;
  padding-top: 1px;
  margin-top: 0;
  font-size: 16px;
  letter-spacing: 0.3px;
  line-height: 1.5;
}
#home-mid .home-mid-procedure .procedure-image img {
  width: 100%;
}
#home-mid .home-mid-procedure .inner .inner-bg {
  position: absolute;
  width: 300%;
  height: 100%;
  margin-left: -100%;
  top: 0;
  right: 0;
  background-color: #5d9632;
}
#home-mid .home-mid-procedure .inner .row {
  color: #fff;
  padding: 5px 0;
}
#home-mid .home-mid-promotion .promo-links {
  position: relative;
  margin-top: 20px;
}
#home-mid .home-mid-procedure .inner a, #home-mid .home-mid-promotion .promo-links div {
  display: inline-block;
}

#home-mid .home-mid-procedure .inner a, #home-mid .home-mid-promotion .promo-links a {
  display: inline-block;
  border: 1px solid #fff;
  padding: 5px 39px 5px 39px;
  margin: 0 auto;
  text-align: center;
  font-family: Helvetica, sans-serif;
  color: #fff;
  font-size: 16px;
  letter-spacing: 0.3px;
  font-weight: 700;
  line-height: 23px;
}
#home-mid .home-mid-procedure .inner a {
  margin-left: -15px;
  white-space: nowrap;
}
#home-mid .home-mid-promotion .promo-links a {
  margin-left: 20px;
}
#home-mid .home-mid-procedure .inner .title {
  font-size: 1.8em;
  padding-top: 10px;
  margin: 0;
}
#home-mid .home-mid-procedure .inner .title .col-md-11 {
  padding-left: 0;
}
#home-mid .home-mid-procedure .line-container, #home-mid .home-mid-promotion .line-container {
  position: relative;
}
#home-mid .home-mid-promotion .line {
  right: 0;
}
#home-mid .home-mid-procedure .inner .bottom {
  padding: 15px 0;
}
#home-mid .home-mid-promotion {
  position: relative;
  margin-top: 15px;
  color: #fff;
  padding-bottom: 25px;
}
#home-mid .home-mid-promotion .promotion-bg {
  background-position: top right;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  width: 200%;
  height: 100%;
  margin-left: -100%;
  top: 0;
  right: 0;
}
#home-mid .home-mid-promotion .title {
  font-family: 'PlutoSansMedium';
  font-size: 1.3em;
  margin-top: 20px;
  letter-spacing: 8px;
}
#home-mid .home-mid-promotion .promo-title {
  font-family: 'PlutoSansLight';
  font-size: 1.8em;
}
#home-mid .home-mid-promotion .promo-image {
  margin-top: 20px;
  text-align: left;
}

#home-mid .home-mid-promotion .promo-image div {
  width: 200px;
  height: 200px;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

@media (max-width: 768px) {
  .node-product #product-info-sections .info-section-content h3 {
    display: inline-block;
  }
  #home-mid {
    margin: 0px;
  }
  #home-mid .home-mid-procedure .inner {
    margin-top: 0px;
  }
  #home-mid .home-mid-procedure {
    margin-top: 0px;
  }
  #home-mid .home-mid-promotion .promo-links {
    margin:0px auto;
    padding:0;
    text-align:center;
  }
  #home-mid .home-mid-promotion .promo-links a {
    width: 70%;
    margin: 0px auto;
    background-color: #65B403;
    z-index: 10;
  }
  .home-mid-procedure {
    margin: 0px;
    margin-right: -15px;
  }
  #home-mid .home-mid-procedure .inner a,  #home-mid .home-mid-promotion .promo-links a {
    margin-top: 20px;
    padding: 10px 10px;
    position: relative;
    width: 100%;
    max-width: 200px;
    margin-left: 0;
  }
  #home-mid .home-mid-procedure .inner .bottom {
    padding: 15px 0px 20px 0px;
    margin-right: 0;
  }
  #home-mid .home-mid-procedure .inner .bottom .xs-centered {
    padding-right: 25px;
  }
  #home-mid .home-mid-promotion {
    margin-top: 0px;
    margin-right: -15px;
    color: #fff;
    padding-bottom: 15px;
  }
  #home-mid .home-mid-promotion .row {
    margin: 0;
  }
  #home-mid .home-mid-promotion .promotion-bg {
    width: 100%;
  }
  #home-mid .home-mid-promotion p {
    margin-right: 15px;
  }
  #home-mid .home-mid-promotion .promo-image div {
    margin:0px auto;
    padding:0;
    position:relative;
  }
  #home-mid .home-mid-procedure .inner .inner-bg {
    max-width: 100%;
  }
  #home-mid .home-mid-procedure .inner .title .col-md-11 {
    padding-left: 15px;
  }
  #home-about {
    text-align: left;
  }
  #home-mid .home-mid-promotion .promo-links div {
    width: 100%;
    max-width: 200px;
    margin: 0 10px;
  }
}
@media (max-width: 1199px) {
  #home-mid .home-mid-promotion .promo-image div {
    width: 175px;
    height: 175px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  #home-mid .home-mid-promotion .promo-links a {
    padding-left: 25px;
    padding-right: 25px;
  }

  #home-mid .home-mid-promotion .promo-links .odd a {
    margin-top: 20px;
  }

  footer .right ul li {
    margin: 0;
  }
}

@media (max-width: 530px) {
  #home-mid .home-mid-promotion .promo-links div {
    display: block;
    margin: 0 auto;
    text-align: center;
  }

  #home-mid .home-mid-promotion > .row > div {
    width: 100%;
  }

  #home-mid .home-mid-procedure .inner .bottom > div {
    width: 100%;
    padding-right: 25px;
  }

  #home-mid .home-mid-procedure .line, #home-mid .home-mid-promotion .line {
    right: 0;
  }
}

#home-mid .right {
  text-align: center;
  font-size: 13px;
}
#home-mid .home-mid-buy, #home-mid .home-mid-demo, #home-mid .home-mid-sample, #home-mid .home-mid-support{
  padding: 40px 0;
  @media (min-width: 992px) {
    padding: 40px 85px;
  }
}
#home-mid .home-mid-line {
  height: 1px;
  width: 100%;
  position: relative;
}
#home-mid .home-mid-line .line {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid #706f73;
}
#home-mid .home-mid-demo h4 span {
  background-position: 0 -39px;
}
#home-mid .home-mid-sample h4 span {
  background-position: 0 -79px;
}
#home-mid #home-mid-demo-select, #home-mid #home-mid-sample-select {
  width: 50%;
}
#home-mid .right p {
  color: #706f73;
  max-width: 400px;
  margin: 0 auto 20px auto;
}
#home-mid .right a {
  display: inline-block;
  font-family: "Helvetica Neue", Arial, sans-serif;
  padding: 9px 15px;
  font-size: 16px;
  letter-spacing: 0.3px;
  min-width: 225px;
  font-weight: 700;
  color: #fff;
}
#home-mid .right a:hover {
  text-decoration: none;
  background-color: #706f73;
}
#home-mid .form-demo {
  display: inline-block;
  font-family: "Helvetica Neue", Arial, sans-serif;
  padding: 10px 60px;
  font-size: 1.3em;
  background-color: #5d9632;
  color: #fff;
  outline: none;
  border: none;
}
#home-mid .form-demo:hover {
  text-decoration: none;
  background-color: #706f73;
}
#term-products .views-row {
  width: 100%;
  padding: 0 10px;
  margin-bottom: 20px;
  @media (min-width: 992px) {
    width: 25%;
  }
}
a.product-listing {
  padding: 20px 45px;
  border: 1px solid #ddd;
  display: inline-block;
  font-size: 1.2em;
  vertical-align: top;
  height: 100%;
  width: 100%;
  text-align: left;
  position: relative;
  text-decoration: none;
}

.node-type-product,
.node-type-landing-page {
  a.product-listing {
    width: 195px;
    padding: 20px;
    .title {
      margin-bottom: 20px;
    }
  }
}

a .hover-bg {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ddd;
  z-index: 1;
}
a.product-listing img {
  display: block;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform:translateY(-50%);
  -moz-transform:translateY(-50%);
  -ms-transform:translateY(-50%);
  -o-transform:translateY(-50%);
}
a.product-listing span.img {
  display: block;
  height: 155px;
  margin-bottom: 10px;
}
a.product-listing span.title {
  display: block;
  color: #706f73;
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
  margin-bottom: 20px;
}
a.product-listing span.subtitle {
  display: block;
  text-align: left;
  padding: 10px 5px;
  border-top: 1px solid #6e716e;
  margin-bottom: 15px;
  font-family: "Helvetica Neue LT Std Lt", Arial, sans-serif;
  font-size: 13px;
  line-height: 14px;
  font-weight: 400;
  color: #706f73;
}
a.product-listing:hover {
  text-decoration: none;
}
a:hover .hover-bg {
  opacity: 0.1;
  visibility: visible;
}
#category-expand-boxes {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: none;
}
#category-expand-boxes li {
  display: inline-block;
}
#category-expand-boxes a {
  display: block;
  text-indent: -9999px;
  width: 25px;
  color: #fff;
  text-align: center;
  padding: 4px 0;
  font-size: 12px;
  white-space: nowrap;
  font-family: Helvetica, Arial, sans-serif;
  -webkit-transition: width 0.3s ease-in-out;
  -moz-transition: width 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
  text-transform: uppercase;
}
#category-expand-boxes a.active {
  text-indent: 0;
  width: auto;
  padding-left: 10px;
  padding-right: 10px;
}
#category-expand-boxes a:hover {
  text-decoration: none;
  background-color: #5d9632;
}
.node-product #category-expand-boxes {
  text-align: right;
  margin-bottom: 35px;
}
.node-product h1 {
  display: none;
}
.node-product .line {
  position: relative;
  margin: 0 0 15px 0;
}
.node-product .line-inner {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid #adafb1;
}
.node-product .top-row {
  margin: 15px 0 0 0;
}
.overview-product .node-product .top-row {
  padding-bottom: 2em;
}
.overview-product .common-header .inner-wide {
  background-color: #8d959c;
}
/* overview products header font color is white */
.overview-product #product-top-right .product-heading-wrapper h1 {
  font-family: 'PlutoSansLight';
  font-size: 2.5em;
  padding-top: 5px;
  display: inline-block;
  margin-bottom: 5px;
  color: #fff;
  margin-top: 0;
}
.overview-product .common-header .field-name-body {
  color: #fff;
}
.overview-product .common-header .field-name-body a {
  color: #fff;
}
.overview-product .node-product #category-expand-boxes {
  margin-bottom: 0;
}

.node-product .flexslider {
  border: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  margin-bottom: 0;
  padding: 0 24px;
}
.node-product .flexslider:hover .flex-direction-nav .flex-prev,
.node-product .flexslider:hover .flex-direction-nav .flex-next {
  font-size: 0;
  width: auto;
  height: auto;
}

.node-product .flexslider:hover .flex-direction-nav .flex-prev {
  left: 0;
  top: 125px;
}

.node-product .flexslider:hover .flex-direction-nav .flex-next {
  right: 0;
  top: 125px;
}

.node-product .flexslider .flex-control-thumbs {
  width: 80%;
  padding-top: 10px;
  text-align: center;
  //position:absolute;
  bottom: -76px;
  left: 0;
  right: 0;
  margin: auto;
}

.node-product .flexslider .flex-control-thumbs li {
  display: inline-block;
  float: none;
  width: 50px;
  height: 50px;
  margin: 0 6px;
}

.node-product .flex-control-thumbs img {
  max-height: 100%;
  width: auto;
  margin: 0 auto;
}

.node-product .flex-direction-nav a:before {
  content: "\f104";
  font-family: 'FontAwesome';
  font-size: 45px;
  line-height: 1;
}
.node-product .flex-direction-nav a.flex-next:before {
  content: "\f105";
  font-family: 'FontAwesome';
}
.node-product .flexslider .slides img {
  width: auto;
  max-width: 100%;
  margin: 0 auto 10px auto;
  max-height: 250px;
}
.node-product #product-top-right {
  padding: 0 15px;
}
.node-product #product-top-right h1 {
  display: inline;
  font-size: 32px;
  color: #4a4a4a;
  font-family: "Helvetica Neue LT Std Lt", Arial, sans-serif;
  font-size: 30px;
  line-height: 33px;
}
.overview-product .product-heading-wrapper {
  float: left;
}
.node-product #product-top-right .title-category {
  display: inline-block;
  margin-left: 30px;
}
.node-product #product-top-right .title-category a {
  color: #00aeef;
  font-family: 'PlutoSansLight';
  letter-spacing: 2px;
}
.node-product #product-top-right .title-category a:hover {
  text-decoration: none;
  color: #706f73;
}
.overview-product .node-product #product-top-right .title-category a {
  color: #fff;
}
.overview-product .node-product #product-top-right .title-category a:hover {
  text-decoration: underline;
}
.node-product #product-top-right h2 {
  color: #4a4a4a;
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-size: 20px;
  line-height: 23px;
  clear: both;
}
.overview-product .node-product #product-top-right h2 {
  color: #fff;
}
.overview-product .category-quick-links {
  padding: 1px 0;
}
.node-product #product-top-right .title-promo {
  display: inline-block;
  vertical-align: super;
  background-color: #d4d4d4;
  padding: 2px 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  margin-left: 20px;
}
.node-product #product-top-right .field-name-body {
  line-height: 1.7;
  margin-top: 10px;
}
.node-product #product-top-right .field-name-body p{
  line-height: 22px;
  color: #706f73;
}
.node-product #product-top-right .field-name-body ul{
  color: #706f73;
  font-family: "Helvetica Neue LT Std Lt";
  font-size: 16px;
  letter-spacing: 0.3px;
  line-height: 20px;
}
.node-product #product-top-right .product-buttons {
  margin-top: 20px;
  text-align: left;
}
.node-product #product-top-right .product-buttons a {
  min-width: 225px;
  padding: 10px 20px;
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 0.3px;
  font-weight: 700;
  line-height: 23px;
}
.overview-product .node-product #product-top-right .product-buttons {
  text-align: left;
}
.node-product #product-top-right .product-buttons .field-item {
  display: inline-block;
    margin: 7px 7px 7px 0;
}
.node-product #product-featured {
  background-color: #006a95;
  margin: 50px 0 0 -15px;
  position: relative;
  font-size: 14px;
}
.node.node-product #product-featured .inner-wide-right {
  background-color: #f0f0f0;
}
.node-product #product-featured .inner-wide-right {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #006a95;
}
.node-product #product-featured .left {
  padding: 15px 0 15px 0;
  line-height: 0;
}
.node-product #product-featured .left iframe {
  width: 550px;
  height: 300px;
}
.node-product #product-featured .left img {
  width: auto !important;
  height: auto !important;
  max-width: 100%;
  max-height: 100%;
  margin:-15px;
}
.node-product #product-featured .field-name-field-featured-video {
  text-align: center;
}
.node-product #product-featured .right {
  padding: 20px 20px 20px 20px;
  left: 0px;
  color: #fff;
  line-height: 1.5;
  position:relative;
}
.node-product #product-featured .right a:not(.btn) {
  color: #fff;
  text-decoration: underline;
}
.node-product #product-featured .right a:not(.btn):hover {
  text-decoration: none;
}
.node-product #product-featured h3 {
  //color: #fff;
  font-family: 'PlutoSansLight', 'Helvetica Neue LT Std Lt', 'Helvetica Neue', Arial, sans-serif;
  font-size: 24px;
  margin-top: 0;
}
.node-product #product-featured .line-inner {
  border-color: #adafb1;
}
.node-product #product-featured .line {
  margin-bottom: 23px;
}
.node-product #product-featured .center {
  color: #fff;
  padding: 50px 20px;
}
.node-product #product-featured .center h3 {
  margin-left: -20px;
}
.node-product #product-featured .center .line {
  margin-left: -20px;
}
.node-product #product-related,
.node-type-landing-page #product-related {
  padding-bottom: 30px;
  margin-top: 105px;
  width: 100%;
  position: relative;
}

//.node-type-landing-page, #product-groups-slider {
  .left-line {
    position: relative;
    display: inline-block;
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 99999%;
      right: 0;
      z-index: 1;
      height: 1px;
      background-color: #adafb1;
    }
  }
  .right-line {
    position: relative;
    display: inline-block;
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 9999%;
      z-index: 1;
      height: 1px;
      background-color: #adafb1;
      @media (max-width: 991px) {
        width: 9999%;
        right: 0;
      }
    }
  }
//}

.landing-header {

}

.node-type-landing-page #product-related {
  margin-top: 0;
}

.node-product #product-related .inner-wide,
.node-type-landing-page #product-related .inner-wide {
  background-color: #f8f8f8;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.node-product #product-related h3,
.node-type-landing-page #product-related h3 {
  color: #706f73;
  font-family: "Helvetica Neue LT Std Lt", Arial, sans-serif;
  font-size: 30px;
  line-height: 33px;
  text-align: left;
  margin: 25px 0 15px;
}
.node-product #product-related ul,
.node-type-landing-page #product-related ul {
  display: table;
  list-style-type: none;
  padding: 0;
}

.node-product #product-related ul {
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.node-product #product-related li,
.node-type-landing-page #product-related li {
  min-height: 377px;
  min-width: 201px;
  border: 1px solid #dfdfdf;
  background-color: #ffffff;
  display: inline-block;
  padding: 0;
  vertical-align: top;
  margin: 0 20px 20px 0;
}
.node-product #product-related li {
  @media (max-width: 767px) {
    width: 100%;
    min-height: auto;
    margin: 0 0 20px 0;
    .product-listing {
      width: 100%;
    }
  }
}


.node-product #product-related li a,
.node-type-landing-page #product-related li a {
  border: none;
  min-height: inherit;
  background-color: #fff;
}
/* overview sub products */
.node-product #product-overview-subproducts {
  width: 100%;
  text-align: center;
  position: relative;
  margin: 30px 0 60px 0;
}
.node-product #product-overview-subproducts .inner-wide {
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.node-product #product-overview-subproducts h3 {
  color: #706f73;
  font-size: 2.5em;
  font-family: 'PlutoSansLight';
}
.node-product #product-overview-subproducts ul {
  display: table;
  list-style-type: none;
  margin: 0 auto;
  padding: 0;
}
.node-product #product-overview-subproducts li {
  display: inline-block;
  padding: 0;
  margin: 0;
  vertical-align: top;
}
.node-product #product-overview-subproducts li a {
  background-color: #fff;
}

.node-product #product-info {
  margin: 30px 0 60px 0;
}
.node-product #product-info-left {
  padding: 0;
}
.node-product #product-info-list {
  display: none;
  list-style-type: none;
  padding: 13px 0 0 0;
  background-color:#13457c;
}
.node-product #product-info-list li.list,
.node-product #product-info-list li#more-info-drop {
  display: inline-block;
  text-align: center;
  font-size: 20px;
  line-height: 23px;
  color: #fff;
  font-family: 'Helvetica Neue';
  margin: 0 60px 10px 0px;

}
.node-product #product-info-list li.list span {
  cursor: pointer;
  padding-bottom: 5px;
  display: inline-block;
  font-weight:normal;
  border-bottom: 3px solid transparent;
}
.node-product #product-info-list li.list span:hover {
  border-color: #c8c8c8;
}
.node-product #product-info-list li.list.active span {
  border-bottom: 3px solid #05abed;
}
.node-product #product-info-list li#more-info-drop {
  position: relative;
  text-align: right;
  cursor: pointer;
}
.node-product #product-info-list li#more-info-drop .more-title:after {
  content: "\f107";
  font-family: 'FontAwesome';
  padding-left: 10px;
}
.node-product #product-info-list #more-info-options {
  position: absolute;
  right: -85px;
  top: 100%;
  width: 200%;
  max-width: 250px;
  display: none;
  padding-top: 17px;
  z-index: 2;
}
.node-product #product-info-list li#more-info-drop.active #more-info-options {
  display: block;
}
.node-product #product-info-list #more-info-options span {
  display: block;
  color: #fff;
  padding: 10px 20px;
  text-align: left;;
}
.node-product #product-info-list #more-info-options span:last-child {
  border-bottom: 2px solid #fff;
}
.node-product #product-info-list #more-info-options span:hover {
  //color: #13457c;
}
.node-product #product-info-mobile-list {
  margin: 0px auto 0px auto;
  text-align: center;
  background-color:#f0f0f0;
  padding:20px 0 ;
}
.node-product #product-info-mobile-list .select2 {
  text-align: left;
  min-width: 275px;
  width: 85% !important;
  font-size: 1.2em;
  margin:0 5%;
}
.node-product #product-info-sections img {
  max-width: 100%;
}
.node-product #product-info-sections div.info-section {
  display: none;
}
.info-colors {
  padding-top: 20px;
}

.node-product #product-info-sections .info-section-content {
  padding: 25px 15px 0 15px;
}
.node-product #product-info-sections .info-section-content > h3 {
  font-family: 'PlutoSansLight';
  color: #706f73;
  /*display: none;*/
  border-bottom: 1px solid #706f73;
  padding-bottom: 3px;
  margin-top: 0;
}
/*.node-product #product-info h1 {*/
/*font-family: 'PlutoSansLight';*/
/*color: #706f73;*/
/*display: none;*/
/*border-bottom: 1px solid #706f73;*/
/*padding-bottom: 3px;*/
/*}*/
.node-product #product-info h1 {
  font-weight: normal;
  margin-top: 12px;
  margin-bottom: 10px;
}
.node-product #product-info h2 {
  margin-top: 15px;
  margin-bottom: 8px;
  color: #706f73;
}
/*********** Start Compare page css***********/

#info-compare .table>tbody>tr>th, #info-compare .table>tbody>tr>td {
  border: 0;
}

#info-compare .table tbody tr.blue {
  background: #13457C;
  color: #ffffff;
}

#info-compare .table tbody tr.gray {
  background: #f0f0f0;
}

#info-compare .table tbody tr.white {
  background: #ffffff;
}

.node-product #product-info-sections .info-section-content tr.blue th h3 {
  color: #ffffff;
  font-size: 20px;
}

@media (min-width:450px){
  .compare-mobile{display:none;}
  .compare-desktp{display:block;}
}

@media (max-width:451px){
  .compare-mobile{display:block;}
  .compare-desktp{display:none;}
  #info-compare .table tbody tr.gray ul, #info-compare .table tbody tr.white ul{
    padding-left: 16px;
  }
}

@media (max-width:991px){
  .node-product #product-info-sections .info-section-content tr.blue th h3 {display:inline-block;}
}

/*********** End Compare page css ***********/

.node-product #product-info-sections div.info-section.active {
  display: block;
}
.node-product #product-info-sections div.info-section .field-name-field-product-section-image {
  width: 100%;
  max-height: 250px;
  overflow: hidden;
}
.node-product #product-info-sections div.info-section .field-name-field-product-section-image img {
  width: 100%;
  height: auto;
}
.node-product #info-videos .vid-container {
  margin-bottom: 30px;
}
.node-product #info-videos .field-name-field-product-video-title {
  margin-top: 10px;
  color: #706f73;
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
}
.node-product #info-videos .field-name-field-product-video-description {
  margin-top: 10px;
  color: #706f73;
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  letter-spacing: 0.3px;
  font-weight: 400;
  line-height: 20px;
}
.node-product #info-faqs .field-name-field-product-faq-question {
  cursor: pointer;
  margin-bottom: 5px;
  color: #4a4a4a;
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
}
.node-product #info-faqs .field-name-field-product-faq-question:before {
  float: right;
  content: "\f196";
  font-family: 'FontAwesome';
  font-weight: normal;
}
.node-product #info-faqs .field-name-field-product-faq-question.open:before {
  content: "\f147";
}
.node-product #info-faqs .field-collection-container {
  border: none;
}
.node-product #info-faqs .field-collection-view {
  padding-top: 5px;
  margin: 0 0 30px 0;
  border-bottom: none;
}
.node-product #info-faqs .field-collection-view-links,
.node-product #info-faqs .action-links,
.node-product #info-faqs .field-name-field-product-faq-answer {
  display: none;
  color: #706f73;
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  letter-spacing: 0.3px;
  font-weight: 400;
  line-height: 20px;
  padding-bottom: 10px;
}
.node-product #info-videos .row {
  margin: 15px 0;
}
.node-product #info-skus table {
  border-collapse: collapse;
  width: 100%;
}
.node-product #info-skus table, .node-product #info-skus table th, .node-product #info-skus table td {
  border: 1px solid #666;
}
.node-product #info-skus table th {
  background-color: #d4d4d4;
  padding: 5px 10px;
}
.node-product #info-skus table td {
  padding: 5px 10px;
}
.node-product #info-skus table td {
  padding: 5px 10px;
}
.view-commerce-cart-form .views-field-line-item-title span, .view-id-commerce_cart_summary .views-field-line-item-title span, .node-product #info-buy {
  color: #706f73;
}
.view-commerce-cart-form .views-field-commerce-total,
.view-commerce-cart-form .views-field-field-sku-image {
  display: none;
}
.node-product .buynow-notice {
  margin: 30px 20% 0 20%;
  font-family: 'PlutoSansLight';
  font-size: 0.9em;
}
.node-product #info-buy .row {
  padding: 15px 0;
  margin: 0;
  border-bottom: 1px solid #ddd;
}
.node-product #info-buy .col-header {
  padding: 0;
  margin: 30px 0 0 0;
  font-family: 'PlutoSansMedium';
  font-size: 0.8em;
  color: #ddd;
}
.node-product #info-buy h4 {
  font-family: "Helvetica Neue LT Std Lt", Arial, sans-serif;
  font-size: 2.5em;
  margin: 0;
  padding: 0 0 10px 0;
  color: #706f73;
}
.node-product #info-buy .col-label {
  display: none;
  margin-top: 25px;
  color: #999;
}
.node-product #info-buy .col-image {
  min-height: 0;
  text-align: center;
  padding-left: 0;
  margin-bottom: 10px;
}
.node-product #info-buy .col-info {
  padding-left: 0;
}
.view-commerce-cart-form .views-field-line-item-title span, .view-id-commerce_cart_summary .views-field-line-item-title span, .node-product #info-buy .col-info span {
  display: block;
}
.node-product #info-buy .col-price {
  font-size: 1.1em;
  font-family: "Helvetica Neue", Arial, sans-serif;
  margin-top: 40px;
  padding-left: 0;
  color: #5d9632;
}
.node-product #info-buy .col-quantity {
  margin-top: 15px;
  padding: 0 5px;
}
.view-commerce-cart-form span.sku-sku, .view-id-commerce_cart_summary span.sku-sku, .node-product #info-buy .sku-sku {
  font-size: 0.9em;
  color: #706f73;
}
.view-commerce-cart-form span.sku-title, .view-id-commerce_cart_summary span.sku-title, .node-product #info-buy .sku-title {
  color: #706f73;
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
  margin-bottom: 10px;
}
.view-commerce-cart-form span.sku-description, .node-product #info-buy .sku-description {
  color: #706f73;
  font-size: 16px;
  letter-spacing: 0.3px;
}
.node-product #info-buy .col-add-cart {
  text-align: center;
  padding: 0;
}
.node-product #info-buy .btn {
  padding: 10px;
  width: 90%;
  max-width: 200px;
  min-width: 100%;
  /*  margin: 10px auto 20px auto;   */
  margin: 10px auto 5px auto;
  display: block;
}
.node-product #info-buy .btn.added {
  background-color: #ddd !important;
  color: #333;
  margin-bottom: 0;
  font-family: 'PlutoSansLight';
  cursor: default;
}
.node-product #product-info aside {
  margin-top: 0px;
}
.node-product #product-info aside div.field-name-field-product-sidebar-content {
  padding: 40px;
  margin-bottom: 30px;
  background: #f8f8f8;
  color: #706f73;
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  letter-spacing: 0.3px;
  font-weight: 400;
  line-height: 20px;
  a {
    color: #5d9632;
  }
  hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    height: 2px;
    background: rgba(151,151,151, 0.4);
  }
}
.node-product #product-info aside div.field-name-field-product-sidebar-content p img {
  max-width:100%
}
.node-product #product-info aside #product-promotion {
  padding: 20px;
  position: relative;
  color: #706f73;
}
.node-product #product-info aside .promo-bg {
  position: absolute;
  background: transparent url(../img/product-promo-bg.jpg) center center no-repeat;
  background-size: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.node-product #product-info aside .promo-container {
  position: relative;
}
.node-product #product-info aside #product-promotion .sect-heading {
  font-family: 'PlutoSansMedium';
  font-size: 1.3em;
  letter-spacing: 8px;
  text-transform: uppercase;
}
.node-product #product-info aside #product-promotion h4 {
  font-family: 'PlutoSansLight';
  font-size: 2em;
  margin-bottom: 5px;
  margin-top: 15px;
}
.node-product #product-info aside #product-promotion .line {
  margin-bottom: 0;
}
.node-product #product-info aside #product-promotion a {
  margin-top: 20px;
  font-family: 'PlutoSansLight';
  font-size: 1.5em;
  padding: 5px 50px;
  display: inline-block;
  color: #706f73;
  border: 2px solid #706f73;
}
.node-product #product-info aside #product-promotion a:hover {
  background-color: #706f73;
  color: #fff;
  text-decoration: none;
}
.node-product #product-info aside .product-buttons {
  margin-top: 20px;
  padding: 14px;
  font-size: 1.2em;
}
.node-product #product-info aside .product-buttons .field-item {
  margin-bottom: 20px;
}
.node-product #product-info aside .product-buttons .field-item a {
  width: 90%;
  margin: 0 auto;
  display: block;
  padding: 15px 0;
}
.node-product .less-descript-link, .node-product .full-description {
  display: none;
}
.node-product .less-descript-link, .node-product .more-descript-link {
  text-decoration: none;
}
.node-product .less-descript-link i, .node-product .more-descript-link i {
  margin-left: 4px;
}
.node-product #sku-filters {
  text-align: center;
  border: 0 !important;
  font-family: 'PlutoSansLight';
  margin-left: -15px !important;
  margin-right: -15px !important;

}
@media (max-width: 768px) {
  .node-product #sku-filters {
    padding-top:0px!important;
  }
}
.node-product #sku-filters span {
  padding-right: 20px;
}
.node-product #sku-filters span i {
  display: none;
  margin-left: 5px;
}
.node-product #sku-filters span.processing {
  padding-right: 0;
}
.node-product #sku-filters span.processing i {
  display: inline-block;
}
.node-product #sku-filters select {
  width: 90%;
  font-size: 1.2em;
}
.node-product #info-buy .grid-layout {
  margin-left: -15px;
  margin-right: -15px;
}
.node-product .grid-layout .sku-message {
  font-size: 1.2em;
  text-align: center;
  margin: 20px 0;
}
.node-product .grid-sku .inner {
  border: 1px solid #aeaeae;
  margin: 15px 0;
}
.node-product #info-buy .grid-sku .row {
  padding: 2px 0;
  margin: 0 10px;
  border-color: #b8b8b8;
}
.node-product #info-buy .grid-sku .row.last {
  border: none;
  padding-bottom: 5px;
}
.node-product .grid-sku .sku-attr div {
  padding-left: 0;
  padding-right: 0;
}
.node-product .grid-sku .sku-image {
  text-align: center;
  height: 220px;
}
.node-product .grid-sku .sku-image img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform:translateY(-50%);
  -moz-transform:translateY(-50%);
  -ms-transform:translateY(-50%);
  -o-transform:translateY(-50%);
  max-width: 100%;
}
.node-product #info-buy .grid-sku .sku-title {
  text-align: center;
  padding: 5px 0 10px 0;
  font-size: 1.4em;
}
.node-product #info-buy .grid-sku .sku-price {
  text-align: center;
  padding: 5px 0;
  color: #000;
}
.node-product .grid-sku .sku-attr-container {
  background-color: #ededed;
  font-size: 0.88em;
}
.node-product .grid-sku .sku-attr {
  font-size: 0.9em;
  color: #000;
}
.node-product .grid-sku .sku-attr .strong {
  font-weight: bold;
}
.node-product #sku-grid-list {
  display: table;
  width: 100%;
}
.node-product #info-buy .grid-sku .btn-primary {
  width: 100%;
  margin: 0;
  max-width: none;
}
.node-product #info-buy .grid-sku .btn.added {
  background-color: #999 !important;
  padding-bottom: 8px !important;
}
.node-product .grid-sku a {
  background-color: #999;
  display: block;
  text-align: center;
  color: #fff;
  padding-bottom: 10px;
}
.node-product .field-name-field-product-testimonials-intro {
  margin-bottom: 50px;
  color: #706f73;
  font-family: "Helvetica Neue LT Std Lt", Arial, sans-serif;
  font-size: 16px;
  letter-spacing: 0.3px;
  line-height: 20px;
  h3 {
    color: #4a4a4a;
    font-family: "Helvetica Neue", Arial, sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 23px;
    margin-bottom: 5px;
  }
}
.node-product .testimonial-row {
  margin-top: 20px;
}
.node-product .testimonial-image,
.node-product .testimonial-info {
  display: table-cell;
  vertical-align: top;
}
.node-product .testimonial-image {
  padding: 10px 0;
  width: 100px;
}
.node-product .testimonial-info {
  margin-top: 10px;
  .author-info {
    @media (max-width: 767px) {
      text-align: center;
      margin-bottom: 10px;
    }
  }
  .name {
    color: #4a4a4a;
    font-family: "Helvetica Neue", Arial, sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 18px;
  }
  .title {
    color: #5d9632;
  }
}
@media (max-width: 768px) {
  .node-product #sku-filters select {
    width: 90%;
    font-size: 1.2em;
    margin:0 5%;
  }
  .node-product #product-info-sections .info-section-content {
    margin: 0;
    padding: 25px 0 0 0;
  }
  .node-product .testimonial-row {
    margin: 0;
    padding:10px 0;
    border-bottom:1px solid #CCC;
  }
  .node-product .testimonial-image,
  .node-product .testimonial-info {
    display:block;
    vertical-align: top;
  }
  .node-product .testimonial-image {
    width: 115px;
    margin:0px auto;
  }
  .node-product .testimonial-info {
    clear:both;
    padding-left: 0px;
    width:100%;
  }
}
.node-product .testimonial-image div {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.node-product .field-name-field-product-testimonial-name {
  color: #706f73;
  font-family: 'PlutoSansLight';
  font-size: 1.1em;
  margin-top: 5px;
}
//.node-product .field-name-field-product-testimonial-quote .field-item:before,
//.node-product .field-name-field-product-testimonial-quote .field-item:after {
//  content: '"';
//}
.node-product .testimonial-text {
  position: relative;
  background-color: #f0f0f0;
  padding: 45px;
  color: #706f73;
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  letter-spacing: 0.3px;
  font-weight: 400;
  line-height: 20px;
  min-height: 220px;
  @media (min-width: 768px) {
    &:before {
      position: absolute;
      left: -14px;
      content: '';
      border-top: 14px solid transparent;
      border-bottom: 14px solid transparent;
      border-right: 14px solid #f0f0f0;
    }
  }
}
.node-product .field-name-field-product-testimonial-name .field-item:before {
  content: "-";
}
.node-page .field-name-body {
  width: 90%;
  color: #706f73;
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  letter-spacing: 0.3px;
  line-height: 18px;
}
.node-page .field-name-body h1,
.node-page .field-name-body h2,
.node-page .field-name-body h3 {
  /*font-family: 'PlutoSansMedium';*/
  font-family: 'PlutoSansLight';
  font-weight: normal;
}

.node-page .field-name-body h1 {
  margin-top: 12px;
  margin-bottom: 10px;
}

.node-page .field-name-body h2 {
  margin-top: 15px;
  margin-bottom: 8px;
}

.node-page .field-name-body h3 {
  color: #706f73;
  font-family: "Helvetica Neue";
  font-size: 16px;
  letter-spacing: 0.3px;
  font-weight: 700;
  line-height: 26px;
}

.node-page .field-name-body h3.title {
  margin-bottom: 15px;
  border-bottom: 1px solid #706f73;
  padding-bottom: 3px;
}

#collection-header,
#collection-header h1,
#collection-header h2,
#collection-header h3 {
  color: #fff;
}
#collection-header .line-inner {
  border-color: #fff;
}

/* Canada - hide price */
body.domain-2 .sku-attr-container .sku-attr.last {
  border: none !important;
}
body.domain-2 .node-product .buynow-notice,
body.domain-2.page-cart .views-field-commerce-unit-price,
body.domain-2.page-cart .views-field-commerce-total,
body.domain-2.page-cart .line-item-summary,
body.domain-2.page-cart .cart-footer-text,
body.domain-2.page-checkout .views-field-commerce-total,
body.domain-2.page-checkout .view-footer,
body.domain-2.page-checkout .checkout-notice {
  display: none;
}

.view-commerce-cart-form {
  width: 90%;
  margin: 0 auto;
}
body.page-checkout h1, .view-commerce-cart-form h1 {
  font-family: 'PlutoSansLight';
  color: #706f73;
}
body.page-checkout h1 {
  margin-bottom: 20px;
}
.view-commerce-cart-form thead {
  background-color: #f0f0f0;
  font-family: 'PlutoSansLight';
  color: #999;
  font-size: 0.9em;
}
.view-commerce-cart-form .table>thead>tr>th {
  border: none;
}
.view-commerce-cart-form .table>tbody>tr>td {
  border-top: none;
  border-bottom: 1px solid #ddd;
}
.view-commerce-cart-form span.sku-title a {
  color: #706f73;
}
.view-commerce-cart-form span.sku-title a:hover {
  color: #428bca;
  text-decoration: none;
}
.view-commerce-cart-form .views-field-edit-delete .btn {
  color: #999;
  background-color: transparent;
  border: none;
  padding: 0;
  font-size: 0.8em;
  text-transform: lowercase;
}
.view-commerce-cart-form input, .view-commerce-cart-form button {
  border-radius: 0;
}
body.page-checkout #commerce-checkout-form-checkout fieldset .no-pad-left,
body.page-checkout #commerce-checkout-form-checkout fieldset .no-pad-right {
  padding: 0;
}
body.page-checkout #commerce-checkout-form-checkout button, .view-commerce-cart-form button {
  padding-left: 25px;
  padding-right: 25px;
}
.view-commerce-cart-form #edit-submit {
  border: none;
  text-transform: capitalize;
}
//.view-commerce-cart-form #edit-continue {
//  margin: 5px auto 0 auto;
//  display: block;
//}
.view-commerce-cart-form .cart-footer-text {
  width: 80%;
  min-width: 250px;
  font-size: 0.8em;
  margin: 20px auto;
  color: #4e515e;
  font-family: 'PlutoSansLight';
  margin-bottom: 20px;
}
.view-commerce-cart-form .form-actions {
  clear: none;
  width: 80%;
  min-width: 260px;
  text-align: right;
  margin: 0 auto 30px auto;
}
body.page-checkout #commerce-checkout-form-checkout {
  margin: 50px 5%;
}
#commerce-checkout-form-checkout h2,
#commerce-checkout-form-checkout h3,
#commerce-checkout-form-checkout h4 {
  clear:both;
  float:left;
  width:100%;
  margin:0 0 10px 0;
  padding:0;
}
#commerce-checkout-form-checkout h3{
  font-size: 14px;
  line-height: 1.42857;
  color: #333;
}
#commerce-checkout-form-checkout h4{
  margin:0 0 20px 0;
  color: #706f73;
  font-family: 'PlutoSansLight';
  font-size: 0.9em;
  line-height: 1.3;
}
body.page-checkout #commerce-checkout-form-checkout fieldset {
  border: none;
  box-shadow: none;
  -webkit-box-shadow: none;
}
body.page-checkout #edit-cart-contents {
  float: right;
  width: 40%;
  min-width: 300px;
  background-color: #f0f0f0;
  display: none;
}
body.page-checkout #edit-cart-contents .panel-heading {
  background-color: #c3c3c3;
  border-radius: 0;
}
body.page-checkout #edit-cart-contents .panel-body {
  padding: 0;
}
body.page-checkout #edit-cart-contents .view {
  padding: 15px;
}
body.page-checkout #edit-cart-contents table {
  margin: 0;
}
body.page-checkout #edit-cart-contents table tr:first-child td {
  border-top: none;
}
body.page-checkout #edit-cart-contents .views-field-quantity {
  text-transform: uppercase;
}
body.page-checkout #edit-cart-contents .checkout-notice {
  background-color: #fff;
  padding-top: 30px;
}
body.page-checkout #edit-cart-contents .table-striped>tbody>tr:nth-child(odd)>td, body.page-checkout #edit-cart-contents .table-striped>tbody>tr:nth-child(odd)>th {
  background-color: #f0f0f0;
}
body.page-checkout #edit-customer-profile-billing, body.page-checkout #edit-buttons {
  width: 100%;
  min-height: 300px;
  margin-bottom: 0;
}
body.page-checkout #edit-buttons {
  text-align: center;
}
body.page-checkout #edit-customer-profile-billing .panel-heading {
  background-color: transparent;
  border: none;
  color: #706f73;
  font-family: 'PlutoSansMedium';
  padding: 0 0 5px 5px;
  text-transform: uppercase;
}
body.page-checkout #edit-customer-profile-billing .panel-body {
  padding: 5px;
}
body.page-checkout #edit-customer-profile-billing .panel-body p {
  padding: 5px;
}
body.page-checkout #edit-customer-profile-billing label {
  display: none;
}
body.page-checkout #edit-customer-profile-billing .form-group .form-group {
  width: 100%;
}
body.page-checkout #edit-customer-profile-billing input, body.page-checkout #edit-customer-profile-billing select {
  border-radius: 0;
}
body.page-checkout #edit-buttons {
  float: left;
}
body.page-checkout .field-name-commerce-order-total table {
  width: 100%;
}
body.page-checkout .checkout-completion-message {
  margin: 50px 20%;
}
body.page-checkout .checkout-completion-message .btn {
  padding: 15px 40px;
  margin: 20px 0;
}
.cart-empty-page {
  font-family: 'PlutoSansLight';
  font-size: 1.5em;
  width: 80%;
  margin: 50px auto;
}
#product-category-buttons {
  list-style-type: none;
  text-align: center;
  margin: 0;
  padding: 0;
  position: relative;
}
#product-category-buttons a {
  font-size: 1em;
  text-align: center;
  padding: 6px 10px;
  color: #fff;
  font-family: 'PlutoSansLight';
  display: inline-block;
  min-width: 170px;
  margin: 10px 5px;
  border-width: 2px;
  border-style: solid;
  white-space: nowrap;
}
#product-category-buttons a:hover {
  text-decoration: none;
}
#product-category-buttons li {
  padding: 0;
  display: inline-block;
}
#product-category-buttons a:hover {
  background-color: transparent;
}
#term-header #category-overs {
  float: right;
  margin-right: 15px;
}
.view-id-product_category_listing.view-display-id-product_line .view-filters {
  display: none;
}
#category-specialties {
  bottom:5px!important;
  font-family: 'PlutoSansLight';
  color: #000;
  left:35px!important;
  z-index: 5;
  padding:0;
  margin:0;
}
@media (max-width: 1199px) {
  /*Chrome CSS here*/
  #category-specialties {
    font-family: 'PlutoSansLight';
    color: #000;
    left:0px!important;
    z-index: 5;
    padding:0;
    margin:0;
    position:relative!important;
    bottom:0px!important;
  }

  .node-product #product-featured .left iframe {
    width: 500px;
  }
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
  /*Chrome CSS here*/
  #category-specialties {
    bottom:5px!important;
    font-family: 'PlutoSansLight';
    color: #000;
    left: 35px!important;
    z-index: 5;
    padding:0;
    margin:5px 0 0 0;
  }
}
@media (max-width: 768px) {
  #category-specialties {
    left:0px!important;
  }
}
#category-specialties span.label {
  font-size: 0.9em;
  text-transform: uppercase;
}
#category-specialties .specialty-filter {
  display: inline-block;
  background-color: #fff;
  position: relative;
  width: 240px;
  cursor: pointer;
  border:1px solid #00AEEF;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #fff transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0px;
  height: 0px;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
}
.specialty-filter .select2-selection__arrow {
  background-color: #00AEEF;
  width: 25px;
}
.specialty-filter .select2-selection__arrow {
  height: 20px;
  position: absolute;
  top: 0px;
  right: 0px;
  width: 20px;
}
.specialty-filter .select2-selection--single {
  cursor: pointer;
}
#category-specialties .specialty-filter #specialty-active {
  display: block;
  padding: 0px 10px;
}
#category-specialties .specialty-filter #specialty-active i.fa-chevron-down {
  float: right;
  margin-top: 3px;
}
#category-specialties .specialty-filter #specialty-active i.fa-sort-down {
  float: right;
  position:relative;
  top:0px;
  left:3px;
  z-index:2;
  color:#fff;
}
#category-specialties .specialty-filter #specialty-active i.fa-sort-up {
  float: right;
  position:relative;
  top:5px;
  left:3px;
  z-index:2;
  color:#fff;
}
#category-specialties .specialty-filter:hover i.fa-chevron-down {
  color: #428bca;
}
#category-specialties .specialty-filter ul {
  position: absolute;
  top: 100%;
  left: -1px;
  list-style-type: none;
  color: #13457c;
  border-left: 1px solid #00AEEF;
  border-right: 1px solid #00AEEF;
  margin: 0;
  padding: 0;
  background-color: #fff;
  width: 240px;
  display: none;
}
#category-specialties .specialty-filter ul li {
  white-space: nowrap;
  padding: 7px 40px 7px 10px;
  border-bottom: 1px solid #00AEEF;
}
#category-specialties .specialty-filter ul li:hover {
  background-color: #f0f0f0;
}
#page-products, #term-products, #term-subcategory-links {
  margin: 50px auto 60px auto;
}
#term-products h4, #term-subcategory-links h4 {
  color: #ffffff;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
  padding: 15px 40px;
  margin: 0;
}
#section-category-buttons {
  margin: 30px 0 0 0;
  position: relative;
  padding: 20px 0 10px 0;
}
@media (max-width: 768px) {
  #term-products h4, #term-subcategory-links h4 {
    font-size: 24px;
  }
  #section-category-buttons {
    margin: 1px 0 0 0;
  }
}
#section-category-buttons .inner-wide {
  background-color: #e6e6e6;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
#page-top-text,
#term-top-text {
  margin: 50px auto 35px auto;
}
#page-footer-text {
  margin-top: 50px;
}

#term-footer-text {
  padding: 40px 0 20px;
  background-color: #f8f8f8;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    height: 100%;
    width: 100%;
    bottom: 0;
    left: -100%;
    background-color: #f8f8f8;
  }
  &:after {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    height: 100%;
    width: 100%;
    bottom: 0;
    right: -100%;
    background-color: #f8f8f8;
  }
  a {
    margin: 0 15px 20px 0;
    display: inline-block;
    border: 1px solid #5d9632;
    color: #5d9632;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 16px;
    letter-spacing: 0.3px;
    font-weight: 700;
    line-height: 23px;
    text-decoration: none;
    padding: 10px 30px;
    &:hover {
      color: #8cc63e;
      border-color: #8cc63e;
    }
  }
}

.bottom-links {
  padding: 40px 0 20px;
  background-color: #f8f8f8;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    height: 100%;
    width: 100%;
    bottom: 0;
    left: -100%;
    background-color: #f8f8f8;
  }
  &:after {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    height: 100%;
    width: 100%;
    bottom: 0;
    right: -100%;
    background-color: #f8f8f8;
  }
  .wrap-links {
    overflow: hidden;
  }
  .field-item {
    display: inline-block;
    float: left;
    .btn-primary, a {
      margin: 0 15px 20px 0;
      display: inline-block;
      border: 1px solid #5d9632;
      color: #5d9632;
      background: transparent;
      font-family: Helvetica, Arial, sans-serif;
      font-size: 16px;
      letter-spacing: 0.3px;
      font-weight: 700;
      line-height: 23px;
      text-decoration: none;
      padding: 10px 30px;
      &:hover {
        color: #8cc63e;
        border-color: #8cc63e;
      }
    }
  }
}

@media (max-width: 768px) {
  #term-footer-text, .page-taxonomy .bottom-links {
    padding:20px 15px;
  }
}
.product-listing-view .views-row {
  display: inline-block;
  text-align: center;
  vertical-align: top;
}
.resource-asset-products .product-listing-view .views-row {
  width: 200px;
  @media (max-width: 768px) {
    width: 100%;
  }
  margin: 0 20px 20px 0;
}

#subcategory-links, .product-listing-view .view-filters ul {
  list-style-type: none;
  background-color: #f0f0f0;
  padding: 20px 40px;
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-gap: 50px;
  -moz-column-gap: 50px;
  column-gap: 50px;
  -webkit-backface-visibility:hidden;
  a.product-listing {
    padding: 20px;
  }
}
#subcategory-links li,#subcategory-links li a, .product-listing-view .view-filters ul li {
  display:inline-block;
  width: 33%;
  min-width: 200px;
  margin-bottom: 16px;
  vertical-align: top;
  color: #706f73;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 16px;
  letter-spacing: 0.3px;
  font-weight: 400;
  line-height: 20px;
}

@media (min-width: 768px) {
  #resource-center-quick-search .sds-container {
    padding-right: 3px;
    padding-left: 0;
  }
  #resource-center-quick-search .ifu-container {
    padding-left: 2px;
    padding-right: 0;
  }
  .node-product .flexslider {
    padding: 0 0;
    margin-bottom: 30px;
  }

  .node-product .flex-direction-nav a:before {
    font-size: 64px;
  }

  #events-header{
    min-height:230px;
  }
  #events-header .inner-wide {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 200%;
    background: #fff url(../img/Events.jpg) no-repeat scroll center bottom;
    background-size: cover;
  }
  #events-header,
  #events-header h1,
  #events-header h2,
  #events-header h3 {
    color: #13457C;
  }
  #events-header #term-header .line-inner{
    border-color: #13457C;
  }
  #news-header{
    min-height:230px;
  }
  #news-header .inner-wide {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 200%;
    background: url(../img/news.jpg) no-repeat scroll center center;
    background-color:#fff!important;
  }
  #news-header,
  #news-header h1,
  #news-header h2,
  #news-header h3 {
    color: #13457C;
  }
  #news-header #term-header .line-inner{
    border-color: #13457C;
  }
  #specialty-header{
    min-height:230px;
  }

  .taxonomy-term.vocabulary-specialty #specialty-header .inner-wide {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 200%;
    background-color:#fff!important;
    background-size: auto auto;
  }

  .taxonomy-term.vocabulary-specialty, .taxonomy-term.vocabulary-specialty h1,
  .taxonomy-term.vocabulary-specialty h2,
  .taxonomy-term.vocabulary-specialty h3{
    color: #13457C;
  }

  .taxonomy-term.vocabulary-specialty #term-header .line-inner{
    border-color: #13457C;
  }

  #taxonomy-term-11 #term-header .inner-wide {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 200%;
    background: url(../img/Yellow_Header.jpg) no-repeat scroll center center;
    background-size: cover;
  }
  #taxonomy-term-12 #term-header .inner-wide {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 200%;
    background: url(../img/Green_Header.jpg) no-repeat scroll center center;
    background-size: cover;
  }
  #taxonomy-term-13 #term-header .inner-wide {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 200%;
    background: url(../img/Purple_Header.jpg) no-repeat scroll center center;
    background-size: cover;
  }
  #taxonomy-term-1741 #term-header .inner-wide {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 200%;
    background: url(../img/DarkGreen_Header.jpg) no-repeat scroll center center;
    background-size: cover;
  }
  #taxonomy-term-10 #term-header .inner-wide {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 200%;
    background: url(../img/Blue_Header.jpg) no-repeat scroll center center;
    background-size: cover;
  }
  .filters-wr > section.block:last-child {
    padding-bottom: 20px;
  }
}

#subcategory-links li span, .product-listing-view .view-filters ul li span {
  cursor: pointer;
  position: relative;
  display: inline-block;
  padding-bottom: 2px;
  border-bottom: 3px solid transparent;
}
#subcategory-links li:hover span, .product-listing-view .view-filters ul li:hover span {
  border-color: #c8c8c8;
}
.product-listing-view .view-filters ul li.active span {
  border-color: #00aeef;
}
#subcategory-links i, .product-listing-view .view-filters i {
  display: none;
  font-size: 12px;
  position: absolute;
  top: 4px;
  right: -20px;
}
.product-listing-view .view-filters i.icon-refresh-animate {
  display: inline-block;
  -webkit-animation-name: rotateThis;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: rotateThis;
  -moz-animation-duration: 1s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  animation-name: rotateThis;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.product-listing-view .view-content {
  font-size: 0;
}
.product-listing-view .view-empty {
  text-align: center;
  font-family: 'PlutoSansLight';
  font-size: 1.5em;
}
#category-listing-drop,
#subcategory-links-drop {
  margin: 20px auto;
  position: relative;
  width: 80%;
  text-align: center;
}
#category-listing-drop .select2,
#subcategory-links-drop .select2 {
  width: 100% !important;
  text-align: left;
}
#specialty-procedures {
  margin: 20px 20px 60px 20px;
  text-align: center;
}
#specialty-procedures .col-centered {
  float: none;
  text-align: left;
  margin-right: -4px;
}
#specialty-procedures .procedure-container {
  margin: 25px auto;
  height: 300px;
  max-width: 400px;
  position: relative;
}
#specialty-procedures .procedure-container .img-container{
  text-align: center;
}
#specialty-procedures .procedure-container img {
  max-width: 100%;
  width: 100%;
}
#specialty-procedures .procedure-info {
  width: 100%;
  background-color: #706f73;
  color: #fff;
  padding: 0 0 5px 0;
}
#specialty-procedures .more {
  float: right;
  margin: 13px 15px 0 10px;
  display: inline-block;
  border-radius: 40px;
  position: relative;
  width: 40px;
  height: 40px;
  line-height: 1;
  font-size: 2.8em;
  color: #fff;
  text-align: center;
  padding-left: 5px;
  background-color: #5d9632;
  &:hover {
    background-color: #8cc63e;
  }
  &:after {
    content: '';
    display: block;
    background-image: url(../img/pelton/arr_r.svg);
    width: 20px;
    height: 22px;
    background-size: cover;
    position: absolute;
    top: 9px;
    left: 9px;
  }

}
#specialty-procedures .more span {

}
#specialty-procedures .more:hover i {
  color: #175291;
  background-color: #f0f0f0;
}
#specialty-procedures h4 {
  padding: 0 5px 5px 20px;
  border-top: 1px solid #fff;
  margin-bottom: 5px;
  min-height: 57px;
  margin-right: 65px;
  margin-top: 0;
  position: relative;
}
#specialty-procedures h4 a {
  font-size: 1em;
  color: #fff;
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 0 5px 5px 20px;
  line-height: 1.3;
}
#specialty-procedures h4 a:hover {
  text-decoration: none;
  color: #f0f0f0;
}
#specialty-procedures .procedure-subtitle {
  padding: 15px 20px 15px 20px;
  min-height: 45px;
}
#specialty-procedures .procedure-subtitle h3{
  color: #fff;
}
#specialty-product-lines .row {
  position: relative;
  margin: 0;
  padding-left: 35px;
  padding-right: 35px;
}
#specialty-product-lines .odd .inner-wide {
  background-color: #e6e6e6;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
#specialty-product-lines .line-image {
  text-align: center;
  margin: 30px 0;
}
#specialty-product-lines .line-image img {
  max-width: 100%;
}
#specialty-product-lines .line-info {
  padding-right: 5%;
  margin: 50px 0 30px 0;
}
#specialty-product-lines .odd .line-info {
  padding-right: 0;
  padding-left: 5%;
}
#specialty-product-lines .line-content {
  margin-top: 10px;
}
#specialty-product-lines .line-content a {
  display: inline-block;
  background-color: #706f73;
  color: #fff;
  padding: 10px 20px;
  margin-top: 15px;
  font-family: 'Source Sans Pro', sans-serif;
  width: 100%;
  max-width: 300px;
}
#specialty-product-lines .line-content a:hover {
  background-color: #42c0f6;
  text-decoration: none;
}
#specialty-product-lines h5 {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 2em;
  display: inline-block;
}
#specialty-product-lines .line {
  position: relative;
  display: none;
  margin-top: 10px;
}
#specialty-product-lines .line-inner {
  position: absolute;
  top: 0;
  left: -400%;
  width: 500%;
  border-bottom: 1px solid #888;
}
#specialty-product-lines .even .line-inner {
  left: 0;
}
#specialty-product-lines .prod-line {
  margin: 35px 0;
}
#specialty-product-lines .prod-line-image {
  text-align: center;
  height: 200px;
}
#specialty-product-lines .prod-line-image img {
  max-width: 100%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}
#specialty-product-lines .prod-line h5 {
  font-size: 1.5em;
  padding-bottom: 10px;
  border-bottom: 1px solid #888;
  margin-top: 15px;
  width: 100%;
}
.node-procedure {
  width: 90%;
  margin: 0 auto 50px auto;
}
.node-procedure .procedure-social-mobile {
  margin-top: 40px;
}
.node-procedure blockquote {
  background-color: transparent;
  font-family: 'PlutoSansLight';
}
.node-procedure #procedure-select {
  padding: 20px 0;
  margin: 25px auto;
  font-family: 'PlutoSansLight';
  color: #13457c;
  text-align: center;
  background-color: #f0f0f0;
}
.node-procedure #procedure-select span.heading {
  font-size: 2em;
}
.node-procedure #procedure-select .select2-container {
  width: 300px !important;
  font-size: 1.2em;
  text-align: left;
  margin-left: 20px;
  vertical-align: text-bottom;
}
.node-procedure #procedure-content h4 {
  display: block;
  width: 100%;
  color: #fff;
  background-color: #13457C;
  font-family: 'PlutoSansLight';
  font-size: 2.2em;
  padding: 10px;
  margin: 0;
  text-align: center;
}
.node-procedure #procedure-content .procedure-image {
  width: 100%;
  height: 225px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.node-procedure #procedure-content .procedure-body {
  margin: 15px 15px;
}
.node-procedure #procedure-content .procedure-social {
  float: right;
  margin: 10px 0 30px 50px;
}
.node-procedure #procedure-content .social-label {
  font-family: 'PlutoSansMedium';
  text-align: center;
  padding-bottom: 10px;
}
.node-procedure #procedure-content .procedure-social li {
  display: block;
  clear: both;
}
.node-procedure #procedure-content .procedure-featured {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  margin: 0 auto 20px auto;
  padding: 20px 10px;
  color: #13457c;
  font-size: 1.2em;
  font-style: italic;
  max-width: 350px;
}
.node-procedure #procedure-content .featured-link {
  float: right;
  width: 45px;
  height: 45px;
  position: relative;
}
.node-procedure #procedure-content .featured-link a {
  background-color: #13457c;
  color: #fff;
  font-size: 1.8em;
  border: 1px solid #13457c;
  border-radius: 45px;
  width: 45px;
  height: 45px;
  display: block;
  text-align: center;
  text-indent: 2px;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -22%;
}
.node-procedure #procedure-content .featured-link a:hover {
  background-color: #fff;
  color: #13457c;
}
.node-procedure #procedure-products {
  margin-top: 50px;
  clear: both;
}
.node-procedure #procedure-products .view-filters ul {
  text-align: center;
}
.node-procedure #procedure-products .view-filters ul li {
  width: 19%;
  min-width: 100px;
}
@media (max-width: 768px) {
  .node-procedure #procedure-content .procedure-body {
    margin: 15px 15px;
    position:relative;
    padding-bottom:40px;
  }
  .node-procedure #procedure-content .procedure-body ul li {
    margin-bottom: 5px;
  }
  .node-procedure #procedure-products .view-filters ul li {
    width: 50%;
    min-width: 100px;
  }
  .node-procedure #procedure-content .procedure-image {
    height: 100%;
  }
  .node-procedure #procedure-select {
    padding: 30px 0;
    margin: 0px auto;
  }
  .node-procedure {
    width: 100%;
    margin: 0 auto;
  }
  .node-product #product-featured .left iframe {
    width: 80%;
  }
}
@media screen and (min-width: 768px) and (max-width:768px) {
  .node-procedure #procedure-content .procedure-social {
    display: none !important;
  }
  .node-procedure .procedure-social-mobile {
    display: block !important;
  }
  .node-procedure #procedure-content .procedure-body {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
}
.node-procedure #procedure-tiles {
  text-align: center;
  margin-top: 50px;
  clear: both;
}
.node-procedure #procedure-tiles ul {
  list-style-type: none;
  padding: 20px 0 0 0;
  width: 100%;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  padding-top: 15px;
  text-align: center;
}
.node-procedure #procedure-tiles ul li {
  display: inline-block;
  margin: 0 50px 15px 50px;
  font-size: 1.2em;
  font-family: 'PlutoSansLight';
  color: #706f73;
  vertical-align: top;
  cursor: pointer;
  border-bottom: 3px solid transparent;
}
.node-procedure #procedure-tiles ul li:hover {
  border-color: #c8c8c8;
}
.node-procedure #procedure-tiles ul li.active,
.node-procedure #procedure-tiles ul li.active:hover {
  border-color: #00aeef;
}
.node-procedure #procedure-tiles .group-intro {
  text-align: left;
  margin: 25px 0 20px 0;
}
.node-procedure #procedure-tiles .group-items {
  display: none;
}
.node-procedure #procedure-tiles .group-items.active {
  display: block;
}
.node-procedure #procedure-tiles .group-item {
  display: inline-block;
  vertical-align: top;
  width: 200px;
  margin: 20px 28px 20px 28px;
  border: 1px solid #ddd;
  position: relative;
}
.node-procedure #procedure-tiles .group-item:hover,
.node-procedure #procedure-tiles .group-item:focus {
  text-decoration: none;
}
.node-procedure #procedure-tiles .group-item strong {
  font-family: 'PlutoSansLight';
}
.node-procedure #procedure-tiles .group-item .item-title {
  background-color: #706f73;
  color: #fff;
  font-family: 'PlutoSansLight';
  padding: 5px;
  font-size: 1.1em;
}
.node-procedure #procedure-tiles .group-item .item-image {
  height: 155px;
}
.node-procedure #procedure-tiles .group-item .item-image img {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}
.node-procedure #procedure-tiles .group-item .item-info {
  background-color: #ddd;
  color: #706f73;
  font-family: 'PlutoSansLight';
  font-size: 1.3em;
}
.node-procedure #procedure-tiles .group-item .item-subtitle {
  padding: 6px 5px 10px 5px;
}
.node-procedure #procedure-tiles .group-item .item-line {
  width: 80%;
  margin: 0 auto;
  border-bottom: 1px solid #706f73;
}
.node-procedure #procedure-tiles .group-item .item-text {
  font-family: 'PlutoSansLight';
  font-size: 0.8em;
  padding: 10px 5px;
}
.node-procedure #procedure-tiles .select2 {
  width: 90% !important;
  max-width: 300px;
}

.node-news h1 {
  font-size: 2.5em;
  color: #706f73;
  font-family: 'PlutoSansLight';
  margin-bottom: 0;
}
.node-news .top-row {
  margin: 0;
}
.node-news .top-row .col-md-8 {
  padding: 0;
}
.node-news .meta-date {
  font-size: 1.1em;
  font-family: 'PlutoSansLight';
  color: #333;
}
.node-news .meta-date span {
  color: #faa61a;
}
.node-news .news-social {
  float: right;
  margin: 0 0 30px 60px;
}
.node-news .news-social li {
  display: block;
  clear: both;
}
.node-news .news-social li a {
  float: right;
  margin-bottom: 10px;
}
.node-news h2 {
  font-size: 2em;
  color: #706f73;
  font-family: 'PlutoSansLight';
  margin-bottom: 20px;
}
.node-news .field-name-field-news-image {
  margin-top: 20px;
  text-align: center;
}
.node-news .field-name-field-news-image img {
  max-width: 100%;
  height: auto;
}
.node-news .news-byline {
  margin-top: 25px;
  padding: 20px;
  border-top: 1px solid #333;
  border-bottom: 1px solid #333;
}
.node-news .field-name-field-news-author-title, .node-news .field-name-field-news-author-bio {
  font-size: 0.9em;
}
.node-news blockquote {
  background-color: transparent;
  font-family: 'PlutoSansLight';
}
.node-news .news-references h3 {
  font-size: 1em;
  font-weight: bold;
  margin-top: 25px;
  text-transform: uppercase;
}
.node-news .news-references .field-item {
  margin-bottom: 8px;
  font-size: 0.9em;
}
.node-news .news-references .field-item p {
  margin: 0;
  padding: 0;
}
.node-news #news-latest {
  position: relative;
  margin: 30px 0 0 0;
  padding-bottom: 15px;
}
.node-news #news-latest .row {
  margin: 0;
}
.node-news #news-latest .row .col-md-10 {
  padding: 0;
}
.node-news #news-latest .inner-wide {
  background-color: #e5e5e5;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.node-news #news-latest h4 {
  text-align: center;
  margin: 15px 0;
}
.node-news #news-latest h4 a {
  color: #000;
  font-family: 'PlutoSansMedium';
  text-align: center;
  font-size: 0.8em;
  text-transform: uppercase;
}
.node-news #news-latest h4 a i {
  border: 1px solid #000;
  border-radius: 17px;
  width: 17px;
  height: 17px;
  vertical-align: bottom;
  line-height: 1;
}
.node-news #news-latest h4 a:hover {
  color: #706f73;
  text-decoration: none;
}
.node-news #news-latest h4 a:hover i {
  border-color: #706f73;
}
.node-news #news-latest .views-row a {
  position: relative;
  overflow: hidden;
  height: 100px;
  padding: 10px;
  display: block;
  background-color: #706f73;
  margin-bottom: 5px;
}
.node-news #news-latest img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.4;
  height: auto;
  min-height: 100px;
}
.node-news #news-latest .created {
  position: relative;
  display: block;
  margin-top: 10px;
  font-family: 'PlutoSansLight';
  color: #faa61a;
  padding: 0 20px;
  font-size: 0.9em;
}
.node-news #news-latest .title {
  position: relative;
  display: block;
  color: #fff;
  font-family: 'PlutoSansLight';
  padding: 0 20px;
  max-height: 42px;
  overflow: hidden;
}
.node-news #news-latest .views-row a:hover {
  text-decoration: none;
}
.node-news #news-latest .views-row a:hover img {
  opacity: 0.6;
}
/*#news-header .inner-wide {
  background-image: url(../img/news-header-bg.jpg);
}*/
#news-listing {
  margin-bottom: 40px;
}
#news-listing .news-item {
  width: 100%;
}
#news-listing .node-teaser {
  margin: 40px 10px 0 10px;
  border: 1px solid #f0f0f0;
}
#news-listing h4 {
  margin: 20px 15px 0 15px;
  padding: 0;
}
#news-listing h4 a {
  display: block;
  padding-bottom: 5px;
  width: 100%;
  border-bottom: 1px solid #706f73;
  color: #706f73;
  font-family: 'PlutoSansMedium';
  font-size: 1.2em;
}
#news-listing h4 a:hover {
  text-decoration: none;
  color: #333;
}
#news-listing .meta-date {
  margin: 3px 15px 0 15px;
  font-size: 0.9em;
}
#news-listing .field-name-field-news-image {
  margin-top: 0;
}
#news-listing .teaser-body {
  margin: 10px 15px 15px 15px;
}
#news-listing a.read-more {
  text-decoration: underline;
  color: #2a6496;
}
#news-listing a.read-more:hover {
  color: #428bca;
}
#news-listing .pager a {
  padding: 10px 50px;
  border: 1px solid #706f73;
  color: #706f73;
  font-size: 1.3em;
  font-family: 'PlutoSansLight';
  border-radius: 0;
}
#page--message-only {
  margin-top: 25px;
  text-align: center;
  font-family: 'PlutoSansLight';
}
/*#events-header .inner-wide {
  background-image: url(../img/events-header-bg.jpg);
  background-size: cover;
}*/
#events-listing-filters {
  width: 95%;
  margin: 0 auto;
  text-align: center;
  font-family: 'PlutoSansLight';
  color: #13457c;
}
#events-listing-filters h4 {
  font-family: 'PlutoSansLight';
  color: #13457c;
  font-size: 1.6em;
  margin-top: 20px;
}
#events-listing-filters .filter {
  display: inline-block;
  margin: 10px;
  white-space: nowrap;
}
#events-listing-filters .filter span.filter-label {
  display: block;
  text-align: center;
  vertical-align: middle;
  font-size: 1.2em;
}
#events-listing-filters .filter .form-control {
  display: inline-block;
  width: auto;
  height: auto;
  max-width: 200px;
}
#events-listing-filters .filter .select2 {
  text-align: left;
  width: 300px !important;
}
#events-listing-filters .filter .refresh-container {
  width: 14px;
  display: inline-block;
}
#events-listing-filters .filter i.icon-refresh-animate {
  display: inline-block;
  -webkit-animation-name: rotateThis;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: rotateThis;
  -moz-animation-duration: 1s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  animation-name: rotateThis;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
#events-listing .view-filters {
  display: none;
}
#events-listing .view-empty {
  width: 80%;
  margin: 40px auto;
  font-size: 1.4em;
  text-align: center;
}
#events-listing .view-content .views-row {
  width: 90%;
  border: 10px solid #ddd;
  margin: 10px auto;
}
.node-event {
  overflow: hidden;
}
.node-event h1 {
  font-size: 2em;
  font-family: 'PlutoSansLight';
  color: #13457c;
  margin: 15px 0 5px 0;
}
.node-event .event-right {
  padding: 0 30px;
}
.node-event h4 {
  margin: 15px 0 5px 0;
}
.node-event h4 a {
  color: #13457c;
  font-size: 1.5em;
  font-family: 'PlutoSansLight';
}
.node-event h4 a:hover {
  text-decoration: none;
  color: #2a6496;
}
.node-event .event-photo {
  background-color: #fff;
  position: relative;
}
.node-event .event-photo img {
  width: auto;
  position: relative;
  max-height: 200px;
}
.node-event .event-speaker-bg {
  background-color: #13457c;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: auto;
  width: 100%;
}
.node-event .event-speaker-single {
  width: 100%;
  position: relative;
  color: #fff;
  text-align: center;
}
.node-event .event-speaker-single .event-speaker-meta {
  position: relative;
  width: 100%;
  font-family: 'PlutoSansLight';
  font-size: 1em;
  padding: 5px 0;
  color: #fff;
}
.node-event .event-speaker-single .event-speaker-meta .title {
  padding-bottom: 3px;
}
.node-event .event-speaker-single .event-speaker-meta .position {
  font-family: 'PlutoSansLight';
  font-size: 0.9em;
}
.node-event .event-speaker-single .event-speaker-meta .meta-line {
  display: none;
  border-bottom: 1px solid #fff;
  width: 85%;
  margin-bottom: 5px;
}
.node-event .event-speaker-single .event-speaker-image {
  width: 100%;
  background-color: #fff;
  position: relative;
  padding-bottom: 10px;
}
.node-event .event-speaker-single .event-speaker-image div {
  width: 200px;
  height: 200px;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border: 7px solid #8ca9c9;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 10px auto 0 auto;
}
.node-event .event-speaker-multiple {
  clear: both;
  color: #fff;
  width: 100%;
  padding-right: 10px;
  margin: 10px 0 10px 10px;
}
.node-event .event-speaker-multiple:last-child {
  margin-bottom: 0;
}
.node-event .event-speaker-multiple .event-speaker-image div {
  float: left;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border: 3px solid #8ca9c9;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  z-index: 1;
  margin-top: -5px;
}
.node-event .event-speaker-multiple .event-speaker-meta {
  width: 100%;
  margin-top: 5px;
}
.node-event .event-speaker-multiple .event-speaker-meta .meta-line {
  width: 85%;
  margin-bottom: 5px;
}
.node-event .event-speaker-multiple .event-speaker-meta .meta-inner {
  font-family: 'PlutoSansLight';
  background-color: #13457c;
  padding: 5px 0 5px 0;
  height: 50px;
  margin-left: 40px;
  text-indent: 10px;
}
.node-event .event-speaker-multiple .event-speaker-meta .position {
  font-family: 'PlutoSansLight';
  font-size: 0.8em;
  max-height: 16px;
  overflow: hidden;
}
.node-event .event-meta {
  color: #333;
  font-family: 'PlutoSansLight';
  font-size: 1.2em;
  color: #faa61a;
}
.node-event .event-credits {
  color: #faa61a;
  font-family: 'gotham_black';
}
.node-event .event-description {
  margin-top: 10px;
}
.node-event .field-name-body {
  margin-top: 25px;
}
.node-event .event-links {
  margin: 15px 0;
  text-align: center;
}
.node-event .event-links a {
  padding: 10px 10px;
  margin: 10px 15px;
  min-width: 150px;
}
.node-event .event-links a i {
  margin-right: 6px;
}
#event-header {
  min-height: 200px;
}
#event-header .inner-wide {
  background-image: url(../img/event-detail-header-bg.jpg);
}
#event-container {
  width: 90%;
  margin: 25px auto 0 auto;
}
#event-container .event-dates {
  color: #706f73;
  font-family: 'PlutoSansLight';
  font-size: 2em;
  margin-top: 20px;
}
#event-container .event-time {
  font-family: 'PlutoSansLight';
  margin-bottom: 5px;
}
#event-container .event-address {
  margin-top: 20px;
}
#event-container .event-address .name-block {
  font-family: 'PlutoSansLight';
  font-style: italic;
  color: #706f73;
  font-size: 1.2em;
  margin-bottom: 2px;
}
#event-container .event-address .country {
  display: none;
}
#event-container .event-address .event-map {
  margin-top: 15px;
  display: block;
  color: #706f73;
  text-decoration: underline;
}
#event-container .event-address .event-map:hover {
  color: #00aeef;
}
#event-container .event-reg a {
  margin-top: 30px;
  width: 80%;
  padding: 10px 30px;
}
#event-container .event-reg a i {
  margin-right: 6px;
}
#event-container .event-links {
  margin-top: 50px;
  border-bottom: none;
}
#event-container .event-links a {
  margin-top: 25px;
}
#event-container .field-name-field-event-image img {
  width: 100%;
  margin-bottom: 20px;
}
#event-speakers {
  width: 100%;
  background-color: #e6e6e6;
  color: #13457c;
  padding-bottom: 10px;
}
#event-speakers h3 {
  padding-left: 50px;
  display: inline-block;
  border-bottom: 1px solid #13457c;
  margin: 10px 0 15px 0;
  font-family: 'PlutoSansLight';
}
#event-speakers .event-speaker-multiple {
  background-color: transparent;
  color: #13457c;
  margin: 10px;
  padding: 0;
  width: auto;
  cursor: pointer;
}
#event-speakers .event-speaker-multiple:hover .event-speaker-name {
  border-bottom: 1px solid #13457c;
}
#event-speakers .event-speaker-multiple .event-speaker-name {
  border-bottom: 1px solid transparent;
  font-family: 'PlutoSansLight';
  margin-left: 70px;
}
#event-speakers .event-speaker-multiple .event-speaker-position {
  margin-left: 70px;
  font-size: 0.9em;
}
.event-speaker-teaser {
  margin: 0;
}
.event-speaker-teaser .left {
  text-align: center;
  z-index: 1;
  min-width: 220px;
}
.event-speaker-teaser .left img {
  width: 100%;
  margin-bottom: 10px;
}
.event-speaker-teaser .event-speaker-name-position {
  border-bottom: 1px solid #13457c;
  color: #13457c;
  display: inline-block;
  margin-left: -70px;
  padding-left: 70px;
  margin-bottom: 10px;
}
.event-speaker-teaser .event-speaker-name-position .title {
  font-family: 'PlutoSansLight';
  font-size: 1.6em;
}
.event-speaker-teaser .event-speaker-name-position .position {
  font-family: 'PlutoSansLight';
  font-size: 1em;
  margin-left: 15px;
}
.event-speaker-teaser .btn {
  padding: 10px 30px;
  margin-top: 10px;
}
.event-speaker-teaser .event-teaser-image div {
  width: 260px;
  height: 260px;
  margin: 0 auto;
  border-radius: 130px;
  -webkit-border-radius: 130px;
  -moz-border-radius: 130px;
  border: 7px solid #8ca9c9;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
#promotions-header .row {
  margin: 0;
}
#promotions-header .header-left {
  padding: 30px 50px;
}
#promotions-header .header-left h1 {
  font-family: 'PlutoSansLight';
  color: #13457c;
  font-size: 2em;
}
#promotions-header .header-left .line-inner {
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  border-bottom: 1px solid #000;
}
#promotions-header .header-left h2 {
  font-family: 'PlutoSansMedium';
  font-size: 1.3em;
  padding-top: 5px;
}
#promotions-header .header-left h3 {
  font-size: 1em;
}
#promotions-header .header-left .inner-left {
  position: absolute;
  width: 200%;
  left: -100%;
  top: 0;
  height: 100%;
  background-color: #e6e6e6;
}
#promotions-header .header-left-inner {
  position: relative;
  width: 100%;
}
#promotions-header .header-right .inner-right {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  background: #52c552 url(../img/green-geo.jpg) center center no-repeat;
  background-size: cover;
}
#promotions-header #promotions-slider {
  overflow: hidden;
  color: #fff;
  width: 100%;
  position: relative;
}
#promotions-header #promotions-slider ul {
  width: 20000em;
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;
}
#promotions-header #promotions-slider li {
  float: left;
  position: relative;
  padding: 20px;
  overflow: hidden;
}
#promotions-header #promotions-slider .views-field-field-image {
  text-align: center;
}
#promotions-header #promotions-slider .views-field-field-image .field-content {
  width: 200px;
  height: 200px;
  border-radius: 100px;
  border: 5px solid #008900;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
}
#promotions-header #promotions-slider .views-field-field-image .field-content img {
  opacity: 0;
}
#promotions-header #promotions-slider .views-field-field-promotion-display-title {
  font-family: 'PlutoSansMedium';
  font-size: 2em;
  border-bottom: 1px solid #fff;
  margin-bottom: 10px;
  padding-bottom: 5px;
  width: 100%;
}
#promotions-header #promotions-slider .views-field-field-promotion-link {
  text-align: center;
}
#promotions-header #promotions-slider .views-field-field-promotion-link a {
  display: inline-block;
  margin-top: 20px;
  color: #fff;
  font-family: 'PlutoSansLight';
  font-size: 1.2em;
  padding: 5px 10px;
  border: 1px solid #fff;
}
#promotions-header #promotions-slider .views-field-field-promotion-link a:hover {
  text-decoration: none;
}
.promotions-section {
  border: 15px solid #e6e6e6;
  width: 100%;
  margin: 0px auto;
  overflow: hidden;
}
.promotions-section h4 {
  width: 100%;
  padding: 10px;
  margin: 0;
  color: #fff;
  font-family: 'PlutoSansLight';
  font-size: 1.8em;
  text-align: center;
}
.promotions-section .content-left {
  cursor: pointer;
}
.promotions-section .category-left {
  padding: 0;
  display: none;
}
.promotions-section .category-right {
  padding: 0;
  background-color: #e6e6e6;
  margin-bottom: -99999px;
  padding-bottom: 99999px;
}
.promotions-section .category-left div {
  display: none;
  text-align: center;
}
.promotions-section .category-left div.active {
  display: block;
}
.promotions-section .category-left img {
  max-width: 100%;
}
.promotions-section .promo-content-container {
  border-top: 1px solid #bbb;
  background-color: #fff;
  font-size: 0.9em;
  padding: 8px 10px 5px 10px;
}
.promotions-section .promo-content-container.active {
  background-color: #fff;
  border-color: #fff;
  border-top-color: #bbb;
}
.promotions-section .promo-content-container.first {
  border-top: none;
}
.promotions-section .promo-content-container .row {
  padding: 0;
}
.promotions-section .promo-content-container p {
  margin: 0;
}
.promotions-section h5 {
  color: #006a95;
  font-family: 'PlutoSansLight';
  padding: 0;
  margin: 0;
  font-size: 1.2em;
}
.promotions-section .content-link {
  color: #fff;
  display: block;
  padding: 10px 20px;
  border: 1px solid #42c0f6;
  background-color: #42c0f6;
  text-align: center;
  font-size: 1.1em;
  font-family: 'PlutoSansLight';
}
.promotions-section .active .content-link {
  color: #fff;
  background-color: #42c0f6;
}
.promotions-section .content-link:hover {
  text-decoration: none;
}
.promotions-section .promo-disclaimer {
  margin: 15px;
  font-size: 0.8em;
  display: none;
}
.promotions-section .promotions-disclaimer {
  display: none;
}
.promotions-section .view-mobile-disclaimer {
  text-decoration: none;
  display: block;
  margin: 5px 0 10px 0;
  text-align: right;
}

#contact-header .inner-wide {
  background: transparent url(../img/contact-header-bg.jpg) center center no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

#design-center-header .banner-title,
#contact-header .banner-title, .header-page-1 .banner-title {
  position: relative;
  p {
    margin-top: 10px;
  }
}

#contact-header h1,
#aboutus-header h1,
#designgallery-header h1,
#design-center-header h1,
.header-page-2, .header-page-1 h1{
  display: inline-block;
  line-height: 1.2;
  margin: 0;
  color: #4a4a4a;
  font-family: "Helvetica Neue LT Std Lt", Arial, sans-serif;
  font-size: 30px;
  line-height: 33px;
  font-size: 30px;
}
#design-center-header h1 {
  color: #4a4a4a;
}
#contact-content .col {
  padding: 20px 0 30px 60px;
}
#contact-content strong {
  font-weight: normal;
}
@media (max-width: 768px) {
  #contact-content {
    padding: 20px 20px 30px 40px;
  }
  #contact-content .col {
    padding: 0;
  }
}
#contact-content {
  color:#333;
}
#contact-content h3 {
  font-family: 'PlutoSansMedium';
  color: #13457c;
  font-size: 1.3em;
  text-transform: uppercase;
  padding:0;
  margin:20px 0 0 0;
}
#contact-content h4#heading {
  font-family: "PlutoSansLight",sans-serif;
  font-size: 14px;
  line-height:20px;
  margin:0 0 0px 0;
}
#contact-content h4 span.subs {
  display: block;
  font-size: 0.9em;
}
#contact-content h4 {
  font-family: "PlutoSansLight",sans-serif;
  font-size: 0.9em;
  line-height:20px;
  margin: 3px 0 0 0;
  padding:0;
}
#contact-content h3 {
  margin-bottom: 10px;
}
#contact-content ul {
  margin:0;
}
#contact-content li {
  margin:0px 0;
}
#contact-content p {
  margin: 0;
  padding: 0;
}
#contact-content .phone {
  font-size: 14px;
  color:#333;
  margin-top: 10px;
  display: block;
}
#contact-offices {
  margin: 10px 15px 30px 15px;
}
#contact-offices .select2 {
  min-width: 200px;
}
#contact-offices .select2-container--default .select2-selection--single .select2-selection__arrow {
  background-color: #b0b0b0;
}
#contact-offices .select2-container--default .select2-selection--single {
  border-color: #b0b0b0;
}
#contact-offices .select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #13457c transparent transparent;
}

#aboutus-header, #practice-header {
    min-height: 180px;
}
.practice-page .header-page-1, .practice-page header-page-2  {
  margin-bottom: 50px;
}

#aboutus-header .header-image-responsive,
#design-center-header .header-image-responsive, #practice-header .header-image-responsive {
  //background: transparent url(../img/aboutus-header-bg.jpg) center center no-repeat;
  background-size: cover;
  min-height: 180px;
}
//#aboutus-header .header-image-responsive img {
//  display: none;
//}
#aboutus-body {
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;
}
#aboutus-vid {
  margin: 0 2px 0 0;
}
#aboutus-mid-container {
  margin: 0 2px 0 0;
  padding: 0;
}
#aboutus-mid-image {
  width: 100%;
  padding: 15px;
  height: 250px;
  background: transparent url(../img/aboutus-mid.jpg) center center no-repeat;
  margin: 0 auto;
}
#aboutus-video-left {
  padding: 30px 0 10px 0;
}
#aboutus-video-left h2 {
  font-size: 30px;
  font-weight: normal;
  margin-top: 15px;
  margin-bottom: 8px;
}
#aboutus-video-left .line {
  position: relative;
  width:282px;
}
#aboutus-video-left .line-inner {
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  border-bottom: 1px solid #888;
}
#aboutus-video-left .left-content {
  width: 100%;
  position: relative;
}
#aboutus-video-left .left-content-body {
  margin:40px 0 0 0;
  height:auto;
  min-height:285px;
}
@media (max-width: 768px) {
  #aboutus-video-left .left-content {
    padding: 0px 0px 10px 0px;
  }
  #aboutus-video-left .left-content-body {
    margin:25px 0 0 0;
  }
}
#aboutus-video-left .left-content h1 {
  font-weight: normal;
  margin-top: 12px;
  margin-bottom: 10px;
}
#aboutus-video-left .left-content p {
  /*margin:20px 0 0 0;*/
  margin: 0 0 10px;
}
#aboutus-video-left .aboutus-bulb {
  display: inline-block;
  width: 40px;
  height: 45px;
  vertical-align: bottom;
  background: transparent url(../img/aboutus-bulb.png) center center no-repeat;
}
#aboutus-video-right {
  text-align: right;
  line-height: 0;
  min-height: 329px;
  position: relative;
  top:82px;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
}
#aboutus-video-right iframe,
#aboutus-video-right object,
#aboutus-video-right embed {
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  height: 100%;
}
.aboutus-bottom {
  padding: 50px;
}

#rewards-header {
  position: relative;
}
.line {
  position: relative;
  width: 95%;
  margin-bottom: 15px;
}
.line-inner {
  position: absolute;
  z-index: 99;
  top: 0;
  left: -100%;
  width: 200%;
  border-bottom: 1px solid #888;
}
.right .line-inner {
  left: 0;
}
body[class*="kerr-page-kerrpreferred"] #rewards-header {
  height: 440px;
  margin-bottom: 100px;
}
body[class*="kerr-page-kerrpreferred"] #rewards-header .field-type-text-with-summary {
  margin: 1.3em 0;
}
body[class*="kerr-page-kerrpreferred"] {
  background: #fff;
}
body[class*="kerr-page-rewards"] #rewards-header h1 {
  background: transparent url(../img/loyalty-logo.png) center center no-repeat;
  height: 35px;
  width: 90%;
  max-width: 495px;
  margin: 0 auto;
  text-indent: -9999px;
  background-size: contain;
  color: #13457c;
  font-family: 'PlutoSansLight';
  font-size: 3.3em;
}
body[class*="kerr-page-kerrpreferred"] #rewards-header h1 {
  background: transparent url(../img/kerrpreferred-logo.png) center center no-repeat;
  height: 168px;
  width: 90%;
  max-width: 495px;
  text-indent: -9999px;
  background-size: contain;
  color: #13457c;
  font-family: 'PlutoSansLight';
  font-size: 3.3em;
}
body[class*="kerr-page-kerrpreferred"] #rewards-form-hubspot {
  margin: 0 auto;
  float: none;
}
body[class*="kerr-page-rewards"] #rewards-form h4 {
  background: transparent url(../img/loyalty-logo.png) center center no-repeat;
  height: 35px;
  width: 90%;
  text-indent: -9999px;
  background-size: contain;
}
body[class*="kerr-page-kerrpreferred"] #rewards-form .row > .col-md-6 {
  float: none;
  margin: 0 auto;
  text-align: left;
  margin-bottom: 1em;
}
body[class*="kerr-page-kerrpreferred"] #rewards-form .row > .col-md-6 > h4:first-of-type {
  display: none;
  visibility: hidden;
}
#rewards-form .hbspt-form {
  margin-top: 30px;
}
#rewards-footer {
  text-align: center;
  margin: 25px auto;
}
body[class*="kerr-page-kerrpreferred"] #rewards-header .inner-wide,
body[class*="kerr-page-rewards"] #rewards-header .inner-wide {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
body[class*="kerr-page-kerrpreferred"] #rewards-header .inner-wide {
  background: transparent url(../img/kerrpreferredrewardsmembers-bg.jpg) center center no-repeat;
  min-height: 534px;
}
body[class*="kerr-page-rewards"] #rewards-header .inner-wide {
  background: #ececec url(../img/rewards-bg.jpg) center center no-repeat;
}

body[class*="kerr-page-rewards"] #rewards-header .row {
  padding: 80px 0;
  margin: 0;
}
body[class*="kerr-page-kerrpreferred"] #rewards-header .row > .col-md-2:first-of-type {
  width: 0;
}
body[class*="kerr-page-rewards"] #rewards-header .row-inner {
  text-align: center;
  padding: 0;
}
body[class*="kerr-page-rewards"] #rewards-header .line {
  position: relative;
  margin-bottom: 30px;
  margin-top: -5px;
}
body[class*="kerr-page-rewards"] #rewards-header .line-inner {
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  border-bottom: 1px solid #000;
}

body[class*="kerr-page-rewards"] #rewards-header p {
  font-size: 1.1em;
  padding: 0 10px;
}
body[class*="kerr-page-rewards"] #rewards-header #reward-signup-link {
  margin-top: 20px;
  background-color: #13457c;
  padding: 10px 45px;
}
body[class*="kerr-page-rewards"] #rewards-header #reward-signup-link:hover {
  background-color: #42c0f6;
}

body[class*="kerr-page-rewards"] #rewards-mid {
  position: relative;
  color: #fff;
}
body[class*="kerr-page-rewards"] #rewards-mid .inner-wide {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #13457c;
}
body[class*="kerr-page-rewards"] #rewards-mid .content-inner {
  width: 90%;
  max-width: 800px;
  position: relative;
  margin: 0 auto;
  text-align: center;
  padding: 20px 0 40px 0;
}
body[class*="kerr-page-rewards"] #rewards-mid h2 {
  font-family: 'PlutoSansLight';
  font-size: 2.5em;
  padding-bottom: 40px;
}
body[class*="kerr-page-rewards"] #rewards-mid ul {
  margin-top: 50px;
}
body[class*="kerr-page-rewards"] #rewards-mid li {
  font-family: 'PlutoSansLight';
  font-size: 1.3em;
  text-align: left;
}
body[class*="kerr-page-rewards"] #rewards-mid .rewards-hand {
  margin: 0 auto;
  display: block;
  width: 234px;
  height: 234px;
  background: transparent url(../img/rewards-hand.png) center center no-repeat;
}
body[class*="kerr-page-rewards"] #rewards-mid p {
  margin-top: 40px;
  font-size: 1.1em;
}

body[class*="kerr-page-kerrpreferred"] #rewards-mid > .content-inner > .row > .col-sm-6 {
  width: 100%;
}
body[class*="kerr-page-kerrpreferred"] #rewards-mid > .content-inner > .row > .col-sm-6 img {
  display: block;
  width: 100%;
}


body[class*="kerr-page-rewards"] #rewards-form {
  width: 80%;
  margin: 0 auto;
  padding-top: 75px;
}
body[class*="kerr-page-kerrpreferred"] #rewards-form {
  position: relative;
  margin-top: 120px;
}
body[class*="kerr-page-kerrpreferred"] #rewards-form .inner-wide {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
body[class*="kerr-page-kerrpreferred"] #rewards-form .inner-wide {
  background: transparent url(../img/blue-bg.png) top center repeat;
}
body[class*="kerr-page-kerrpreferred"] #rewards-form .content-inner {
  padding-top: 3em;
  padding-bottom: 2em;
}
body[class*="kerr-page-rewards"] #rewards-form h4 {
  font-family: 'PlutoSansLight';
  font-size: 2em;
  color: #13457c;
}
body[class*="kerr-page-rewards"] #rewards-form img {
  max-width: 100%;
}
body[class*="kerr-page-kerrpreferred"] #rewards-form label {
  color: #fff;
}

body[class*="kerr-page-kerrpreferred"] #rewards-header #reward-signup-link {
  border: 1px solid #0086ef;
  background: none;
  color: #0086ef;
  font-family: PlutoSansMedium;
  text-transform: uppercase;
  line-height: normal;
  font-size: 16px;
  letter-spacing: 0.3px;
  padding-top: 1em;
  padding-bottom: 1em;
}
body[class*="kerr-page-kerrpreferred"] #rewards-header #reward-signup-link:hover {
  background-color: #0086ef;
  color: #fff;
}


body[class*="kerr-page-rewards"] #rewards-header-top {
  position: relative;
  /*   min-height: 490px; */
}
body[class*="kerr-page-rewards"] #rewards-header-top h1 {
  background: transparent url(../img/loyalty-preferred-logo.png) center center no-repeat;
  height: 68px;
  width: 90%;
  max-width: 495px;
  margin: 0 auto;
  text-indent: -9999px;
  background-size: contain;
  color: #13457c;
  font-family: 'PlutoSansLight';
  font-size: 3.3em;
  margin-bottom: 32px;
}
body[class*="kerr-page-kerrpreferred"] #rewards-header-top .inner-wide,
body[class*="kerr-page-rewards"] #rewards-header-top .inner-wide {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
body[class*="kerr-page-rewards"] #rewards-header-top .inner-wide {
  background: #ececec url(../img/kerrpreferredrewards-bg.jpg) center center no-repeat;
}

.row {
  /*   padding: 80px 0; */
  margin: 0;
}
body[class*="kerr-page-rewards"] #rewards-header-top .row-inner {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
}
body[class*="kerr-page-rewards"] #rewards-header-top p {
  font-size: 1.1em;
  padding: 0;
  color: #fff;
}
body[class*="kerr-page-rewards"] #rewards-header-top #reward-signup-link {
  margin-top: 10px;
  background-color: #00aeef;
  padding: 10px 45px;
  font-family: "PlutoSansLight";
  font-size: 20px;
}
body[class*="kerr-page-rewards"] #rewards-header-top #reward-signup-link:hover {
  background-color: #3276b1;
}
body[class*="kerr-page-rewards"] #rewards-header-top p.bottom-text {
  margin-top: 30px;
}
@media (max-width: 768px) {
  body[class*="kerr-page-rewards"] #rewards-header-top .inner-wide,
  body[class*="kerr-page-rewards"] #rewards-header .inner-wide {
    height: 80px;
    background-size: cover;
  }
  body[class*="kerr-page-rewards"] #rewards-header h1 {
    margin-top:10px;
  }
  body[class*="kerr-page-rewards"] #rewards-header-top h1 {
    height: auto;
    margin-top: -18px;
  }
  body[class*="kerr-page-rewards"] #rewards-header-top p {
    color: #333;
  }
  #aboutus-video-right {
    min-height: 315px;
    top:-20px;
  }
  body[class*="kerr-page-kerrpreferred"] #rewards-header h1 {
    background: transparent url(../img/kerrpreferred-logo-sm.png) 50% 50% no-repeat;
    margin: 0;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 450px;
    background-size: contain;
    height: 120px;
  }
}


#howtobuy-header {
  position: relative;
  padding-bottom: 50px;
}
#howtobuy-header .row {
  margin: 0 auto;
}
#howtobuy-header .inner-wide {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #000;
  opacity: 0.2;
}
#howtobuy-header .header-line {
  text-align: left;
}
#howtobuy-header h1 {
  margin: 0;
  padding: 60px 0 5px 20px;
  font-family: 'PlutoSansLight';
  font-size: 3.5em;
  color: #706f73;
}
#howtobuy-header .line {
  position: relative;
  width: 100%;
}
#howtobuy-header .line-inner {
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  border-bottom: 1px solid #706f73;
}
.howtobuy-container {
  width: 80%;
  margin: 0 auto;
}
.howtobuy-step {
  text-align: center;
  margin-top: 50px;
}
.howtobuy-step .step-icon {
  width: 165px;
  height: 165px;
  background: transparent url(../img/howtobuy-icons.png) -1px 0 no-repeat;
  margin: 0 auto;
}
.howtobuy-step .step2-icon {
  background-position: -170px 0;
}
.howtobuy-step .step3-icon {
  background-position: -339px 0;
}
.howtobuy-step p {
  margin: 0 40px;
}
#howtobuy-header h2 {
  margin-top: 30px;
  display: inline-block;
  font-family: 'PlutoSansMedium';
  font-size: 1em;
  padding-bottom: 5px;
  border-bottom: 1px solid #000;
  position: relative;
}
#howtobuy-header h2 span {
  position: absolute;
  display: block;
  left: -33px;
  top: -6px;
  color: #fff;
  background-color: #00aeef;
  width: 26px;
  height: 26px;
  border-radius: 13px;
  -webkit-border-radius: 13px;
  -moz-border-radius: 13px;
  text-align: center;
  font-size: 17px;
  padding-top: 4px;
  -webkit-box-shadow: 3px 3px 3px -2px rgba(0,0,0,0.75);
  -moz-box-shadow: 3px 3px 3px -2px rgba(0,0,0,0.75);
  box-shadow: 3px 3px 3px -2px rgba(0,0,0,0.75);
}
#howtobuy-contact {
  width: 80%;
  margin: 65px auto 40px auto;
}
#howtobuy-contact .btn {
  padding: 20px 50px;
  font-size: 2em;
  margin-bottom: 25px;
}
#howtobuy-contact p {
  font-size: 1.1em;
  line-height: 1.5;
  padding-top: 5px;
  margin-bottom: 25px;
}
#resource-center-header h2 {
  font-family: 'PlutoSansLight';
  font-size: 1em;
}
#resource-center-general-search h3 {
  color: #fff;
  font-family: 'PlutoSansLight';
  font-size: 1.5em;
  margin: 0;
  text-align: right;
  line-height: 35px;
}
#resource-center-general-search .form-text:focus {
  box-shadow: none;
}
#resource-center-general-search form div {
  width: 100%;
  margin: 0 auto;
}

#block-views-sds-emea-block {
  width: 95%;
  margin: 0 auto;
  clear: none;
}

.block-facetapi h2,
#block-kerrdental-search-sort-search-block h2,
#block-kerrdental-search-items-per-page-block label {
  color: #706f73;
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  letter-spacing: 0.3px;
  font-weight: 400;
  line-height: 20px;
}

body.node-type-page .block-facetapi:last-child {
  padding-bottom: 20px;
}
.view-id-resource_center .view-empty {
  width: 80%;
  margin: 40px auto;
  font-size: 1.2em;
}
.view-id-resource_center .node-image {
  text-align: left;
  margin-top: 2px;
}
.node-resource-asset.node-teaser .node-links {
  margin-top: 15px;
  padding: 0 22px;
  .download-btn {
    display: block;
    background-color: #5d9632;
    color: #ffffff;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 16px;
    letter-spacing: 0.3px;
    font-weight: 700;
    padding: 10px 0 7px 0;
    text-align: center;
    line-height: 23px;
    margin: 10px 0;
    text-decoration: none;
    i {
      margin-right: 5px;
    }
  }
}
.node-type-resource-asset {
    .download-btn {
        display: block;
        background-color: #5d9632;
        color: #ffffff;
        font-family: Helvetica, Arial, sans-serif;
        font-size: 16px;
        letter-spacing: 0.3px;
        font-weight: 700;
        padding: 10px 0 7px 0;
        text-align: center;
        line-height: 23px;
        text-decoration: none;
        margin: 25px 0 10px 0;
        @media (min-width: 992px) {
          max-width: 163px;
        }
    }
}
.node-resource-asset.node-teaser .node-links a {
  display: block;
  color: #706f73;
  margin: 10px 0;
  font-size: 1em;
}
//.node-resource-asset.node-teaser .node-links a:hover {
//  color: #428bca;
//  text-decoration: none;
//}
.node-resource-asset.node-teaser .node-links a.secondary {
  color: #919191;
  text-align: center;
}
.node-resource-asset.node-teaser .node-links a.secondary:hover {
  color: #adadad;
}
.view-id-resource_center .node-title-link {
  color: #706f73;
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  letter-spacing: 0.3px;
  line-height: 20px;
}
.node-resource-asset.node-teaser .node-info-details {
  margin-top: 8px;
}
#ra-container .node-taxonomy, .node-resource-asset.node-teaser .node-taxonomy {
  margin-bottom: 3px;
}
#ra-container .node-taxonomy span, .node-resource-asset.node-teaser .node-taxonomy span {
  font-weight: bold;
}
.node-resource-asset.node-teaser .node-taxonomy .see-more-link {
  display: inline-block;
  margin-left: 5px;
  font-size: 1em;
}
.node-resource-asset.node-teaser .node-taxonomy .see-more {
  display: none;
  font-weight: normal;
}
.bottom-back {
  position: relative;
  text-align: center;
  padding: 15px 0;
}
.bottom-back .inner-wide {
  background-color: #e6e6e6;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.bottom-back a {
  position: relative;
  color: #333333;
  font-size: 1.2em;
}
.bottom-back i {
  border: 1px solid #000;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  vertical-align: text-top;
}
.bottom-back a:hover {
  text-decoration: none;
}
.bottom-back a:hover i {
  border-color: #5d9632;
}
#resource-center-quick-search h3 {
  color: #fff;
  font-size: 1.3em;
  margin: 0 0 5px 0;
  white-space: nowrap;
  vertical-align: top;
}
#resource-center-quick-search h3 span {
  display: block;
  font-size: 0.7em;
  margin-top: 2px;
}
#resource-center-quick-search form {
  vertical-align: top;
  white-space: nowrap;
  width: 100%;
}
#resource-center-quick-search .sds-container .search-container {
  background-color: #005780;
}
#resource-center-quick-search .ifu-container .search-container {
  background-color: #0e3767;
}
#resource-asset-products {
  margin-top: 50px;
}
.resource-asset-products {
  background-color: #f8f8f8;
  position: relative;
  padding-bottom: 20px;
  &:before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    height: 100%;
    width: 100%;
    bottom: 0;
    left: -100%;
    background-color: #f8f8f8;
  }
  &:after {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    height: 100%;
    width: 100%;
    bottom: 0;
    right: -100%;
    background-color: #f8f8f8;
  }
  a.product-listing {
    background-color: #ffffff;
    padding: 20px;
    min-height: 377px;
  }
}
#resource-asset-products h4 {
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-size: 2em;
  color: #706f73;
  text-align: center;
  margin-bottom: 20px;
}
div.view-id-search_all, div.view-id-sku_search {
  width: 100%;
  margin: 15px auto;
  .view-header {
    color: #706f73;
    font-family: "Helvetica Neue", Arial, sans-serif;
    font-size: 13px;
    line-height: 18px;
  }
}
div.view-id-search_all h1, div.view-id-sku_search h1 {
  color: #4a4a4a;
  font-family: "Helvetica Neue LT Std Lt", Arial, sans-serif;
  font-size: 30px;
  line-height: 33px;
}
div.view-id-search_all h2, div.view-id-sku_search h2 {
  color: #4a4a4a;
  font-size: 1.6em;
  margin-top: 10px;
}
div.view-id-search_all .view-empty p, div.view-id-sku_search .view-empty p{
  margin-top: 40px;
}
div.view-id-search_all .view-empty form, div.view-id-sku_search .view-empty form {
  max-width: 500px
}
div.view-id-search_all .view-content, div.view-id-sku_search .view-content {
  margin-top: 25px;
}
div.view-id-search_all .view-content .views-row, div.view-id-sku_search .view-content .views-row {
  padding: 20px;
  border-bottom: 1px solid #ddd;
  div[class^="col-"] {
    color: #706f73;
    font-family: "Helvetica Neue", Arial, sans-serif;
    font-size: 16px;
    letter-spacing: 0.3px;
    line-height: 20px;
  }
}
div.view-id-search_all .views-row a , div.view-id-sku_search .views-row a{
  display: block;
  color: #706f73;
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
  margin-bottom: 15px;
}
div.view-id-search_all .views-row a img, div.view-id-sku_search .views-row a img{
  font-size: 1.5em;
  color: #13457c;
  display: block;
  margin:0px auto;
}

div.view-search-all-product-lines .view-header {
  margin-top: 20px;
  border-top: 1px solid #bebebe;
  padding-top: 15px;
  font-size: 1.2em;
}
div.view-search-all-product-lines .view-content {
  border: none;
  margin-top: 10px;
}
div.view-search-all-product-lines .view-content .views-row {
  border: none;
  padding: 0 20px;
  display: inline-block;
  border-right: 1px solid #bebebe;
}
div.view-search-all-product-lines .views-row a {
  font-size: 1.2em;
}
div.view-search-all-product-lines .view-content .views-row-last {
  border: none;
}
#ra-container {
  width: 95%;
  margin: 30px auto;
}
#ra-container .field-type-image {
  text-align: center;
  margin-bottom: 30px;
}
#ra-container img {
  margin-top: 10px;
}
#ra-container .ra-info-details a.btn {
  padding: 10px 40px;
  font-size: 1.2em;
  margin-top: 30px;
}
.field-name-field-resource-file-size {
  div {
    display: inline-block;
  }
}
#sitemap-container {
  width: 100%;
  margin: 0 auto;
  font-size: 16px;
  letter-spacing: 0.3px;
  margin-bottom: 80px;
  .sitemap-links {
    padding: 0;
    a {
      color: #706f73;
    }
    ul {
      padding-left: 20px;
    }
    & > li > a {
      font-weight: 700;
    }
  }
}
#sitemap-container h1 {
  color: #4a4a4a;
  position: relative;
  display: inline-block;
  font-family: "Helvetica Neue LT Std Lt", Arial, sans-serif;
  font-size: 30px;
  line-height: 33px;
    &:after {
        content: '';
        position: absolute;
        display: block;
        width: 9999%;
        right: 0;
        bottom: 0;
        height: 1px;
        background-color: #adafb1;
    }
}
#sitemap-container ul.sitemap-links ul li {
  list-style-type: none;
}

#contact-content .body {
  padding-top: 20px;
}

#contact-people {
  margin: 20px 0 0 0;
}
#contact-distrib {
  background-color: #f0f0f0;
  padding: 20px;
  @media (min-width: 992px) {
    margin-bottom: 100px;
  }
  margin-bottom: 30px;

}
#contact-distrib  form {
  max-width: 500px;
  margin: 0 auto;
}
#contact-distrib h5 {
  font-family: 'PlutoSansLight';
  font-size: 2em;
  border-bottom: 1px solid #b0b0b0;
  margin: 0;
  padding: 15px;
}
#contact-distrib h5.noborder {
  border: none;
}
.distrib-list {
  border-bottom: 1px solid #b0b0b0;
  padding: 20px;
}
.distrib-list h6 {
  font-family: 'PlutoSansLight';
  font-size: 1.2em;
  margin: 0;
  cursor: pointer;
  position: relative;
  padding-right: 30px;
}
.distrib-list h6 i {
  position: absolute;
  font-size: 1.7em;
  top: -5px;
  right: 0px;
}
.distrib-list .distribs {
  margin-top: 15px;
}
.distrib-list .distribs .field-item {
  margin: 5px 0;
  float: left;
  width: 50%;
  min-width: 200px;
  padding-right: 20px;
}
.distrib-list .distribs .field-item a {
  color: #706f73;
}
.distrib-list.open h6 i:before {
  content: "\f107";
}
#contact-distrib .select2 {
  min-width: 165px;
  margin: 5px 0 20px 20px;
  max-width: 220px !important
}
#international-offices .row {
  margin: 10px 20px 0 15px;
  padding-bottom: 20px;
  display: none;
  font-size: 1.1em;
  font-family: 'PlutoSansLight';
  word-wrap: break-word;
}
#international-offices .row a {
  color: #706f73;
  text-decoration: underline;
}
#international-offices .row strong {
  font-family: 'PlutoSansLight';
}

#contact-sales {
  padding-right: 0;
  margin-bottom: 30px;
  padding-left: 0;
}
#contact-sales-inner {
  padding: 20px;
  background-color: #f0f0f0;
  color: #706f73;
  font-family: "Helvetica Neue", Arial, sans-serif;
  margin-bottom: 30px;
  .sidebar-buttons .field-item {
    .btn{
      color: #ffffff;
      font-size: 16px;
      letter-spacing: 0.3px;
      font-weight: 700;
      line-height: 23px;
      width: 100%;
      margin-bottom: 10px;
    }
    &:last-child .btn {
      margin-bottom: 0;
    }
  }
  strong {
    font-family: "Helvetica Neue", Arial, sans-serif;
    font-size: 14px;
    line-height: 20px;
  }
  p {
    font-family: "Helvetica Neue", Arial, sans-serif;
  }

}
#contact-sales-inner h5 {
  color: #706f73;
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
}
#contact-sales-inner .field-name-field-page-federal-contract-info {
  font-size: 1em;
  margin-top: 20px;
}
#contact-sales-inner .field-name-field-page-federal-contract-info p {
  margin: 0;
}
#contact-sales-inner strong {

}
#contact-sales-inner .select2 {
  margin-top: 20px;
  min-width: 230px;
}
#contact-sales-inner .manager {
  display: none;
  margin-top: 15px;
}
#contact-sales-inner .manager a {
  color: #fff;
  text-decoration: underline;
}

#contact-salesrep {
  border: 1px solid #f0f0f0;
}

#contact-salesrep .contact-salesrep-top {
  background-color: #f0f0f0;
  padding: 20px
}
#contact-salesrep h5 {
  margin: 0 0 25px 0;
  color: #706f73;
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
}
#contact-salesrep .margin-sides {
  margin: 20px;
  color: #706f73;
}
#contact-salesrep .select2-container--default .select2-selection--single .select2-selection__arrow {
  width: 33px;
}
#contact-salesrep .select2-container {
  width: 100% !important;
  margin-bottom: 20px;
}
.contact-salesrep-input {
  position: relative;
  height: 28px;
}
#contact-salesrep-postal {
  width: 100% !important;
  padding: 2px 2px 2px 10px;
  position: absolute;
  top: 0;
  left: 0;
  right: 40px;
  height: 28px;
  border: 1px solid #5d9632;
}
#contact-salesrep-submit {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 10px;
  height: 28px;
}
#contact-salesrep-results .row {
  padding: 20px 0;
  border-top: 1px solid #706f73;
  @media (max-width: 1199px) {
    text-align: center;
  }
}
#contact-salesrep-results .name {
  color: #706f73;
  font-size: 16px;
  letter-spacing: 0.3px;
  margin-bottom: 7px;
}
#contact-salesrep-results a {
  text-decoration: underline;
  color: #5d9632;
  word-break: break-all;

}
#contact-salesrep-results .rep-mail {
  margin-bottom: 10px;
}

#contact-salesrep-results i {
  color: #5d9632;
}
#contact-salesrep-results .contact-salesrep-image {
  width: 100%;
  background-color: #fff;
  position: relative;
  @media (max-width: 1199px) {
    margin-bottom: 15px;
  }
}
#contact-salesrep-results .contact-salesrep-image div {
  width: 70px;
  height: 70px;
  border-radius: 35px;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  border: 4px solid #5d9632;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 0 auto;
}

.error-container {
  width: 100%;
  margin-bottom: 60px;
  padding: 0;
}
.error-container h1 {
  color: #4a4a4a;
  font-family: "Helvetica Neue LT Std Lt", Arial, sans-serif;;
  font-size: 30px;
  line-height: 33px;
}
.error-container h2,
.error-container h3 {
  color: #706f73;
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-weight: 400;
  line-height: 20px;
}
.error-container h2 {
  font-size: 16px;
  letter-spacing: 0.3px;
}
.error-container h3 {
  font-size: 20px;
}

.error-container .form-text {
  display: inline-block;
  border-radius: 0;
  width: 100%;
  margin: 0 auto;
  vertical-align: top;
}
.error-container .form-text:focus {
  box-shadow: none;
}
.error-container form button {
  background-color: #5d9632;
  border: none;
  height: 34px;
  width: 34px;
  padding: 2px 10px;
  vertical-align: top;
  background-image: url("../img/search-icon.svg");
  background-position: 7px;
  background-repeat: no-repeat;
  font-size: 1.4em;
  color: #fff;
}
.error-container #sitemap-container {
  width: 100%;
}
@media (max-width: 768px) {
  .error-container {
    width: 100%;
    margin: 30px auto;
  }
  .error-container h1 {
    margin:0;
    padding:0;
    font-size: 32px;
  }
  .error-container h2,
  .error-container h3 {
    font-size: 18px;
    margin-top: 20px;
  }
  .view-id-product_category_listing .view-filters {
    background-color: #f0f0f0;
    padding:5px 0;
  }
}

#super-container.blur {
  filter: blur(3px);
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  transition: all 0.5s ease;
}
.main-overlay-bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 99;
  background-color: rgba(0,0,0,0.5);
  display: none;
}
#country-menu {
  display: none;
  font-family: 'Helvetica Neue';
}
#country-menu .map-bg {
  margin-right: -13px;
  background: #fff url(../img/country-menu-bg.jpg) center center no-repeat;
  background-size: cover;
  padding-bottom: 25px;
}
#country-menu .country-menu-content {
  margin: 0 10% 20px 10%;
}
#country-menu .country-menu-title {
  background-color: #f8f8f8;
  color: #706f73;
  padding: 10px;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
}
#country-menu .country-menu-region {
  font-size: 16px;
  letter-spacing: 0.3px;
  color: #706f73;
  display: inline-block;
  margin-top: 20px;
  line-height: 18px;
  border-bottom: 1px solid #706f73;
  font-family: 'Helvetica Neue';
}
#country-menu ul {
  margin: 10px 20px 0 0;
  padding: 0;
  list-style-type: none;
  display: block;
}
#country-menu ul li {
  margin: 0 0 10px 0;
}
#country-menu ul li a {
  color: #706f73;
}
#country-menu .menu-2-col ul {
  -moz-column-count: 2;
  -moz-column-gap: 15px;
  -webkit-column-count: 2;
  -webkit-column-gap: 15px;
  column-count: 2;
  column-gap: 15px;
}
@media (max-width: 992px) {
  #country-menu .country-menu-region {
    cursor: pointer;
  }
  #country-menu ul {
    display: none;
  }
  #country-menu .menu-2-col ul {
    -moz-column-count: auto;
    -moz-column-gap: 0;
    -webkit-column-count: auto;
    -webkit-column-gap: 0;
    column-count: auto;
    column-gap: 0;
  }
  .node #product-featured {
    margin: 50px -15px 0 -15px;

    .left {
      padding-left: 0;

      img {
        margin: 5px auto;
        max-width: 80%;
      }
    }
  }
}
@media (min-width: 993px) {
  #country-menu ul {
    display: block !important;
  }
}

.view-id-product_line_order {
  margin: 30px;
}
.view-id-product_line_order .view-header {
  font-size: 1.5em;
  font-family: 'PlutoSansLight';
  margin-bottom: 30px;
}
.view-id-product_line_order .messages {
  background-color: #d4d3d3;
  padding: 15px;
  margin: 15px;
}
.view-id-product_line_order .tabledrag-toggle-weight-wrapper {
  display: none;
}
.view-id-product_line_order .table>tbody>tr>td {
  line-height: 1;
}
.view-id-product_line_order .table>tbody>tr>td a {
  vertical-align: sub;
}
.view-id-product_line_order a.tabledrag-handle .handle {
  padding-right: 10px;
  margin-right: 20px;
}

@-webkit-keyframes rotateThis {
  from { -webkit-transform: rotate( 0deg ); }
  to { -webkit-transform: rotate( 360deg ); }
}
@-moz-keyframes rotateThis {
  from { -moz-transform: rotate( 0deg ); }
  to { -moz-transform: rotate( 360deg ); }
}
@keyframes rotateThis {
  from { transform: rotate( 0deg ); }
  to { transform: rotate( 360deg ); }
}
@-webkit-keyframes shake {
  0% { transform: rotateZ(0); }
  10% { transform: rotateZ(-30deg); }
  20% { transform: rotateZ(15deg); }
  30% { transform: rotateZ(-10deg); }
  40% { transform: rotateZ(7.5deg); }
  50% { transform: rotateZ(-6deg); }
  60% { transform: rotateZ(5deg); }
  70% { transform: rotateZ(-4.28571deg); }
  80% { transform: rotateZ(3.75deg); }
  90% { transform: rotateZ(-3.33333deg); }
  100% { transform: rotateZ(0); }
}
@-moz-keyframes shake {
  0% { transform: rotateZ(0); }
  10% { transform: rotateZ(-30deg); }
  20% { transform: rotateZ(15deg); }
  30% { transform: rotateZ(-10deg); }
  40% { transform: rotateZ(7.5deg); }
  50% { transform: rotateZ(-6deg); }
  60% { transform: rotateZ(5deg); }
  70% { transform: rotateZ(-4.28571deg); }
  80% { transform: rotateZ(3.75deg); }
  90% { transform: rotateZ(-3.33333deg); }
  100% { transform: rotateZ(0); }
}
@keyframes shake {
  0% { transform: rotateZ(0); }
  10% { transform: rotateZ(-30deg); }
  20% { transform: rotateZ(15deg); }
  30% { transform: rotateZ(-10deg); }
  40% { transform: rotateZ(7.5deg); }
  50% { transform: rotateZ(-6deg); }
  60% { transform: rotateZ(5deg); }
  70% { transform: rotateZ(-4.28571deg); }
  80% { transform: rotateZ(3.75deg); }
  90% { transform: rotateZ(-3.33333deg); }
  100% { transform: rotateZ(0); }
}

@media (max-width: 991px) {
  #kerrdental-nav #matrix-container .content-container {
    display: none !important;
  }
  #header-top .container {
    width: 100%;
  }
}

@media (max-width: 450px) {
  body[class*="kerr-page-rewards"] #rewards-form h4 {
    font-size: 1.5em;
  }
  .event-speaker-teaser .left,
  .event-speaker-teaser .right {
    padding: 0;
  }
  .home-mid-demo .select2 {
    width: 90% !important;
  }
}

/* Smallest devices (tablets, 500px and up) */
@media (min-width: 500px) {
  .view-commerce-cart-form .views-field-commerce-total {
    display: table-cell;
  }
  .view-commerce-cart-form .views-field-edit-delete .btn:after {
    content: ' X';
    text-transform: uppercase;
  }
  #contact-header h1, .header-page-1 h1 {
    //font-size: 1.2em;
    max-width: 400px;
  }
}

@media (max-width: 550px) {
  #skip-link {
    display: none !important;
  }
  .node-product #product-featured .left iframe {
    height: 200px;
    width: 70%;
  }
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .xs-centered {
    text-align: left;
  }
  #procedure-header {
    padding: 0;
  }
  #header-bottom .container {
    display: block;
  }
  #header-help, #header-international, #header-search {
    text-align: right;
    padding: 0px 14px 0px 16px;
  }
  #header-international, #header-search {
    flex-grow: 1;
  }
  #header-help .help-container {
    width: 135px;
  }
 /* #header-international .fa-globe {
    float: right;
  }*/
  .header-cart {
    //padding: 0 40px;
    //float: right;
    //background-color: #10365f;
  }
  .footer {
    margin: 0;
    padding: 0;
  }
  .footer .mid {
    margin-top: 20px;
  }
  footer .left ul, footer .mid ul {
    margin: 0;
    text-align: left;
    padding: 0 25px;
  }
  #category-expand-boxes {
    display: block;
    position: relative;
  }
  .node-product .top-row {
    margin-top: 30px;
  }
  .node-product .line-inner {
    right: -100%;
    width: 200%;
  }
  .node-product h1 {
    //display: block;
  }
  .node-product #product-featured .right {
    padding: 20px 0px 20px 40px;
  }
  #home-carousel-container {
    display: block;
  }
  .node-type-enhanced-page .header-image-responsive,
  .node-type-enhanced-page .header-image-responsive img {
    height: 205px;
  }
  .header-image-responsive:after {
    padding-top: 0;
  }
  //.header-image-responsive img {
  //  max-height: none;
  //}
  #home-carousel-container .slides a {
    height: 255px;
  }
  #news-listing .news-item {
    width: 50%;
  }
  .view-commerce-cart-form .views-field-field-sku-image {
    display: table-cell;
  }
  .view-commerce-cart-form .form-actions {
    width: 100%;
    float: right;
  }
  .view-commerce-cart-form .cart-footer-text {
    float: left;
    width: 100%;
  }
  body.page-checkout #commerce-checkout-form-checkout fieldset .no-pad-left {
    padding-right: 10px;
  }
  body.page-checkout #commerce-checkout-form-checkout fieldset .no-pad-right {
    padding-left: 10px;
  }
  #howtobuy-header .header-line {
    text-align: right;
  }
  #howtobuy-header h1 {
    padding-left: 0;
  }
  #resource-center-general-search {
    padding: 18px 30px;
  }

  #home-mid .left {
    padding-right: 0;
  }
  #rewards-mid .inner-wide {
    background: transparent url(../img/big-blue.jpg) center center no-repeat;
  }
  #rewards-mid .rewards-hand {
    float: right;
    margin-right: 50px;
  }
  .node-procedure #procedure-content .procedure-body {
    margin: 30px 0 0 0;
  }
  .promotions-section {
    width: 95%;
    margin: 30px auto;
  }
  #promotions-header #promotions-slider .views-field-field-promotion-display-title {
    width: 80%;
  }
  #promotions-header #promotions-slider .views-field-field-image {
    float: right;
    text-align: left;
    margin-left: 20px;
  }
  #promotions-header #promotions-slider .views-field-title {
    width: 65%;
  }
  #promotions-header #promotions-slider .views-field-field-promotion-link {
    text-align: left;
  }
  #promotions-header #promotions-slider li {
    padding: 50px 20px;
  }
  .node-product #product-info aside .promo-bg {
    width: 200%;
    margin-right: -100%;
  }
  #events-listing-filters .filter .select2 {
    width: 200px !important;
  }
  #contact-header h1, .header-page-1 h1 {
    position: relative;
    max-height: none;
    max-width: 400px;
    //font-size: 1.5em;
  }
  #contact-distrib .select2 {
    margin: 5px 0 20px 30px;
    max-width: none;
  }
  .node-product #product-featured .inner-wide-right {
    width: 200%;
    margin-right: -100%;
  }
  #aboutus-header .header-image-responsive img {
    display: block;
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
  #kerrdental-nav #matrix-container .top-tabs a:first-child {
    margin-left: 1px;
    margin-right: -1px;
  }

  #kerrdental-nav li ul li a.menutype-drop:after {
    top: 5px;
  }

  .node-product #product-top-left {
    padding: 0 20px 0 60px;
  }

  #mobile-menu-button {
    display: none;
  }
  header {
    position: fixed;
    top: 0;
  }
  #fixed-header-spacer {
    height: 110px;
  }
  .header-cart, .header-profile {
    display: inline-block;
    //float: none;
    padding: 0;
  }
  header nav.col-md-9 {
    padding-right: 5px;
    padding-left: 5px;
  }
  #header-top nav {
    top: initial;
    left: initial;
    width: 75%;
    position: static;
  }
  #kerrdental-nav {
    display: block !important;
    margin-top: 3px;
    float: left;
    overflow: visible;
    margin-left: 15px;
  }
  #kerrdental-nav ul {
    background-color: transparent;
  }
  #kerrdental-nav li {
    display: inline-block;
    background-color: transparent;
    width: auto;
    margin-left: 7px;
    margin-right: 6px;
    height: 50px;
  }
  #kerrdental-nav li.link-cart {
    display: none;
  }
  #kerrdental-nav li li.last {
    display: inline-block;
  }
  #kerrdental-nav li a {
    margin-top: 12px;
    padding: 4px 0;
    text-align: center;
    color: #fff;
  }
  #kerrdental-nav li.active a,  #kerrdental-nav li.active-trail a {
    color: #fff;
    border-bottom: 3px solid #05abed;
  }
  /*#kerrdental-nav li:hover a,  #kerrdental-nav li li li a {*/
    /*border-bottom: 3px solid #05abed;*/
  /*}*/
  #kerrdental-nav li:hover li a {
    border: 0;
  }
  #kerrdental-nav li ul li a.menutype-drop:after {
    content: "\f105";
  }
  #kerrdental-nav li ul {
    position: absolute;
  }
  #kerrdental-nav li > ul.depth-1 {
    margin-left: 67px;
    width: 320px;
    //border-top: 1px solid #333;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.15);
    /*min-width: 320px;*/
    /*max-height: calc(100vh - 75px);*/
    /*overflow-y: auto;*/

    > li {
      &.submenu-columns > ul {
        width: 685px;
      }

      &:first-child {
        border-top: 1px solid #333;

        > ul {
          top: -1px;

          li:first-child {
            border-top: 1px solid #333;
          }
        }
      }

      ul {
        width: 290px;
      }
    }
  }

  #kerrdental-nav > li ul.depth-1 #show-product-categories ul,
  #kerrdental-nav > li > ul.depth-1 > li.submenu-columns > ul {
    margin-left: -175px;
  }

  #kerrdental-nav > li ul.depth-1 #show-product-categories ul {
    width: auto;
  }

  /*#kerrdental-nav li ul.depth-1.show-product-categories-menu-wrapper {*/
    /*overflow: inherit;*/
  /*}*/
  /*#kerrdental-nav li.expanded a.menutype-drop:after {*/

  /*}*/
  #kerrdental-nav li ul#ul-398 {
    margin-left: 0;
  }
  #kerrdental-nav li.link-depth-2.active-d2:not(.submenu-columns) {
      position: relative;
  }
  #kerrdental-nav li ul.depth-2 {
    margin-left: 0;
    top: 0;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.15);
    background: #000;
  }
  #kerrdental-nav li#show-product-categories ul.depth-2 {
      border-left: none;
  }
  #kerrdental-nav li.link-depth-3.active-d3 {
      position: relative;
  }
  #kerrdental-nav li ul.depth-3 {
    top: 0;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.15);
  }
  #kerrdental-nav ul li.active a,  #kerrdental-nav ul li a {
    border: none;
  }
  #kerrdental-nav li.submenu-columns ul li {
    width: auto;
  }
  #kerrdental-nav li.submenu-columns ul li a {
    padding: 0;
    position: relative;
    height: 70px;
  }
  #kerrdental-nav li ul ul {
    position: absolute;
    left: 100%;
  }
  #kerrdental-nav li ul ul.open-left {
    left: auto;
    right: 100%;
  }
  /*#kerrdental-nav ul.depth-3.open-left {*/
    /*border: 1px solid #bbb;*/
  /*}*/
  /*#kerrdental-nav li ul li.expanded ul {*/
    /*position: static;*/
  /*}*/
  /*#kerrdental-nav li ul li.expanded ul li {*/
    /*padding-left: 20px;*/
  /*}*/
  #kerrdental-nav #specialty-dentist,  #kerrdental-nav #specialty-endo,  #kerrdental-nav #specialty-hygen,  #kerrdental-nav #specialty-lab {
    width: 173px;
    height: 160px;
    padding: 68px 0 0 0;
    border: 1px solid #fff;
    border-right: 0;
    background-color: #052446;
    color: #fff;
    text-align: center;
  }
  //#kerrdental-nav #specialty-hygen {
  //  padding-top: 59px;
  //}
  #kerrdental-nav #specialty-lab {
    border-right: 1px solid #fff;
  }
  #kerrdental-nav #specialty-dentist:before,  #kerrdental-nav #specialty-endo:before,  #kerrdental-nav #specialty-hygen:before,  #kerrdental-nav #specialty-lab:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0.2;
    background: transparent url(../img/nav-specialty-bg.jpg) 0 0 no-repeat;
  }
  #kerrdental-nav #specialty-dentist:hover:before,  #kerrdental-nav #specialty-endo:hover:before,  #kerrdental-nav #specialty-hygen:hover:before,  #kerrdental-nav #specialty-lab:hover:before {
    opacity: 0.3;
  }
  #kerrdental-nav #specialty-endo:before {
    background-position: -176px 0;
  }
  #kerrdental-nav #specialty-hygen:before {
    background-position: -352px 0;
  }
  #kerrdental-nav #specialty-lab:before {
    background-position: -530px 0;
  }
  #kerrdental-nav li.category-matrix {
    width: 670px;
  }
  #kerrdental-nav #matrix-container .top-tabs {
    display: table;
    width: 100%;
  }
  #kerrdental-nav #matrix-container .top-tabs a {
    float: none;
    display: table-cell;
    vertical-align: middle;
    width: 16%;
    text-align: center;
    padding: 20px 8px;
  }
  #kerrdental-nav #matrix-container .top-tabs a:hover, #kerrdental-nav #matrix-container .top-tabs a.active  {
    background: #f8f8f8;
  }
  .node-type-enhanced-page .header-image-responsive,
  .node-type-enhanced-page .header-image-responsive img {
    height: 266px;
  }
  #home-carousel-container .slides a {
    height: 330px;
  }
  #home-mid .home-mid-line .line {
    left: -15px;
    width: 300%;
  }
  .node-product #product-info-list {
    display: block;
    padding-left: 50px; // new styles.
  }
  #news-listing .news-item {
    width: 33%;
  }
  #specialty-product-lines .odd .line-image {
    float: right;
  }
  #specialty-product-lines .line {
    display: block;
  }
  .node-product #info-buy .col-label {
    display: block;
  }
  #commerce-checkout-form-checkout h2,
  #commerce-checkout-form-checkout h3,
  #commerce-checkout-form-checkout h4 {
    width:50%;
  }
  #promotions-header .row,
  #promotions-header .row > div[class*='col-'] {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex: 1 0 auto;
  }
  #aboutus-video-left {
    padding: 0 0 0 30px;
  }
  #aboutus-vid,
  #aboutus-vid > div[class*='col-'] {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex: 1 0 auto;
    margin: 0;
    padding: 0 1px 30px 0;
    overflow:visible;

    /*		padding-bottom: 56.25%;  16:9 */
  }
  #category-overs {
    position: relative;
    display: block;
    background-color: #f0f0f0;
    font-size: 0;
    &:before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      height: 100%;
      width: 100%;
      bottom: 0;
      left: -100%;
      background-color: #f0f0f0;
    }
    &:after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      height: 100%;
      width: 100%;
      bottom: 0;
      right: -100%;
      background-color: #f0f0f0;
    }
  }
  .node-event .event-speaker-bg {
    height: 1000px;
  }
  .node-product #info-buy .col-add-cart .btn {
    font-size: 0.9em;
  }
  .node-product #info-buy .col-add-cart .view-cart {
    text-transform: lowercase;
    display: block;
  }
  body.page-checkout #edit-cart-contents {
    display: block;
  }
  body.page-checkout #edit-customer-profile-billing, body.page-checkout #edit-buttons {
    float: left;
    width: 55%;
  }
  #ra-container {
    width: 80%;
  }
  #ra-container .field-type-image {
    margin-bottom: 0;
  }
  .view-id-resource_center .node-image {
    text-align: center;
  }
  #events-listing-filters .filter span.filter-label {
    padding-right: 14px;
  }
  #events-listing-filters .filter {
    margin: 20px 12px;
  }
  .node-event .event-speaker-single .event-speaker-meta .meta-line {
    display: block;
  }
  .node-event .event-speaker-multiple .event-speaker-meta .meta-line {
    border-bottom: 1px solid #fff;
  }
  .node-event h4 {
    border-bottom: 1px solid #13457c;
  }
  .node-event .event-links {
    border-top: 1px solid #333;
    border-bottom: 1px solid #333;
  }
  .node-event .event-photo img {
    width: 100%;
    max-height: none;
  }
  .node-event .event-right {
    padding: 0 15px;
  }
  .view-id-product_category_listing .view-filters {
    background-color: #f0f0f0;
  }
  .node-product #product-related {
    margin-top: 50px;
  }
  .node-product #info-buy .col-info {
    padding-left: 15px;
    padding-right: 15px;
  }
  .node-product #product-top-right {
    padding-right: 60px;
  }
  .node-product #info-buy .col-image {
    min-height: 1px;
  }
  .promotions-section .promo-content-container {
    border-top: 1px solid #bbb;
    border-left: 1px solid #bbb;
  }
  .promotions-section .promo-content-container {
    background-color: #e6e6e6;
  }
  .promotions-section .category-left {
    display: block;
  }
  .promotions-section .content-link {
    color: #42c0f6;
    background-color: transparent;
  }
  .promotions-section .promotions-disclaimer {
    display: block;
  }
  #promotions-header .header-right {
    margin-left: 0;
  }
  #promotions-header .header-right .inner-right {
    width: 200%;
  }
  .promotions-section .promo-disclaimer {
    display: none !important;
  }
  #specialty-procedures .col-centered {
    display: inline-block;
    vertical-align: top;
  }
  #header-search,
  #header-search.searching {
  }
  /*#header-search .form-text {
    display: inline-block !important;
    width: 250px !important;
  }*/
  .node-product #product-featured {
    .left {
      padding: 15px;
    }

    .field-name-field-featured-video {
      text-align: left;
      padding:0;
      margin:-15px;
    }
  }
  #events-listing-filters .filter .select2 {
    width: 160px !important;
  }
  #contact-header h1, .header-page-1 h1 {
    max-width: 600px;
    //font-size: 2em;
  }
  #howtobuy-header .inner-wide {
    background-image: url(../img/home-slider-bg-transparent.png);
  }
  #contact-sales {
    padding-left: 15px;
  }
  #contact-sales-inner,
  #contact-salesrep {
    margin-left: 10px;
  }
  #product-category-buttons a {
    min-width: 150px;
  }
  .node-product #info-faqs .field-name-field-product-faq-question {
    cursor: default;
  }
  .node-product #info-faqs .field-name-field-product-faq-question:before {
    display: none;
  }
  .node-product #info-faqs .field-name-field-product-faq-answer {
    display: block !important;
  }
  footer .mid {
    position: relative;
    &:before {
      position: absolute;
      display: block;
      content: '';
      width: 1px;
      height: 130px;
      background-color: rgba(151,151,151, 0.5);
    }
    &:after {
      position: absolute;
      display: block;
      content: '';
      right: 0;
      top: 0;
      width: 1px;
      height: 130px;
      background-color: rgba(151,151,151, 0.5);
    }
  }
  .node-product #product-featured .right {
    left: 5px;
  }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  #kerrdental-nav li {
    margin-left: 18px;
    margin-right: 17px;
  }
  #kerrdental-nav li.category-matrix {
    width: 800px;
  }
  .node-type-enhanced-page .header-image-responsive,
  .node-type-enhanced-page .header-image-responsive img {
    height: 321px;
  }
  #home-carousel-container .slides a {
    height: 400px;
  }
  .node-product #info-buy .col-add-cart .btn {
    font-size: 1em;
  }
  .node-product #product-featured .right {
    padding-left: 0;
    padding-right: 30px;
  }
  .node-event .event-links a {
    padding: 10px 20px;
    margin: 10px 30px;
    min-width: 160px;
  }
  #contact-header h1, .header-page-1 h1 {
    max-width: 370px;
  }
  #product-category-buttons a {
    min-width: 170px;
  }
}

/*CSS for serving the retina image to devices with a high "device-pixel-ratio":*/
@media only screen and (-moz-min-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 3/2), only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-devicepixel-ratio: 1.5), only screen and (min-resolution: 1.5dppx) {
  header .navbar-brand a {
    background-image: url(../img/kerr-logo@2x.png);
    background-size: 75px 26px;
  }
}


/** Added Class for Event Address feild **/
.event-meta .address {
  float: right;
}

@media (min-width: 320px) and (max-width: 767px) {
  .event-meta .address {
    display: block;
    float: none;
  }
}

/** Added Class for overrite color property on promotion page**/
#promotions-header #promotions-slider .promotions-slider-carousel ul li p.small {
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .node-product .flexslider .flex-control-thumbs {
    display:none;
  }
}

.view-sku-details {
  margin:30px auto;

  #legal-notice {
    margin-top: 20px;
  }
}

.view-sku-details .item-list ul {
  margin:0;
  padding:0;
}

.view-sku-details ul li {
  list-style-type: none;
}

.view-sku-details h1 {
  color: #706f73;
  font-family: "PlutoSansLight";
  margin-top: 0;
}

.view-sku-details img {
  margin-top: 10px;
  vertical-align: middle;
  border: 0 none;
  max-width: 100%;
  height:100%;

}

.view-sku-details  .field-type-image {
  text-align: center;
}

.view-sku-details .field-content {
  float: left;
  width: 70%;
  word-wrap: break-word;
}

.view-sku-details .views-field {
  border-bottom: 1px solid #ddd;
  float: left;
  margin: 0;
  padding: 5px 0;
  width: 100%;
  word-wrap: break-word;
}

.view-sku-details .views-label {
  float: left;
  font-weight: bold;
  width: 30%;
}

.view-sku-details .views-field-title {
  display: block;
  font-family: "PlutoSansLight";
  font-size: 1.3em;
  color: #706f73;
  margin-bottom: 4px;
}

.view-sku-details .views-field-field-sku-full-description {
  padding-bottom: 10px;
}

.view-sku-details .views-field-field-sku-full-description img{
  max-width:100%;
}

.view-sku-details .col-add-cart .btn-primary {
  background-color: #428bca;
  border-color: #357ebd;
  color: #fff;
  font-size: 17px !important;
  padding: 14px 27px;
}


@media (max-width: 992px) {
  .view-sku-details .col-add-cart .btn-primary {
    min-width:100%;
  }

  .col-add-cart {
    text-align: center;
  }
}

.view-sku-details .col-add-cart .btn.added {
  background-color: #ddd !important;
  color: #333;
  cursor: default;
  font-family: "PlutoSansLight";
  margin-bottom: 0;
  margin-right: 5px;
}

.view-sku-details .views-field-field-sku-description {
  border-bottom: medium none;
  color: #999;
  font-size: 0.9em;
  padding-top: 0;
}

.col-add-cart .fa-cart-plus::before {
  font-family: FontAwesome;
}

.mar-top-15 {
  margin-top: 5px;
  float: left;
  min-width: 100%;
}

.view-sku-details .mar-top-15 a {
  text-align: center;
}
.col-add-cart {
  margin-top: 15px;
}

.col-add-cart a.secondary {
  color: #919191;
  text-decoration: none;
  margin-left:3px;
}
.col-add-cart a.secondary:hover {
  color: #adadad;
  text-decoration: none;
}

.btn-half {
  width: 50%!important;
  min-width: 50%!important;
  float: left;

}

.node-product #info-buy .grid-sku .btn-primary {
  width : 50%;
  float:left;
}

.node-product #info-buy .grid-sku .btn {
  min-width : auto;
}

.node-product #info-buy .grid-sku .secondary {
  border-top: 1px solid #aeaeae;
}

.node-product #info-buy .grid-sku .sku-add-cart a{
  padding: 8px 10px 10px;

}

@media (min-width: 991px) and (max-width: 1208px) {
  .node-product #info-buy .grid-sku .btn
  { font-size:12px;}

  .node-product #info-buy .grid-sku .sku-add-cart a {
    font-size:12px;
  }
}

/***
* SKU Search
*
***/

div.view-id-sku_search span.sku-sku  {
  font-size: 0.9em;
  color: #706f73;
}

div.view-id-sku_search span.sku-description  {
  color: #999;
  font-size: 0.9em;
}

div.view-id-sku_search a.secondary  {
  color: #919191;
  text-decoration: none;
  font-size: 14px;
}

.page-search section#block-quicktabs-search + section#block-system-main {
  display: none;
}

#quicktabs-search ul.quicktabs-tabs.quicktabs-style-garland {
  background: #13457c none repeat scroll 0 0;
  height: auto;
  padding: 10px 25px;
  font-size: 18px;
  line-height: normal;
  margin: 0;
  padding-bottom: 0;
  border-bottom: 0px solid transparent;
}

#quicktabs-search ul.quicktabs-tabs.quicktabs-style-garland li {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  display: inline-block;
  float: none;
  list-style-type: none;
  /*     overriding garland css so need to use !important */
  padding-bottom: 0 !important;
}

#quicktabs-search ul.quicktabs-tabs.quicktabs-style-garland li.active {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
}

#quicktabs-search ul.quicktabs-tabs.quicktabs-style-garland li a {
  display: block;
  margin: 0;
  padding: 12px;
  border-bottom: 3px solid transparent;
}

#quicktabs-search ul.quicktabs-tabs.quicktabs-style-garland li a:hover {
  text-decoration: none;
  border-bottom: 3px solid #05abed;
}

#quicktabs-search ul.quicktabs-tabs.quicktabs-style-garland li a:link,
#quicktabs-search ul.quicktabs-tabs.quicktabs-style-garland li a:visited {
  color: #706f73;
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
  margin: 0 0 8px 0;
  padding: 8px 12px 4px 11px;
}

#quicktabs-search ul.quicktabs-tabs.quicktabs-style-garland li.active a {
  background: #FFF;
  color: #13457c;
  text-decoration: none;
  padding: 8px 12px 12px 11px;
  margin: 0 0 0 0;
}

#quicktabs-search ul.quicktabs-tabs.quicktabs-style-garland li.active a:hover {
  border-bottom: 3px solid transparent;
}

.moreinfo-mrg {
  margin-top:50px;
}


@media (min-width: 320px) and (max-width: 767px) {
  .teaser-view {
    display: none;
    float: none;
  }
}

@media (min-width: 768px) {
  .rwd-break {
    display: none;
    float: none;
  }
}



/* User Login */
.not-logged-in.page-user .tabs--primary {
  display: none;
}
.not-logged-in.page-user .region-content {
  margin-top: 2vh;
  margin-bottom: 2vh;
}
.not-logged-in.page-user .region-content .header-line {
  margin-bottom: 50px;
}
.not-logged-in.page-user .region-content h1.title {
  /*   font-family: 'PlutoSansLight'; */
  font-size: 3.5em;
  line-height: normal;
  /*   color: #706f73; */
}
.not-logged-in.page-user .sso-login h3 {
  /*   font-family: 'PlutoSansLight'; */
  font-size: 1.6em;
  line-height: normal;
  /*   color: #706f73; */

  margin-top: 0;
}
.not-logged-in.page-user .sso-login {
  font-size: 1.2em;
  /*   font-family: 'PlutoSansLight'; */
  /*   color: #706f73; */
  line-height: normal;

  margin-top: 40px;
  padding-top: 40px;
  border-top: 10px solid #474c55;
}
.not-logged-in.page-user .region-content .form-item {
  max-width: 350px;
}

.not-logged-in.page-user .region-content .form-item label {
  /*   color: #706f73; */
  /*   font-family: 'PlutoSansLight'; */
  line-height: normal;
  font-size: 16px;
  letter-spacing: 0.3px;
}

.not-logged-in.page-user .region-content .line {
  position: relative;
  width: 100%;
}
.not-logged-in.page-user .region-content .line-inner {
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  border-bottom: 1px solid #474c55;
}

.not-logged-in.page-user .btn-primary {
  border: none;
  border-radius: 0;
  /*   background-color: #00aeef; */
  padding: 8px 50px;
  font-size: 20px;
  line-height: normal;
  margin-bottom: 10px;
  margin-top: 20px;
}

@media (min-width: 992px) {
  .not-logged-in.page-user .sso-login {
    margin-top: 0;
    padding-top: 0;
    border-top: none;
  }
}

#header-breadcrumbs {
  /*max-width: 530px;*/
  overflow: hidden;
  float: left;
}

.breadcrumb {
  display: none;
  margin-bottom: 0;
  padding: 0 30px;
  background-color: transparent;
}

.breadcrumb li {
  margin-bottom: 0;
}

.breadcrumb > li > a {
  color: black;
  font-size: 12px;
  line-height: 35px;
}

.breadcrumb > li + li:before {
  content:"\003e";
}

@media (min-width: 992px) {
  .breadcrumb {
    display: block;
  }

  #block-quicktabs-search {
    margin-top: 20px;
  }
}

.kerr-page-download-center .flex-wrapper {
  padding: 0 30px;
}

#header-banner {
  h1, h2 {
    color: #313131;
    position: relative;

    @include breakpoint($screen-sm) {
      padding-left: 60px;
    }
  }

  h1 {
    font-size: 38px;
    font-family: $font-light;
    display: inline-block;

    @include breakpoint($screen-sm) {
      margin: 60px 0 0;
    }
  }

  h2 {
    font-size: 18px;
    font-family: $font-regular;
  }

  .line {
    position: relative;
    margin-top: 10px;

    .line-inner {
      position: absolute;
      top: 0;
      right: 0;
      width: 1000%;
      border-bottom: 1px solid #1c2b39;
    }
  }
}

.wtb-body {
  padding: 20px 0;
  margin: 0 -15px;
  background: rgba(216, 216, 216, 0.3);

  @include breakpoint($screen-sm) {
    padding: 40px 20px 40px 60px;
  }

  .field-name-body {
    .field-items {
      .field-item {
        position: relative;

        @include breakpoint($screen-sm) {
          padding-left: 96px;

          &:before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            width: 58px;
            height: 51px;
            background: transparent url(../img/letter.svg) no-repeat center center;
            z-index: 1;
          }
        }

        p {
          font-size: 16px;
          letter-spacing: 0.3px;
          line-height: 1.5;
        }
      }
    }
  }

  .wtb-buttons {
    margin-top: 15px;

    @include breakpoint($screen-md) {
      margin-top: 0;
    }

    .field-item {
      margin-bottom: 10px;

      @include breakpoint($screen-sm) {
        display: inline-block;
        margin-right: 16px;
      }

      .wtb-cta {
        display: block;
        background-color: #5d9632;
        color: #ffffff;
        font-family: $font-light;
        font-size: 16.8px;
        padding: 10px 30px;
        text-align: center;

        &:hover {
          text-decoration: none;
          background-color: #706f73;
        }

        @include breakpoint($screen-sm) {
          display: inline-block;
        }
      }
    }
  }
}

.wtb-dealers {
  overflow: hidden;
  padding: 20px 0 20px;

  @include breakpoint($screen-md) {
    padding: 30px 5px 20px;
  }

  input.form-control {
    border: 1px solid #436da9;
    background-color: #ffffff;
    border-radius: 0;
    padding: 6px 10px;
    height: auto;
    font-size: 12px;
    line-height: 1;
    color: black;
  }

  input[type=checkbox] {
    -webkit-appearance: none;
    -moz-appearance:    none;
    appearance:         none;
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
  }

  input[type=checkbox] + label {
    display      : inline-block;
    border: 1px solid #436da9;
    background-color: #fff;
    width: 18px;
    height: 18px;
  }

  input[type=checkbox]:checked + label {
    background: #fff url(../img/check.svg) no-repeat 65% 42%;
  }

  .form-control {
    &.form-checkboxes {

    }
  }

  .field-name-field-dealers-view {
    .view-filters {
      background-color: #dddddd;
      padding: 15px 20px 24px;
    }

    .views-exposed-form {
      label {
        @extend %filter-label;
      }

      .views-exposed-widget {
        float: none;
        padding: 0;
        margin-bottom: 16px;

        &.views-widget-filter-field_certified_value {
          @include clearfix;

          & > label {
            float: right;
            width: calc(100% - 28px);
          }

          .form-control {
            padding: 0;
            margin: 0 10px 0 0;
            box-shadow: none;
            border: none;
            height: auto;
            outline: none;
            background-color: transparent;
          }

          .form-item {
            label {
              @extend %hide-text;
            }
          }

          label {
            line-height: 1.7;
          }

          label,
          .views-widget {
            display: inline-block;
            vertical-align: middle;
          }

          .views-widget {
            float: left;
            width: 28px;
          }
        }

        &.views-widget-filter-field_address_locality,
        &.views-widget-filter-field_address_postal_code {
          float: left;
        }

        &.views-widget-filter-field_address_locality {
          width: 60%;
        }

        &.views-widget-filter-field_address_postal_code {
          width: 40%;
          padding-left: 12px;
        }

        &.views-submit-button {
          padding-top: 14px;
          margin-bottom: 0;
        }

        .btn {
          margin-top: 0;
          width: 100%;
          font-size: 14px;
          font-family: $font-regular;
          border-radius: 0;
          padding: 9px 12px;
          border: none;
        }
      }
    }

    @include breakpoint($screen-md) {
      .view-filters,
      .view-content,
      .view-empty {
        float: left;
      }

      .view-filters {
        width: 22%;
      }

      .view-content,
      .view-empty {
        width: 78%;
        padding-left: 25px;
      }
    }
  }

  .views-widget-filter-field_geofield_distance {
    .form-item-field-geofield-distance-origin,
    .geofield-proximity-origin-from {
      display: none;
    }
  }

  .table > tbody > tr > td,
  .table > thead > tr > th.active,
  .table > tbody > tr > td.active,
  .table-hover > tbody > tr:hover > td,
  .table-hover > tbody > tr:hover > th,
  .table-striped > tbody > tr:nth-child(odd) > td {
    background-color: white;
  }

  .table > thead > tr > th {
    padding: 8px 8px 10px 0;
    white-space: nowrap;

    &.views-field-field-certification-type,
    &.views-field-title,
    &.views-field-field-geofield-distance {
      padding-right: 30px;

      a {
        color: #141e28;
        text-decoration: underline;

        img {
          display: inline-block;
        }
      }
    }

    &.views-field-field-address {
      padding-right: 120px;
    }
  }

  .table > tbody > tr > td {
    padding: 15px 8px 15px 0;
  }

  .table > thead:first-child > tr:first-child > th {
    font-size: 16px;
    letter-spacing: 0.3px;
    color: #141e28;
  }

  .dealer-title {
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 15px;
  }

  .views-field-field-phone-1 {
    display: none;

    @include breakpoint($screen-md) {
      display: block;
    }
  }

  .views-field-field-phone {
    @include breakpoint($screen-md) {
      display: none;
    }
  }

  .views-field-field-email,
  .views-field-field-link {
    a {
      color: #333333;
      text-decoration: underline;
    }
  }

  .views-field-field-phone,
  .views-field-field-phone-1,
  .views-field-field-fax {
    .field-content {
      display: inline-block;
    }

    &:before {
      content: '';
      display: inline-block;
      width: 15px;
      height: 15px;
      position: relative;
      margin-right: 5px;
    }
  }

  .views-field-field-phone,
  .views-field-field-phone-1 {
    &:before {
      background: url("../img/Kavo_icons_Phone.svg") no-repeat center/cover;
      top: 3px;
    }
  }

  .views-field-field-fax {
    &:before {
      background: url("../img/Kavo_icons_Fax.svg") no-repeat center/cover;
      top: 1px;
    }
  }

  //.form-item-field-geofield-distance-origin,
  //.geofield-proximity-origin-from {
  //  display: none;
  //}

  .geofield-proximity-field-wrapper {
    .form-item-field-geofield-distance-distance {
      width: 40%;
      float: right;
      padding-left: 12px;
    }

    .form-item-field-geofield-distance-unit  {
      width: 60%;
    }

    .form-item {
      margin: 0;
    }

    .form-type-select {
      padding: 0;
    }
  }

  .select2-container {
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  footer .right ul li {
    margin: 0 0 5px;
  }
}

@media (max-width: 345px) {
  footer .right ul li {
    margin: 0 7px 5px;
  }
}

.node-product .field-name-field-testimonial-author {
  font-family: 'PlutoSansCondMedium';
  font-size: 15px;
  font-weight: bold;
  margin-top: 10px;
}
.node-product .field-name-field-testimonial-author .field-item:before {
  content: '- ';
}
.node-product .field-name-field-testimonial-title {
  font-size: 13px;
  color: #5d9632;
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-weight: 400;
}
.node-product .field-name-field-testimonial-business-name {
  color: #706f73;
  font-size: 13px;
}
.node-product .field-name-field-testimonial-location {
  color: #706f73;
  font-size: 13px;
}
.node-product .field-name-field-testimonial-disclaimer {
  font-size: 13px;
  font-style: italic;
  color: #adafb1;
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
  line-height: 14px;
  margin-top: 20px;
}
.node-product .field-name-field-testimonial-disclaimer .field-item:before {
  content: 'Disclaimer: ';
}

@media (min-width: 301px) {
  div.product_banner {
    widows: 105%;
    height: 75px;
    margin-left: -15px;
    margin-right: -13px;
  }

 section div.product_banner img {
    float: right;
    padding: 0 20px 0 0;
    margin-top: 20px;
  }
}

@media (max-width: 300px) {
   div.product_banner {
    display: none;
  }
}
