@mixin breakpoint($width) {
  @media screen and (min-width: $width) {
    @content;
  }
}

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}